/**
 * タイトル
 */
const defaultTitle = 'エスビューア 先生用サイト'
export const title = {
  login: defaultTitle,
  homework: defaultTitle,
  homeworkAddPdfQuestion: defaultTitle,
  homeworkAddStdbQuestion: defaultTitle,
  homeworkAddTextbookQuestion: defaultTitle,
  homeworkProgress: defaultTitle,
  homeworkRegister: defaultTitle,
  question: defaultTitle,
  commentControl: defaultTitle,
  socialLoginError: defaultTitle,
  // 値はエスビューアに準拠
  errorPages: {
    badRequest: `400 Error｜${defaultTitle}`,
    forbidden: `403 Forbidden｜${defaultTitle}`,
    notFound: `404 Not Found｜${defaultTitle}`,
    internalServerError: `500 Error｜${defaultTitle}`,
    serviceUnavailable: `503 Error｜${defaultTitle}`,
  },
}

/**
 * meta 情報
 */
export const meta = {
  description: {
    login: 'エスビューア 先生用サイトを使うには数研アカウントでログインしてください。',
    homework: '',
    homeworkAddPdfQuestion: '',
    homeworkAddStdbQuestion: '',
    homeworkAddTextbookQuestion: '',
    homeworkProgress: '',
    homeworkRegister: '',
    question: '',
    commentControl: '',
    socialLoginError: '',
    // 値はエスビューアに準拠
    errorPages: {
      badRequest: '',
      forbidden: '',
      notFound: '',
      internalServerError: '',
      serviceUnavailable: '',
    },
  },

  keywords: {
    login: 'エスビューア,先生用サイト,エスビューア 先生用サイト,ログイン,数研,数研出版,デジタル教科書',
    homework: '',
    homeworkAddPdfQuestion: '',
    homeworkAddStdbQuestion: '',
    homeworkAddTextbookQuestion: '',
    homeworkProgress: '',
    homeworkRegister: '',
    question: '',
    commentControl: '',
    socialLoginError: '',
    // 値はエスビューアに準拠
    errorPages: {
      badRequest: '',
      forbidden: '',
      notFound: '',
      internalServerError: '',
      serviceUnavailable: '',
    },
  },

  robots: {
    login: setRobots().login,
    homework: setRobots().homework,
    homeworkAddPdfQuestion: setRobots().homeworkAddPdfQuestion,
    homeworkAddStdbQuestion: setRobots().homeworkAddStdbQuestion,
    homeworkAddTextbookQuestion: setRobots().homeworkAddTextbookQuestion,
    homeworkProgress: setRobots().homeworkProgress,
    homeworkRegister: setRobots().homeworkRegister,
    question: setRobots().question,
    commentControl: setRobots().commentControl,
    socialLoginError: setRobots().socialLoginError,
    errorPages: {
      badRequest: setRobots().errorPages.badRequest,
      forbidden: setRobots().errorPages.forbidden,
      notFound: setRobots().errorPages.notFound,
      internalServerError: setRobots().errorPages.internalServerError,
      serviceUnavailable: setRobots().errorPages.serviceUnavailable,
    },
  },
}

function setRobots() {
  const noneRobots = 'none'

  // あえて同じ値でも環境毎に条件分岐を記述する
  switch (process.env.VUE_APP_MODE) {
    case 'development':
      return {
        login: noneRobots,
        homework: noneRobots,
        homeworkAddPdfQuestion: noneRobots,
        homeworkAddStdbQuestion: noneRobots,
        homeworkAddTextbookQuestion: noneRobots,
        homeworkProgress: noneRobots,
        homeworkRegister: noneRobots,
        question: noneRobots,
        commentControl: noneRobots,
        socialLoginError: noneRobots,
        // 値はエスビューアに準拠
        errorPages: {
          badRequest: noneRobots,
          forbidden: noneRobots,
          notFound: noneRobots,
          internalServerError: noneRobots,
          serviceUnavailable: noneRobots,
        },
      }
    case 'staging':
      return {
        login: noneRobots,
        homework: noneRobots,
        homeworkAddPdfQuestion: noneRobots,
        homeworkAddStdbQuestion: noneRobots,
        homeworkAddTextbookQuestion: noneRobots,
        homeworkProgress: noneRobots,
        homeworkRegister: noneRobots,
        question: noneRobots,
        commentControl: noneRobots,
        socialLoginError: noneRobots,
        // 値はエスビューアに準拠
        errorPages: {
          badRequest: noneRobots,
          forbidden: noneRobots,
          notFound: noneRobots,
          internalServerError: noneRobots,
          serviceUnavailable: noneRobots,
        },
      }
    case 'production':
      return {
        login: '',
        homework: noneRobots,
        homeworkAddPdfQuestion: noneRobots,
        homeworkAddStdbQuestion: noneRobots,
        homeworkAddTextbookQuestion: noneRobots,
        homeworkProgress: noneRobots,
        homeworkRegister: noneRobots,
        question: noneRobots,
        commentControl: noneRobots,
        socialLoginError: noneRobots,
        // 値はエスビューアに準拠
        errorPages: {
          badRequest: noneRobots,
          forbidden: noneRobots,
          notFound: noneRobots,
          internalServerError: noneRobots,
          serviceUnavailable: noneRobots,
        },
      }
    // モードがどれにも該当しない場合は、全て none とする
    default:
      return {
        login: noneRobots,
        homework: noneRobots,
        homeworkAddPdfQuestion: noneRobots,
        homeworkAddStdbQuestion: noneRobots,
        homeworkAddTextbookQuestion: noneRobots,
        homeworkProgress: noneRobots,
        homeworkRegister: noneRobots,
        question: noneRobots,
        commentControl: noneRobots,
        socialLoginError: noneRobots,
        errorPages: {
          badRequest: noneRobots,
          forbidden: noneRobots,
          notFound: noneRobots,
          internalServerError: noneRobots,
          serviceUnavailable: noneRobots,
        },
      }
  }
}