<template>
  <div class="button-edit">
    <button
      type="button"
      class="btn"
      :class="{'no-editing-permission' : !editingPermission}"
    >
      <IconEdit
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconEdit from "@/components/atoms/icons/IconEdit.vue"

export default {
  name: "ButtonEdit",
  components: {
    IconEdit,
  },
  
  props: {
    editingPermission : { type: Boolean }
  },
}
</script>

<style lang="scss" scoped>
.btn.no-editing-permission{
  opacity: 0.3 !important;
  filter:grayscale(1);
  cursor: auto!important;
}
</style>