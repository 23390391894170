<template>
  <div id="toolbarArea">
    <!-- ツールタブ -->
    <div id="toolbarTabs">
      <ul class="tab_area">
        <li class="tab_li active">
          <span>ツール</span>
          <!-- 選択中ツール -->
          <div
            v-if="toolType !== 'none'"
            class="toolbarToolIn"
          >
            <span v-if="toolIsPen">選択中：ペン</span>
            <span v-if="toolIsText">選択中：テキスト</span>
            <span v-if="toolIsErase">選択中：消しゴム</span>
            <span v-if="toolIsZoom">選択中：拡大・縮小</span>
            <span
              class="csp"
              @click="toolType='none'"
            >×</span>
          </div>
        </li>
      </ul>
    </div>
   
    <!-- ツールアイコン -->
    <div
      id="toolbarContents"
      class="clearfix"
    >
      <ul id="tool">
        <li id="toolbar">
          <ul id="basicTool">
            <!-- ペンツール関連-->
            <li
              v-if="editable"
              class="pen"
            >
              <img
                :src="toolPenIcon"
                class="csp"
                :class="{undoNonActive : !editingPermission}"
                alt="ペン"
                @click="onClickWritingTool('pen')"
              >
            </li>
            <li v-if="toolIsPen">
              <ul class="selectTools">
                <!------ ペンパネル ------>
                <li
                  v-if="toolIsPen"
                  id="penPanel"
                  class="selectTool"
                >
                  <div class="penList_WP">
                    <ul
                      id="penPanelOption"
                      class="penList panelOptionList clearFix"
                    >
                      <li
                        class="penTool"
                        @click="subPanelIsPenType=!subPanelIsPenType"
                      >
                        <img
                          :src="penTypeIcon"
                          alt="種類"
                        >
                      </li>
                      <li
                        class="penColor"
                        :style="{'background-color': penColor}"
                        @click="subPanelIsPenColor=!subPanelIsPenColor"
                      >
                        <div class="color_choice01" />
                      </li>
                      <li
                        class="penSize"
                        @click="subPanelIsPenSize=!subPanelIsPenSize"
                      >
                        <div class="penThin">
                          <hr
                            :style="{
                              height: penSizeDraw+'px',
                            }"
                          >
                        </div>
                      </li>
                      <li
                        class="penOpacity"
                        @click="subPanelIsPenOpacity=!subPanelIsPenOpacity"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/opacity/pen_opacity.png"
                          alt="透明度"
                          :style="{opacity: penOpacity}"
                        >
                        <span>透明度</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    id="penWrapSub"
                    class="panelWrapSub clearFix"
                  >
                    <!-- 種類 -->
                    <ul
                      id="penToolOption"
                      class="penPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsPenType}"
                    >
                      <li
                        :class="{active: penType==='path'}"
                        @click="penType='path'"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/pen_type/pen_free.png"
                          alt="フリー"
                        >
                      </li>
                      <li
                        :class="{active: penType==='line'}"
                        @click="penType='line'"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/pen_type/pen_line.png"
                          alt="直線"
                        >
                      </li>
                      <li
                        :class="{active: penType==='vartHori'}"
                        @click="penType='vartHori'"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/pen_type/pen_horizon.png"
                          alt="垂直水平"
                        >
                      </li>
                      <li
                        :class="{active: penType==='rect'}"
                        @click="penType='rect'"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/pen_type/pen_rectangle.png"
                          alt="矩形"
                        >
                      </li>
                      <li
                        :class="{active: penType==='ellipse'}"
                        @click="penType='ellipse'"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/pen_type/pen_circle.png"
                          alt="円"
                        >
                      </li>
                    </ul>
                    <!-- 色 -->
                    <ul
                      id="penColorOption"
                      class="penPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsPenColor}"
                    >
                      <li
                        v-for="(color, idx) in penColors"
                        :key="color"
                        :class="{active: penColorIndex===idx}"
                        @click="penColorIndex=idx"
                      >
                        <div
                          :style="{'background-color': color}"
                        />
                      </li>
                    </ul>
                    <!-- 太さ -->
                    <ul
                      id="penSizeOption"
                      class="penPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsPenSize}"
                    >
                      <div class="sizePrev">
                        <div
                          class="penThin"
                        >
                          <hr
                            :style="{
                              height:penSizeDraw+'px',
                            }"
                          >
                        </div>
                      </div>
                      <div class="sizeOutput">
                        {{ penSizeIndex + 1 }}
                      </div>
                      <div class="sizeInput">
                        <p
                          class="small"
                          @click="penSizeIndex--"
                        >
                          －
                        </p>
                        <input
                          v-model="penSizeIndex"
                          type="range"
                          min="0"
                          :max="penSizes.length-1"
                          step="1"
                        >
                        <p
                          class="large"
                          @click="penSizeIndex++"
                        >
                          ＋
                        </p>
                      </div>
                    </ul>
                    <!-- 不透明度 -->
                    <ul
                      id="penOpacityOption"
                      class="penPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsPenOpacity}"
                    >
                      <li
                        v-for="(opacity, idx) in penOpacities"
                        :key="opacity"
                        :class="{active: penOpacityIndex===idx}"
                        @click="penOpacityIndex=idx"
                      >
                        <img
                          src="@/assets/images/tools/penPanel/opacity/pen_opacity.png"
                          :alt="'透明度('+opacity+')'"
                          :style="{opacity: opacity}"
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
            <!-- テキストツール関連-->
            <li
              v-if="editable"
              class="text"
            >
              <img
                :src="toolTextIcon"
                class="csp"
                :class="{undoNonActive : !editingPermission}"
                alt="テキスト"
                @click="onClickWritingTool('text')"
              >
            </li>
            <li v-if="toolIsText">
              <ul class="selectTools">
                <!------ テキストパネル ------>
                <li
                  v-if="toolIsText"
                  id="textPanel"
                  class="selectTool"
                >
                  <div class="textList_WP">
                    <ul
                      id="textPanelOption"
                      class="textList panelOptionList clearFix"
                    >
                      <li
                        id="textColorChoice"
                        class="textColor"
                        :style="{'background-color': textColor}"
                        @click="subPanelIsTextColor=!subPanelIsTextColor"
                      >
                        <img
                          src="@/assets/images/tools/textPanel/text_color.png"
                          style="width: 105%; height: 105%;"
                        >
                      </li>
                      <li
                        id="textSizeChoice"
                        class="fontSize"
                        @click="subPanelIsTextSize=!subPanelIsTextSize"
                      >
                        <img
                          src="@/assets/images/tools/textPanel/blind_size.png"
                          alt="種類"
                        >
                      </li>
                      <li
                        id="textFontChoice"
                        class="fontValue"
                        @click="subPanelIsTextFont=!subPanelIsTextFont"
                      >
                        <img
                          src="@/assets/images/tools/textPanel/blind_font.png"
                          alt="フォント"
                        >
                      </li>
                    </ul>
                  </div>
                  <div
                    id="textWrapSub"
                    class="panelWrapSub clearFix"
                  >
                    <!-- テキストカラー -->
                    <ul
                      id="textColorOption"
                      class="textPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsTextColor}"
                    >
                      <li
                        v-for="(color, idx) in textColors"
                        :key="color"
                        :class="{active: textColorIndex===idx}"
                        :style="{'background-color': color}"
                        @click="textColorIndex=idx"
                      />
                    </ul>
                    <!-- フォントサイズ -->
                    <ul
                      id="textSizeOption"
                      class="textPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsTextSize}"
                    >
                      <div
                        class="sizePrev"
                      >
                        <p
                          :class="{fontSerif: textIsMincho}"
                          :style="{'font-size': textSizeDraw + 'px'}"
                        >
                          あ
                        </p>
                      </div>
                      <div
                        class="sizeOutput"
                      >
                        {{ textSizeIndex + 1 }}
                      </div>
                      <div
                        class="sizeInput"
                      >
                        <p
                          class="small"
                          @click="textSizeIndex--"
                        >
                          －
                        </p>
                        <input
                          v-model="textSizeIndex"
                          type="range"
                          value="0"
                          min="0"
                          :max="textSizes.length - 1"
                          step="1"
                        >
                        <p
                          class="large"
                          @click="textSizeIndex++"
                        >
                          ＋
                        </p>
                      </div>
                    </ul>
                    <!-- 書体 -->
                    <ul
                      id="textFontOption"
                      class="textPanelOption panelSubUl"
                      :class="{displayNone: !subPanelIsTextFont}"
                    >
                      <li
                        :class="{active: textFontIndex===0}"
                        @click="textFontIndex=0"
                      >
                        <img
                          src="@/assets/images/tools/textPanel/blind_gothic.png"
                          alt="ゴシック"
                        >
                      </li>
                      <li
                        :class="{active: textFontIndex===1}"
                        @click="textFontIndex=1"
                      >
                        <img
                          src="@/assets/images/tools/textPanel/blind_mincho.png"
                          alt="明朝"
                        >
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
            <!-- 消しゴムツール関連-->
            <li
              v-if="editable"
              class="eraser"
            >
              <img
                :src="toolEraseIcon"
                class="csp"
                :class="{undoNonActive : !editingPermission}"
                alt="消しゴム"
                @click="onClickWritingTool('eraser')"
              >
            </li>
            <li v-if="toolIsErase">
              <ul class="selectTools">
                <!------ 消しゴムパネル ------>
                <li
                  v-if="toolIsErase"
                  id="eraserPanel"
                  class="selectTool"
                >
                  <ul
                    id="eraserPanelOption"
                    class="eraserList panelOptionList clearFix"
                  >
                    <li
                      id="eraserOption"
                      class="active"
                    >
                      <img
                        src="@/assets/images/tools/eraserPanel/eraser.png"
                        alt="消しゴム"
                      >
                    </li>
                    <li
                      id="eraserAllOption"
                      @click="onClickEraseAll"
                    >
                      <dl class="pageDelete">
                        <dd class="remove">
                          <img
                            src="@/assets/images/tools/eraserPanel/eraser_all.png"
                            alt="ゴミ箱"
                          >
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </li>	
              </ul>
            </li>
            <!-- 拡大縮小ツール関連-->
            <li class="zoom  vertical-border-left">
              <img
                :src="toolZoomIcon"
                class="csp"
                alt="拡大・縮小"
                @click="toolIsZoom=!toolIsZoom"
              >
            </li>
            <li v-if="toolIsZoom">
              <ul class="selectTools">
                <!------ 拡大縮小パネル ------>
                <li
                  v-if="toolIsZoom"
                  id="zoomPanel"
                  class="selectTool"
                >
                  <ul
                    id="zoomPanelOption"
                    class="zoomList panelOptionList clearFix"
                  >
                    <li
                      id="btnExpansion"
                      :class="{
                        zoomNonActive: isScaleMax || isSelectingZoomRange,
                      }"
                    >
                      <img
                        src="@/assets/images/tools/zoomPanel/zoom_in.png"
                        alt="拡大"
                        @click="unselectZoomDrag(() => scaleIndex++)"
                      >
                    </li>
                    <li
                      id="btnReset"
                      :class="{
                        zoomNonActive: isSelectingZoomRange,
                      }"
                    >
                      <!-- scale = 0 は特別な意味をもっている。 倍率および位置を初期表示状態にする -->
                      <img
                        src="@/assets/images/tools/zoomPanel/zoom_100.png"
                        alt="等倍"
                        @click="unselectZoomDrag(() => scale=0)"
                      >
                    </li>
                    <li
                      id="btnReduction"
                      :class="{
                        zoomNonActive: isScaleMin || isSelectingZoomRange,
                      }"
                    >
                      <img
                        src="@/assets/images/tools/zoomPanel/zoom_out.png"
                        alt="縮小"
                        @click="unselectZoomDrag(() => scaleIndex--)"
                      >
                    </li>
                    <li
                      id="zoomDrag"
                      :class="{
                        active: isSelectingZoomRange,
                      }"
                    >
                      <img
                        src="@/assets/images/tools/zoomPanel/zoom_drag.png"
                        alt="範囲選択拡大"
                        @click="isSelectingZoomRange = !isSelectingZoomRange"
                      >
                    </li>
                  </ul>
                </li>		
              </ul>
            </li>
            <!-- アンドゥ、リドゥツール関連-->
            <li
              v-if="editable"
              class="undoredo"
            >
              <img
                id="btnUndo"
                class="csp"
                :class="{undoNonActive: isUndoOff}"
                src="@/assets/images/tools/undo.png"
                alt="Undo"
                @click="onClickUndo"
              >
              <img
                id="btnRedo"
                class="csp"
                :class="{undoNonActive: isRedoOff}"
                src="@/assets/images/tools/redo.png"
                alt="Redo"
                @click="onClickRedo"
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "HomeworkWriteInfoToolbar",
  props: {
    configPageTeacher: {
      type: Object,
      default: function(){
        return {};
      }
    },
    condTeacher: {
      type: Object,
      default: function(){
        return {};
      }
    },
    scales: {
      type: Array,
      default: function(){
        return []
      },
    },
    initialScale: {
      type: Number,
      default: 1.0,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    editingPermission: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
  },
  data: function(){
    return {
      subPanel: 'none',
    }
  },
  computed: {
    ...mapGetters('homework',[
      'toolbarInfoOfwriteInfo',
    ]),
    toolbarInfo:{
      get(){
        return this.toolbarInfoOfwriteInfo;
      },
      set(value){
        this.setToolbarInfoOfWriteInfo(value);
      }
    },
    toolType:{
      get(){
        return this.toolbarInfo.toolType;
      },
      set(value){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.toolType = value;
        this.toolbarInfo = toolbarInfo;
        if(value !== 'zoom'){
          this.isSelectingZoomRange = false;
        }
      },
    },
    penInfo:{
      get(){
        return this.toolbarInfo.penInfo;
      }
    },
    textInfo:{
      get(){
        return this.toolbarInfo.textInfo;
      }
    },
    // ツール選択状態
    toolIsPen: {
      get(){
        return this.toolType === 'pen';
      },
      set(value){
        this.toolType = value ? 'pen' : 'none';
        this.subPanel = 'none';
      }
    },
    toolIsText: {
      get(){
        return this.toolType === 'text';
      },
      set(value){
        this.toolType = value ? 'text' : 'none';
        this.subPanel = 'none';
      }
    },
    toolIsErase: {
      get(){
        return this.toolType === 'erase';
      },
      set(value){
        this.toolType = value ? 'erase' : 'none';
        this.subPanel = 'none';
      }
    },
    toolIsZoom: {
      get(){
        return this.toolType === 'zoom';
      },
      set(value){
        this.toolType = value ? 'zoom' : 'none';
        this.subPanel = 'none';
      }
    },
    // ペンツールアイコン
    toolPenIcon:{
      get(){
        const state = this.toolIsPen ? 'active' : 'off';
        return require(`@/assets/images/tools/tool_pen_${state}.png`);
      }
    },
    // テキストツールアイコン
    toolTextIcon:{
      get(){
        const state = this.toolIsText ? 'active' : 'off';
        return require(`@/assets/images/tools/tool_text_${state}.png`);
      }
    },
    // 消しゴムツールアイコン
    toolEraseIcon:{
      get(){
        const state = this.toolIsErase ? 'active' : 'off';
        return require(`@/assets/images/tools/tool_eraser_${state}.png`);
      }
    },
    // 拡縮ツールアイコン
    toolZoomIcon:{
      get(){
        const state = this.toolIsZoom ? 'active' : 'off';
        return require(`@/assets/images/tools/tool_zoom_${state}.png`);
      }
    },
    // ペンツールのサブパネル選択状態
    subPanelIsPenType: {
      get(){
        return this.toolIsPen && this.subPanel === 'pen_type';
      },
      set(value){
        this.subPanel = this.toolIsPen && value ? 'pen_type' : 'none';
      }
    },
    subPanelIsPenColor: {
      get(){
        return this.toolIsPen && this.subPanel === 'pen_color';
      },
      set(value){
        this.subPanel = this.toolIsPen && value ? 'pen_color' : 'none';
      }
    },
    subPanelIsPenSize: {
      get(){
        return this.toolIsPen && this.subPanel === 'pen_size';
      },
      set(value){
        this.subPanel = this.toolIsPen && value ? 'pen_size' : 'none';
      }
    },
    subPanelIsPenOpacity: {
      get(){
        return this.toolIsPen && this.subPanel === 'pen_opacity';
      },
      set(value){
        this.subPanel = this.toolIsPen && value ? 'pen_opacity' : 'none';
      }
    },
    subPanelIsTextColor: {
      get(){
        return this.toolIsText && this.subPanel === 'text_color';
      },
      set(value){
        this.subPanel = this.toolIsText && value ? 'text_color' : 'none';
      }
    },
    subPanelIsTextSize: {
      get(){
        return this.toolIsText && this.subPanel === 'text_size';
      },
      set(value){
        this.subPanel = this.toolIsText && value ? 'text_size' : 'none';
      }
    },
    subPanelIsTextFont: {
      get(){
        return this.toolIsText && this.subPanel === 'text_font';
      },
      set(value){
        this.subPanel = this.toolIsText && value ? 'text_font' : 'none';
      }
    },
    // ペン種別
    penType: {
      get(){
        return this.penInfo.type;
      },
      set(value){
        this.subPanel = 'none';
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.penInfo.type = value;
        this.toolbarInfo = toolbarInfo;
      }
    },
    // ペン種別アイコン画像パス
    penTypeIcon: {
      get(){
        let name = '';
        switch(this.penType){
          case 'path':      name = 'pen_free'; break;
          case 'line':      name = 'pen_line'; break;
          case 'vartHori':  name = 'pen_horizon'; break;
          case 'rect':      name = 'pen_rectangle'; break;
          case 'ellipse':   name = 'pen_circle'; break;
          default: return '';
        }
        return require(`@/assets/images/tools/penPanel/pen_type/${name}.png`);
      },
    },
    // ペンの終端を丸めるかどうか
    penCaps: {
      get(){
        // 自由線と円は終端を丸める
        // それ以外は丸めない(角張ったまま)
        return [0, 4].includes(this.penType) ? '10px' : '0px';
      }
    },
    // ペン色定義
    penColors: {
      get(){
        return this.condTeacher.penconfig.penColor;
      }
    },
    // ペン色インデックス
    penColorIndex: {
      get(){
        return this.penInfo.color;
      },
      set(value){
        this.subPanel = 'none';
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        const isChange = toolbarInfo.penInfo.color !== value;
        toolbarInfo.penInfo.color = value;
        this.toolbarInfo = toolbarInfo;
        if(isChange){
          this.textColorIndex = value;
        }
      }
    },
    // ペン色
    penColor: {
      get(){
        return this.penColors[this.penColorIndex];
      }
    },
    // ペンサイズ定義
    penSizes: {
      get(){
        return this.condTeacher.penconfig.penSize;
      }
    },
    // ペンサイズインデックス
    penSizeIndex: {
      get(){
        return this.penInfo.size;
      },
      set(value){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        const val = parseInt(value);
        if(val < 0){
          toolbarInfo.penInfo.size = 0;
        }else if(val >= this.penSizes.length){
          toolbarInfo.penInfo.size = this.penSizes.length - 1;
        }else{
          toolbarInfo.penInfo.size = val;
        }
        this.toolbarInfo = toolbarInfo;
      }
    },
    // ペンサイズ
    penSize: {
      get(){
        return this.penSizes[this.penSizeIndex];
      }
    },
    // 実際に描画するラインの太さ
    penSizeDraw:{
      get(){
        return this.penSize * this.scale * this.baseScale / this.pageRate;
      }
    },
    // ペン不透明度定義
    penOpacities: {
      get(){
        return this.condTeacher.penconfig.penOpacity;
      }
    },
    // ペン不透明度インデックス
    penOpacityIndex: {
      get(){
        return this.penInfo.opacity;
      },
      set(value){
        this.subPanel = 'none';
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.penInfo.opacity = value;
        this.toolbarInfo = toolbarInfo;
      }
    },
    // ペン不透明度
    penOpacity: {
      get(){
        return this.penOpacities[this.penOpacityIndex];
      }
    },
    // テキスト色定義
    textColors: {
      get(){
        return this.condTeacher.textconfig.textColor;
      }
    },
    // テキスト色インデックス
    textColorIndex: {
      get(){
        return this.textInfo.color;
      },
      set(value){
        this.subPanel = 'none';
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        const isChange = toolbarInfo.textInfo.color !== value;
        toolbarInfo.textInfo.color = value;
        this.toolbarInfo = toolbarInfo;
        if(isChange){
          this.penColorIndex = value;
        }
      }
    },
    // テキスト色
    textColor: {
      get(){
        return this.textColors[this.textColorIndex];
      }
    },
    // テキストサイズ定義
    textSizes: {
      get(){
        return this.condTeacher.textconfig.textFontSize;
      }
    },
    // テキストサイズインデックス
    textSizeIndex: {
      get(){
        return this.textInfo.size;
      },
      set(value){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        const val = parseInt(value);
        if(val < 0){
          toolbarInfo.textInfo.size = 0;
        }else if(val >= this.textSizes.length){
          toolbarInfo.textInfo.size = this.textSizes.length - 1;
        }else{
          toolbarInfo.textInfo.size = val;
        }
        this.toolbarInfo = toolbarInfo;
      }
    },
    // テキストサイズ
    textSize: {
      get(){
        return this.textSizes[this.textSizeIndex];
      }
    },
    // テキストサイズ描画
    textSizeDraw:{
      get(){
        return this.textSize * this.scale * this.baseScale / this.pageRate;
      }
    },
    // テキストフォントインデックス
    textFontIndex: {
      get(){
        return this.textInfo.font;
      },
      set(value){
        this.subPanel = 'none';
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        if(value < 0){
          toolbarInfo.textInfo.font = 0;
        }else if(value > 1){
          toolbarInfo.textInfo.font = 1;
        }else{
          toolbarInfo.textInfo.font = value;
        }
        this.toolbarInfo = toolbarInfo;
      }
    },
    // 明朝？
    textIsMincho:{
      get(){
        return this.textFontIndex === 1;
      }
    },
    // 拡縮率
    scale: {
      get(){
        return this.toolbarInfo.scale;
      },
      set(value) {
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.scale = value;
        this.toolbarInfo = toolbarInfo;
      }
    },
    // 拡縮インデックス
    scaleIndex: {
      get(){
        return this.scales
          .map((scale, idx) => { return { scale: scale, diff: Math.abs(scale - this.scale), idx: idx } })
          .reduce((a, b) => a.diff < b.diff ? a : b)
          .idx;
      },
      set(value){
        if(value < 0){
          this.scale = this.scales[0];
        }else if(value > this.scales.length - 1){
          this.scale = this.scales[this.scales.length - 1];
        }else{
          this.scale = this.scales[value];
        }
      },
    },
    isScaleMin: {
      get(){
        return this.scale === Math.min(...this.scales);
      }
    },
    isScaleMax: {
      get(){
        return this.scale === Math.max(...this.scales);
      }
    },
    isSelectingZoomRange: {
      get(){
        return this.toolbarInfo.isSelectingZoomRange;
      },
      set(value){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.isSelectingZoomRange = value;
        this.toolbarInfo = toolbarInfo;
      }
    },
    isUndoOff:{
      get(){
        return this.toolbarInfo.isUndoOff;
      }
    },
    isRedoOff:{
      get(){
        return this.toolbarInfo.isRedoOff;
      }
    },
    baseScale: {
      get(){
        return this.toolbarInfo.baseScale;
      },
    },
    pageRate: {
      get(){
        return this.toolbarInfo.pageRate;
      },
    },
  },
  watch:{
  },
  mounted: function(){
  },
  methods: {
    ...mapActions('homework',[
      'setToolbarInfoOfWriteInfo',
    ]),
    closeSubPanel(){
      this.subPanel = 'none';
    },
    onClickEraseAll(){
      this.$emit('erase-all');
    },
    onClickUndo(){
      this.$emit('undo-history');
    },
    onClickRedo(){
      this.$emit('redo-history');
    },
    /**
     * 対象オブジェクトのクローン(別インスタンス)を取得する
     * @param {*} value クローン対象
     * @returns クローンインスタンス
     */
    cloneObject(value){
      return JSON.parse(JSON.stringify(value));
    },
    onClickWritingTool(tool){
      if(this.editingPermission){
        switch(tool){
          case 'pen':
            this.toolIsPen = !this.toolIsPen;
            break;
          case 'text':
            this.toolIsText = !this.toolIsText;
            break;
          case 'eraser':
            this.toolIsErase = !this.toolIsErase;
            break;
        }
      }else{
        // 編集権限がない場合は書き込みツールの使用を禁止にする
        this.$emit("click-writing-tool",true);
      }
    },
    /**
     * 範囲選択拡大モードの解除
     * 範囲選択拡大中でない場合はコールバックを実行
     * @param callback 範囲選択拡大中でない場合に実行するコールバック
     */
    unselectZoomDrag(callback) {
      if (this.isSelectingZoomRange) {
        // 範囲選択拡大中ははモード解除のみ
        this.isSelectingZoomRange = false;
        return;
      }
      if (callback) {
        callback.apply();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.csp{
  cursor: pointer;
}

ul{
  list-style-type: none;
  text-align: left;
}

/*-----------------------------------------------------------
			Toolbar
-----------------------------------------------------------*/

/*
	全体、共通部分
--------------------------------*/
#toolbarArea {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	transform: translate3d(0, 0, 0);
	background: #f1f1f1;
  border-top: 1px solid #808080;
	transition:all 300ms 0s ease;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &::before{
    content: '';
    width: 100%;
    height: 1px; /*線の太さ*/
    background-color:#808080; /*線の色*/
  }

  #toolbarContents {
    padding: 7px 10px;

    #tool{
      height: 60px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      padding-left: 0;
      margin-left: 0;

      #toolbar {
        width: 100%;

        li {
          display: inline-block;
          vertical-align: middle;

          img {
            &.undoNonActive{
              opacity: 0.3;
              cursor: auto;
            }
          }

          // &:nth-child(1) {
          // 	padding-left: 5px;
          // }
          // &:not(:first-child):not(:last-child){
          // 	margin-right: 15px;
          // }
        }

        > ul {
          font-size: 0;

          > li {
            > img {
              width: 50px;
            }
          }
        }
      }
    }
  }
}

.vertical-border-left {
	border-left: 1px #4d4d4d solid;
	padding-left: 15px;
}

@media screen and (max-width: 1020px) {
	#toolbar #basicTool > li:first-child,
	#toolbar #reflowTool > li:first-child{
		display: block;
	}
}

#toolbar{
  #basicTool{
    padding-left: 0;
    margin-left: 0;
    >li:not(:last-child){
      margin-right: 15px;

      >ul{
        >li:not(:last-child){
          margin-right: 8px;
        }
      }
    }
    img {
      /* ドラッグ無効化 */
      user-select:none;
      -webkit-user-select:none;
      -ms-user-select: none;
      -moz-user-select:none;
      -khtml-user-select:none;
      -webkit-user-drag:none;
      -khtml-user-drag:none;

      /* 長押しのポップアップを無効化 */
      -webkit-touch-callout:none;
      -webkit-user-select:none;
    }
  }
}

/*
	タブ部分
--------------------------------*/
#toolbarTabs{
	display: flex;
	position: absolute;
	left: 0px;
	top: 1px;; 
	transform: translateY(-100%);
	height: 35px;
	align-items: flex-end;
}

#toolbarDeploy {
	background: rgba(30, 30, 30, 1);
	color: #fff;
	font-size: 12px;
	line-height: 20px;
	padding: 8px;
	cursor: pointer;
	border-radius: 5px 5px 0 0;
}

.tab_area{
	list-style: none;
	margin-left: 11px;
	display: flex;
	align-items: flex-end;
  margin-bottom: 0;
  padding-left: 0;
}
.tab_li:first-child > span {
	margin: 0 10px;
}
.tab_li{
	position: relative;
	background-color: #909090;
	padding: 6px 12px;
	margin: 0 0 0 8px;
	color: #fff;
	border-radius: 3px 3px 0 0;
	z-index: 0;
	line-height: 1.4em;
}
.tab_li::before {
	content:'';
	position:absolute;
	z-index: -1;
	top: 0;
	left: -0.8em;  
	bottom: 0;
	width: 2em;
	background-color: #909090;
	transform: skew(-30deg);
	border-radius: 3px 0 0 0;  
}
.tab_li.active{
	color: #4d4d4d;
	background-color: #f1f1f1 ; 
	z-index: 1;
	top: .5px;
}
.tab_li.active::before{
	background-color: #f1f1f1 ;
}
.tab_li:last-child {
	margin-right: 8px;
}

/*
	選択中ツール パネル
--------------------------------*/
.selectTools {
  margin-left: 0;
  padding-left: 0;
}
.selectTool{
	background-color: #a9a9a9;
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 5px;
}
.selectTool li.active{
	border: 2px solid #F00!important;
}

.panelOptionList {
	padding: 5px 12px;
}
.panelOptionList > li {
	position: relative;
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background: #fff;
	cursor: pointer;
}
.panelOptionList li:not(:last-child){
	margin-right: 8px;
}

/*
	選択中ツール タブ内 丸枠
--------------------------------*/
.toolbarToolIn {
	background: #FFF;
	color: #424242;
	font-size: 12px;
	margin-left: 12px;
	padding: 0 3px 0 13px;
	border: 1px solid #808080;
	border-radius: 10px;
	float: right;
	line-height: 1.3em;
}
.toolbarToolIn span:nth-child(2){
	padding-left: 15px;
	padding-right: 3px;
	font-size: 1.5em;
	vertical-align: bottom;
}

/* Edge(Chromium)ハック */
_:lang(x)::-ms-, .toolbarToolIn {
	padding: 1px 3px 0 13px;
}

/* IE11ハック */
_:-ms-lang(x)::-ms-backdrop, .toolbarToolIn {
	padding: .5px 3px 0 13px;
}


/*
	アンドゥリドゥ
--------------------------------*/
.undoredo { 
	display: inline-block;
	position: absolute;
	top:10px;
	right: 10px;
	width: 120px;
	line-height: 50px;
	text-align: right;
  
  img{
    width: 45px !important;
    margin: 2px 0;

    &.undoNonActive{
      opacity: 0.3;
      pointer-events: none;
    }
  }
}



/*-----------------------------------------------------------
			.Panel Common Style
-----------------------------------------------------------*/
/*
	黒背景パネル
--------------------------------*/
.panel {
	padding: 20px;
	font-size: 15px;
	color: #fff;
	border-radius: 3px;
	background: #1E1E1E;
	border: solid 1px #fff;
}
/* 閉じるボタン */
#penPanel > .close,
#textPanel > .close,
#stopwatchPanel > .close,
#clipPanel > .close,
#stampPanel > .close {
	position: absolute;
	top: 7px;
	right: 7px;
}

/*
	選択ツール吹き出し
--------------------------------*/
.panelWrapSub {
	height: auto;
	position: absolute;
	bottom: 77px;
}
.panelWrapSub .panelSubUl {
	width: 340px;
	padding: 10px 15px 0px;
	background: #fff;
	border-radius: 5px;
	border: 2px solid #808080;
	position: relative;
	display: flex;
  flex-flow: row wrap;
  gap: 10px;
}
.panelWrapSub .panelSubUl > li {
	position: relative;
	width: 50px;
	height: 50px;
	border: 2px solid #808080;
	margin-bottom: 10px;
	cursor: pointer;
}
.panelOptionList > li:last-child {
	margin-right: 0px;
}

.panelOptionList > li img,
.panelOptionList > li svg,
.panelWrapSub .panelSubUl > li img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-51%,-51%);
}
.panelOptionList > li svg,
.panelOptionList > li:not(.stampSize):not(.navBtn) img {
	width: 35px;
	height: 35px;
}

#penOpacityOption > li img{
	position: absolute;
    top: 50%;
    left: 50%;
}
#penOpacityOption > li span{
	display: none;
}

@media screen and (max-width: 620px) {
	#penWrapSub,
	#textWrapSub,
	#stampWrapSub {
		bottom: 185px;
	}
}
@media screen and (max-width: 1020px) {
	.panelWrapSub {
		bottom: 134px;
	}
}

.panelSubUl:not(.stampPanelOption) > li img {
	width: 40px;
	height: 40px;
}

.panelSubUl:after,
.panelSubUl:before {
	position: absolute;
	top: 100%;
	border: solid transparent;
	content: "";
	pointer-events: none;
}
.panelWrapSub .panelSubUl:after {
	border-top-color: #fff;
	border-width: 15px;
	margin-left: 3px;
}
.panelWrapSub .panelSubUl:before {
	border-top-color: #808080;
	border-width: 18px;
}
.panelWrapSub > ul:nth-child(1):after,
.panelWrapSub > ul:nth-child(1):before {
	left: 15px;
}
.panelWrapSub > ul:nth-child(2):after,
.panelWrapSub > ul:nth-child(2):before {
	left: 67px;
}
.panelWrapSub > ul:nth-child(3):after,
.panelWrapSub > ul:nth-child(3):before {
	left: 120px;
}
.panelWrapSub > ul:nth-child(4):after,
.panelWrapSub > ul:nth-child(4):before {
	left: 173px;
}
.panelWrapSub > ul:nth-child(5):after,
.panelWrapSub > ul:nth-child(5):before {
	left: 226px;
}

/*----------------------------------------------------------
			textPanel
----------------------------------------------------------*/
#textBackColorChoice,
#textColorChoice {
	overflow: hidden;
}

#textShowAll.unable {
	pointer-events: none;
}
#textShowAll.unable img{
	opacity: 0.3;
}

#textSizeOption {
  .sizePrev {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      display: block;
      position: relative;
      margin: 0;
      width: auto;
      height: auto;
    }
  }
}

#textBackColorOption li {
	background: #fff;
	overflow: hidden;
}
#textColorOption li > img {
	display: none;
}

#makeTextElm{
  position: absolute;
	width: 0px;
	height: 0px;
  border: solid 1px blue;
	opacity: 0.3;
}

/*-----------------------------------------------------------
			penPanel
-----------------------------------------------------------*/
#penColorOption li div {
	width: 46px;
	height: 46px;
}
#penPanelOption .penColor > div {
	width: 45px;
	height: 45px;
	border-radius: 50%;
}

#penPanelOption .penOpacity img {
	transform: translate(-45%,-70%);
}
#penPanelOption .penOpacity span,
#stampPanelOption .stampSize span,
#indexPanelOption li span {
	position: absolute;
	font-size: 10px;
	text-align: center;
	width: 3em;
	top: 60%;
	left: 50%;
	transform: translateX(-50%);
}
#penOpacityOption > li:last-child {
	margin-right: 0;
}
.penSize .penThin {
  height: 100%;
  display: flex;
}

.penSize .penThin hr {
	max-height: 15px;
	width: 30px;
	margin: auto;
  opacity: 1;
  border-radius: 10px;
}

/*-----------------------------------------------------------
			SliderPanel(共通)
-----------------------------------------------------------*/
#textSizeOption,
#penSizeOption {
  width: 170px;
  height: 200px;
}

.sizePrev {
	margin: 10px auto;
	width: 110px;
  height: 110px;
	border: solid 1px #5B5B5B;
	overflow: hidden;
}

.sizePrev svg,
.sizePrev .penThin {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.sizePrev .penThin hr {
	margin: 0 auto;
  opacity: 1;
  width: 80%;
  border-radius: 10px;
}

.sizeOutput {
  position: absolute;
  top: 101px;
  left: 108px;
  width: 30px;
  line-height: 30px;
  background-color: #a9a9a9;
  color: #fff;
  font-size: 25px;
	text-align: center;
	cursor: default;
}

.sizeInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
	padding-bottom: 10px;
	width: 135px;
}

.sizeInput p {
  display: inline-block;
  border-radius: 50px;
	color: #fff;
	background: #016eba;
	font-weight: bold;
  line-height: 1.4;
  height: 20px;
  width: 20px;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

/*#region slider_css */
input[type=range] {
  -webkit-appearance: none;
  width: calc(100% - 50px);
  height: 40px;
  padding: 0;
}

input[type=range]:focus {
    outline: none;
}

/*--- Webkit(Chrome,Safari) -----------------------*/
/* バー全体 */
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: #016eba;
  border: none;
}

/* つまみ */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 3px solid #016eba;
  height: 35px;
  width: 18px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;

  /* 縦位置調整 (つまみの高さ-トラックの高さ)/2 。つまみの高さはborderを含む。*/
  margin-top: -14px;
}
/*--------------------------------------------------*/


/*--- FireFox --------------------------------------*/
/* バー全体 */
input[type=range]::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: #016eba;
  border-radius: 8px;
  border: none;
}

/* つまみ */
input[type=range]::-moz-range-thumb {
  border: 3px solid #016eba;
  height: 32px;
  width: 13px;
  border-radius: 3px;
  background: #fff;
  cursor: pointer;
}
/*--------------------------------------------------*/


/*--- Edge, IE -------------------------------------*/
/* バー全体 */
input[type=range]::-ms-track {
  width: 100%;
  height: 7px;
  background: #016eba;
  border: none;
  border-radius: 8px;
  color: transparent;
}

/* つまみ */
input[type=range]::-ms-thumb {
  border: 3px solid #016eba;
  height: 32px;
  width: 13px;
  border-radius: 3px;
  background: #fff;
}

/* 数値のツールチップ非表示 */
input[type=range]::-ms-tooltip {
  display: none;
}

/* Edge向けに一部設定をリセット */
@supports (-ms-ime-align: auto) {
  input[type=range]::-webkit-slider-thumb {
      margin-top: 0 !important;
  }
}
/*--------------------------------------------------*/
/*#endregion */ 


/*-----------------------------------------------------------
			zoomPanel
-----------------------------------------------------------*/

#rangeSelectRect {
  position: absolute;
  background-color: #99f;
  border: solid 1px blue;
  opacity: 0.3;
  z-index: 350;
}

.zoomNonActive{
	opacity: 0.3;
}

/*	symbol 共通スタイル
-----------------------------------------*/
/* メインアイコン */
.instance .symbolIcon {
	width: 30px;
	height: 30px;
}
.instance .symbolIcon img {
	position: absolute;
	width: 25px;
	height: 25px;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
/* 削除ボタン */
.instance .remove {
	position: absolute;
	top: -10px;
	left: -30px;
	width: 30px;
	height: 30px;
}
/* 編集表示ボタン */
.instance .clipDisplay {
	position: absolute;
	top: -10px;
	right: -10px;
}
/* 編集ボックス */
.instance .clipEdit {
	position: absolute;
	top: -10px;
	right: 10px;
	transform: translateX(108%);
	border: 2px #ABABAB solid;
	border-radius: 5px;
}
.instance .clipEdit input[type="button"] {
	width: 90px;
	line-height: 24px;
	font-size: 115%;
	cursor: pointer;
 }


/*-----------------------------------------------------------

			textSymbol

-----------------------------------------------------------*/

.textInput > .textInputArea.fontSerif,
#textSizeOption .sizePrev p.fontSerif {
	font-family: "ＭＳ Ｐ明朝", "MS PMincho", "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "HiraMinProN-W3", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", serif;
}
.textInput > .textInputArea,
#textSizeOption .sizePrev p{
	font-family: "ＭＳ Ｐゴシック", "MS PGothic", "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", "HiraKakuProN-W3","ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",  Arial, Helvetica, "Osaka", sans-serif;
}

.textInput > .textInputArea{
	display: block;
	height: auto;
	width: auto;
	padding: 5px;
	overflow: hidden;
	resize: none;
	line-height: 1.2em;
	-webkit-appearance: none;
	border-radius: 0;
	border: 2px solid #808080;
}
.textInputArea:focus {
	outline: 0;
	border: 1px solid rgba(0, 90, 255,0.6);
}

.displayNone {
  display: none !important;
}
</style>