var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "toolbarArea" } }, [
    _c("div", { attrs: { id: "toolbarTabs" } }, [
      _c("ul", { staticClass: "tab_area" }, [
        _c("li", { staticClass: "tab_li active" }, [
          _c("span", [_vm._v("ツール")]),
          _vm.toolType !== "none"
            ? _c("div", { staticClass: "toolbarToolIn" }, [
                _vm.toolIsPen ? _c("span", [_vm._v("選択中：ペン")]) : _vm._e(),
                _vm.toolIsText
                  ? _c("span", [_vm._v("選択中：テキスト")])
                  : _vm._e(),
                _vm.toolIsErase
                  ? _c("span", [_vm._v("選択中：消しゴム")])
                  : _vm._e(),
                _vm.toolIsZoom
                  ? _c("span", [_vm._v("選択中：拡大・縮小")])
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "csp",
                    on: {
                      click: function($event) {
                        _vm.toolType = "none"
                      }
                    }
                  },
                  [_vm._v("×")]
                )
              ])
            : _vm._e()
        ])
      ])
    ]),
    _c("div", { staticClass: "clearfix", attrs: { id: "toolbarContents" } }, [
      _c("ul", { attrs: { id: "tool" } }, [
        _c("li", { attrs: { id: "toolbar" } }, [
          _c("ul", { attrs: { id: "basicTool" } }, [
            _vm.editable
              ? _c("li", { staticClass: "pen" }, [
                  _c("img", {
                    staticClass: "csp",
                    class: { undoNonActive: !_vm.editingPermission },
                    attrs: { src: _vm.toolPenIcon, alt: "ペン" },
                    on: {
                      click: function($event) {
                        return _vm.onClickWritingTool("pen")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm.toolIsPen
              ? _c("li", [
                  _c("ul", { staticClass: "selectTools" }, [
                    _vm.toolIsPen
                      ? _c(
                          "li",
                          {
                            staticClass: "selectTool",
                            attrs: { id: "penPanel" }
                          },
                          [
                            _c("div", { staticClass: "penList_WP" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "penList panelOptionList clearFix",
                                  attrs: { id: "penPanelOption" }
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "penTool",
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsPenType = !_vm.subPanelIsPenType
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.penTypeIcon,
                                          alt: "種類"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "penColor",
                                      style: {
                                        "background-color": _vm.penColor
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsPenColor = !_vm.subPanelIsPenColor
                                        }
                                      }
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "color_choice01"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "penSize",
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsPenSize = !_vm.subPanelIsPenSize
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "penThin" }, [
                                        _c("hr", {
                                          style: {
                                            height: _vm.penSizeDraw + "px"
                                          }
                                        })
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "penOpacity",
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsPenOpacity = !_vm.subPanelIsPenOpacity
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        style: { opacity: _vm.penOpacity },
                                        attrs: {
                                          src: require("@/assets/images/tools/penPanel/opacity/pen_opacity.png"),
                                          alt: "透明度"
                                        }
                                      }),
                                      _c("span", [_vm._v("透明度")])
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "panelWrapSub clearFix",
                                attrs: { id: "penWrapSub" }
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "penPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsPenType
                                    },
                                    attrs: { id: "penToolOption" }
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.penType === "path"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penType = "path"
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/penPanel/pen_type/pen_free.png"),
                                            alt: "フリー"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.penType === "line"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penType = "line"
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/penPanel/pen_type/pen_line.png"),
                                            alt: "直線"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.penType === "vartHori"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penType = "vartHori"
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/penPanel/pen_type/pen_horizon.png"),
                                            alt: "垂直水平"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.penType === "rect"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penType = "rect"
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/penPanel/pen_type/pen_rectangle.png"),
                                            alt: "矩形"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.penType === "ellipse"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penType = "ellipse"
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/penPanel/pen_type/pen_circle.png"),
                                            alt: "円"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "penPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsPenColor
                                    },
                                    attrs: { id: "penColorOption" }
                                  },
                                  _vm._l(_vm.penColors, function(color, idx) {
                                    return _c(
                                      "li",
                                      {
                                        key: color,
                                        class: {
                                          active: _vm.penColorIndex === idx
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penColorIndex = idx
                                          }
                                        }
                                      },
                                      [
                                        _c("div", {
                                          style: { "background-color": color }
                                        })
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "penPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsPenSize
                                    },
                                    attrs: { id: "penSizeOption" }
                                  },
                                  [
                                    _c("div", { staticClass: "sizePrev" }, [
                                      _c("div", { staticClass: "penThin" }, [
                                        _c("hr", {
                                          style: {
                                            height: _vm.penSizeDraw + "px"
                                          }
                                        })
                                      ])
                                    ]),
                                    _c("div", { staticClass: "sizeOutput" }, [
                                      _vm._v(
                                        " " + _vm._s(_vm.penSizeIndex + 1) + " "
                                      )
                                    ]),
                                    _c("div", { staticClass: "sizeInput" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "small",
                                          on: {
                                            click: function($event) {
                                              _vm.penSizeIndex--
                                            }
                                          }
                                        },
                                        [_vm._v(" － ")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.penSizeIndex,
                                            expression: "penSizeIndex"
                                          }
                                        ],
                                        attrs: {
                                          type: "range",
                                          min: "0",
                                          max: _vm.penSizes.length - 1,
                                          step: "1"
                                        },
                                        domProps: { value: _vm.penSizeIndex },
                                        on: {
                                          __r: function($event) {
                                            _vm.penSizeIndex =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "large",
                                          on: {
                                            click: function($event) {
                                              _vm.penSizeIndex++
                                            }
                                          }
                                        },
                                        [_vm._v(" ＋ ")]
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "penPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsPenOpacity
                                    },
                                    attrs: { id: "penOpacityOption" }
                                  },
                                  _vm._l(_vm.penOpacities, function(
                                    opacity,
                                    idx
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        key: opacity,
                                        class: {
                                          active: _vm.penOpacityIndex === idx
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.penOpacityIndex = idx
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          style: { opacity: opacity },
                                          attrs: {
                                            src: require("@/assets/images/tools/penPanel/opacity/pen_opacity.png"),
                                            alt: "透明度(" + opacity + ")"
                                          }
                                        })
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm.editable
              ? _c("li", { staticClass: "text" }, [
                  _c("img", {
                    staticClass: "csp",
                    class: { undoNonActive: !_vm.editingPermission },
                    attrs: { src: _vm.toolTextIcon, alt: "テキスト" },
                    on: {
                      click: function($event) {
                        return _vm.onClickWritingTool("text")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm.toolIsText
              ? _c("li", [
                  _c("ul", { staticClass: "selectTools" }, [
                    _vm.toolIsText
                      ? _c(
                          "li",
                          {
                            staticClass: "selectTool",
                            attrs: { id: "textPanel" }
                          },
                          [
                            _c("div", { staticClass: "textList_WP" }, [
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "textList panelOptionList clearFix",
                                  attrs: { id: "textPanelOption" }
                                },
                                [
                                  _c(
                                    "li",
                                    {
                                      staticClass: "textColor",
                                      style: {
                                        "background-color": _vm.textColor
                                      },
                                      attrs: { id: "textColorChoice" },
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsTextColor = !_vm.subPanelIsTextColor
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "105%",
                                          height: "105%"
                                        },
                                        attrs: {
                                          src: require("@/assets/images/tools/textPanel/text_color.png")
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "fontSize",
                                      attrs: { id: "textSizeChoice" },
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsTextSize = !_vm.subPanelIsTextSize
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/tools/textPanel/blind_size.png"),
                                          alt: "種類"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass: "fontValue",
                                      attrs: { id: "textFontChoice" },
                                      on: {
                                        click: function($event) {
                                          _vm.subPanelIsTextFont = !_vm.subPanelIsTextFont
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/tools/textPanel/blind_font.png"),
                                          alt: "フォント"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "panelWrapSub clearFix",
                                attrs: { id: "textWrapSub" }
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    staticClass: "textPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsTextColor
                                    },
                                    attrs: { id: "textColorOption" }
                                  },
                                  _vm._l(_vm.textColors, function(color, idx) {
                                    return _c("li", {
                                      key: color,
                                      class: {
                                        active: _vm.textColorIndex === idx
                                      },
                                      style: { "background-color": color },
                                      on: {
                                        click: function($event) {
                                          _vm.textColorIndex = idx
                                        }
                                      }
                                    })
                                  }),
                                  0
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "textPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsTextSize
                                    },
                                    attrs: { id: "textSizeOption" }
                                  },
                                  [
                                    _c("div", { staticClass: "sizePrev" }, [
                                      _c(
                                        "p",
                                        {
                                          class: {
                                            fontSerif: _vm.textIsMincho
                                          },
                                          style: {
                                            "font-size": _vm.textSizeDraw + "px"
                                          }
                                        },
                                        [_vm._v(" あ ")]
                                      )
                                    ]),
                                    _c("div", { staticClass: "sizeOutput" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.textSizeIndex + 1) +
                                          " "
                                      )
                                    ]),
                                    _c("div", { staticClass: "sizeInput" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "small",
                                          on: {
                                            click: function($event) {
                                              _vm.textSizeIndex--
                                            }
                                          }
                                        },
                                        [_vm._v(" － ")]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.textSizeIndex,
                                            expression: "textSizeIndex"
                                          }
                                        ],
                                        attrs: {
                                          type: "range",
                                          value: "0",
                                          min: "0",
                                          max: _vm.textSizes.length - 1,
                                          step: "1"
                                        },
                                        domProps: { value: _vm.textSizeIndex },
                                        on: {
                                          __r: function($event) {
                                            _vm.textSizeIndex =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "large",
                                          on: {
                                            click: function($event) {
                                              _vm.textSizeIndex++
                                            }
                                          }
                                        },
                                        [_vm._v(" ＋ ")]
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "textPanelOption panelSubUl",
                                    class: {
                                      displayNone: !_vm.subPanelIsTextFont
                                    },
                                    attrs: { id: "textFontOption" }
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.textFontIndex === 0
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.textFontIndex = 0
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/textPanel/blind_gothic.png"),
                                            alt: "ゴシック"
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        class: {
                                          active: _vm.textFontIndex === 1
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.textFontIndex = 1
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/tools/textPanel/blind_mincho.png"),
                                            alt: "明朝"
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm.editable
              ? _c("li", { staticClass: "eraser" }, [
                  _c("img", {
                    staticClass: "csp",
                    class: { undoNonActive: !_vm.editingPermission },
                    attrs: { src: _vm.toolEraseIcon, alt: "消しゴム" },
                    on: {
                      click: function($event) {
                        return _vm.onClickWritingTool("eraser")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm.toolIsErase
              ? _c("li", [
                  _c("ul", { staticClass: "selectTools" }, [
                    _vm.toolIsErase
                      ? _c(
                          "li",
                          {
                            staticClass: "selectTool",
                            attrs: { id: "eraserPanel" }
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "eraserList panelOptionList clearFix",
                                attrs: { id: "eraserPanelOption" }
                              },
                              [
                                _vm._m(0),
                                _c(
                                  "li",
                                  {
                                    attrs: { id: "eraserAllOption" },
                                    on: { click: _vm.onClickEraseAll }
                                  },
                                  [_vm._m(1)]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _c("li", { staticClass: "zoom  vertical-border-left" }, [
              _c("img", {
                staticClass: "csp",
                attrs: { src: _vm.toolZoomIcon, alt: "拡大・縮小" },
                on: {
                  click: function($event) {
                    _vm.toolIsZoom = !_vm.toolIsZoom
                  }
                }
              })
            ]),
            _vm.toolIsZoom
              ? _c("li", [
                  _c("ul", { staticClass: "selectTools" }, [
                    _vm.toolIsZoom
                      ? _c(
                          "li",
                          {
                            staticClass: "selectTool",
                            attrs: { id: "zoomPanel" }
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "zoomList panelOptionList clearFix",
                                attrs: { id: "zoomPanelOption" }
                              },
                              [
                                _c(
                                  "li",
                                  {
                                    class: {
                                      zoomNonActive:
                                        _vm.isScaleMax ||
                                        _vm.isSelectingZoomRange
                                    },
                                    attrs: { id: "btnExpansion" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/tools/zoomPanel/zoom_in.png"),
                                        alt: "拡大"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.unselectZoomDrag(function() {
                                            return _vm.scaleIndex++
                                          })
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    class: {
                                      zoomNonActive: _vm.isSelectingZoomRange
                                    },
                                    attrs: { id: "btnReset" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/tools/zoomPanel/zoom_100.png"),
                                        alt: "等倍"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.unselectZoomDrag(function() {
                                            return (_vm.scale = 0)
                                          })
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    class: {
                                      zoomNonActive:
                                        _vm.isScaleMin ||
                                        _vm.isSelectingZoomRange
                                    },
                                    attrs: { id: "btnReduction" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/tools/zoomPanel/zoom_out.png"),
                                        alt: "縮小"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.unselectZoomDrag(function() {
                                            return _vm.scaleIndex--
                                          })
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "li",
                                  {
                                    class: {
                                      active: _vm.isSelectingZoomRange
                                    },
                                    attrs: { id: "zoomDrag" }
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/tools/zoomPanel/zoom_drag.png"),
                                        alt: "範囲選択拡大"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isSelectingZoomRange = !_vm.isSelectingZoomRange
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _vm.editable
              ? _c("li", { staticClass: "undoredo" }, [
                  _c("img", {
                    staticClass: "csp",
                    class: { undoNonActive: _vm.isUndoOff },
                    attrs: {
                      id: "btnUndo",
                      src: require("@/assets/images/tools/undo.png"),
                      alt: "Undo"
                    },
                    on: { click: _vm.onClickUndo }
                  }),
                  _c("img", {
                    staticClass: "csp",
                    class: { undoNonActive: _vm.isRedoOff },
                    attrs: {
                      id: "btnRedo",
                      src: require("@/assets/images/tools/redo.png"),
                      alt: "Redo"
                    },
                    on: { click: _vm.onClickRedo }
                  })
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "active", attrs: { id: "eraserOption" } }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/tools/eraserPanel/eraser.png"),
          alt: "消しゴム"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "pageDelete" }, [
      _c("dd", { staticClass: "remove" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/tools/eraserPanel/eraser_all.png"),
            alt: "ゴミ箱"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }