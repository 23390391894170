<template>
  <div class="button-bulk-return">
    <button type="button" :class="['btn', 'btn-' + color,{'no-editing-permission' : !editingPermission}]" :style="buttonStyle" :disabled="disabled">
      <div class="d-flex flex-row">
        <IconBulkReturn width="20px" height="20px" />
        &nbsp;
        {{ labelName }}
      </div>
    </button>
  </div>
</template>

<script>
import IconBulkReturn from "@/components/atoms/icons/IconBulkReturn.vue"

export default {
  name: "ButtonBulkReturn",
  components: {
    IconBulkReturn,
  },
  props: {
    labelName: {
      type: String,
      default: function () {
        return ''
      },
    },
    color: {
      type: String,
      default: function () {
        return ''
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    fontSize: {
      type: String,
      default: function () {
        return ''
      },
    },
    padding: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderSize: { type: String, default: "1px" },
    // 無効化フラグ
    disabled: { type: Boolean, default: false },
    // 宿題編集権限
    editingPermission: { type: Boolean, default: true }
  },

  computed: {
    buttonStyle: function () {
      const style = {
        color: this.labelColor,
        border: `solid ${this.borderSize} ${this.borderColor}`,
        "font-size": `${this.fontSize}`,
      }
      if (this.padding) {
        style["padding-left"] = `${this.padding}`
        style["padding-right"] = `${this.padding}`
      }
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 2rem;
  padding: 0.5em 1.2em 0.5em;
  font-weight: bold;
  font-size: 0.9em;
}
.no-editing-permission{
  filter: grayscale(1);
  opacity: 0.3;
  cursor: auto!important;
}
</style>