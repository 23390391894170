var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-handed-image-detail" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _vm.selectedNoteItem.noteItems
              ? _c(
                  "div",
                  { staticClass: "modal-container-outer" },
                  [
                    _c("ButtonClose", {
                      staticClass: "modal-default-button",
                      nativeOn: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    }),
                    _c(
                      "div",
                      { ref: "modalContainer", staticClass: "modal-container" },
                      [
                        _c("div", { staticClass: "header-area" }, [
                          _c("div", { staticClass: "header-left-area" }),
                          _c("div", { staticClass: "header-center-area" }, [
                            _c("h6", [
                              _vm._v(_vm._s(_vm.studentName) + "のノート")
                            ])
                          ]),
                          _c("div", { staticClass: "header-right-area" }, [
                            _vm.currentNoteTypeIsWriteInfo
                              ? _c("div", { staticClass: "header-lesson-no" }, [
                                  _vm._v(
                                    " No." +
                                      _vm._s(_vm.currentNoteLessonNo) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _vm.showPageNo
                              ? _c("div", { staticClass: "header-page" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentNoteMaxPage) +
                                      "枚中" +
                                      _vm._s(_vm.currentNotePageNo) +
                                      "枚目 "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c("div", { staticClass: "image-container" }, [
                          _c(
                            "div",
                            { staticClass: "detail-image" },
                            [
                              _c(
                                "HomeworkNoteImage",
                                _vm._g(
                                  {
                                    ref: "noteImage",
                                    attrs: {
                                      content_scale: 1,
                                      img: _vm.currentNoteItem,
                                      "teacher-padding": true,
                                      draggable: true,
                                      editable: _vm.editable,
                                      scale: 1,
                                      "config-page-teacher":
                                        _vm.configPageTeacher,
                                      "cond-teacher": _vm.condTeacher,
                                      scales: _vm.scales,
                                      "initial-scale": _vm.initialScale,
                                      "write-info-object-to-string":
                                        _vm.writeInfoObjectToString
                                    },
                                    on: {
                                      "update:img": function($event) {
                                        _vm.currentNoteItem = $event
                                      },
                                      "update:configPageTeacher": function(
                                        $event
                                      ) {
                                        _vm.configPageTeacher = $event
                                      },
                                      "update:config-page-teacher": function(
                                        $event
                                      ) {
                                        _vm.configPageTeacher = $event
                                      },
                                      "update:condTeacher": function($event) {
                                        _vm.condTeacher = $event
                                      },
                                      "update:cond-teacher": function($event) {
                                        _vm.condTeacher = $event
                                      }
                                    }
                                  },
                                  {
                                    "drag-start": _vm.onDragStart,
                                    showConfirmForOverWriteInfoSize: function() {
                                      return _vm.$emit(
                                        "showConfirmForOverWriteInfoSize"
                                      )
                                    },
                                    drawStart: function() {
                                      return (_vm.isDrawing = true)
                                    },
                                    drawEnd: function() {
                                      return (_vm.isDrawing = false)
                                    }
                                  }
                                )
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "prev-area" }, [
                          _vm.selectedNoteIndex > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "prev",
                                  style: {
                                    "pointer-events": _vm.isDrawing
                                      ? "none"
                                      : "all"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickTransferArea(
                                        _vm.isPrevData
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/sNavi/icon_prev.png")
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "next-area" }, [
                          _vm.selectedNoteIndex <
                          _vm.selectedNoteItem.noteItems.length - 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "next",
                                  style: {
                                    "pointer-events": _vm.isDrawing
                                      ? "none"
                                      : "all"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickTransferArea(
                                        !_vm.isPrevData
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/sNavi/icon_next.png")
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center" },
                          [
                            _c(
                              "HomeworkWriteInfoToolbar",
                              _vm._g(
                                {
                                  ref: "toolbar",
                                  attrs: {
                                    "config-page-teacher":
                                      _vm.configPageTeacher,
                                    "cond-teacher": _vm.condTeacher,
                                    scales: _vm.scales,
                                    "initial-scale": _vm.initialScale,
                                    editable: _vm.editable,
                                    "editing-permission": _vm.editingPermission
                                  },
                                  on: {
                                    "update:configPageTeacher": function(
                                      $event
                                    ) {
                                      _vm.configPageTeacher = $event
                                    },
                                    "update:config-page-teacher": function(
                                      $event
                                    ) {
                                      _vm.configPageTeacher = $event
                                    },
                                    "update:condTeacher": function($event) {
                                      _vm.condTeacher = $event
                                    },
                                    "update:cond-teacher": function($event) {
                                      _vm.condTeacher = $event
                                    }
                                  }
                                },
                                {
                                  "erase-all": _vm.onEraseAll,
                                  "undo-history": _vm.onUndoHistory,
                                  "redo-history": _vm.onRedoHistory,
                                  "click-writing-tool": function($event) {
                                    this$1.showNoEditPermission = $event
                                  }
                                }
                              )
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _vm.showEraseAllConfirm
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message: _vm.$t("messages.confirm.eraseAllWriteInfo"),
                  "ok-button-id": "buttons.erase"
                }
              },
              {
                "ok-confirm-modal": _vm.onClickOkForEraseAllConfirm,
                "close-confirm-modal": _vm.onClickCloseForEraseAllConfirm
              }
            )
          )
        : _vm._e(),
      _vm.showNoEditPermission
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.noEditingPermission") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showNoEditPermission = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }