<template>
  <div class="homework-add-textbook-question">
    <div class="mt-3 mb-1 mx-4">
      <TitleHomework
        ref="titleArea"
        :help-link="$t('url.helps.addQuestion')"
      />
      <div class="d-flex justify-content-center">
        <p class="mb-0 fw-bold">
          {{ $t("labels.addTextbookQuestion") }}
        </p>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-auto">
          <FormSelectBox
            id="selectedBookId"
            :label-text="$t('labels.textbook')"
            :options="bookSelect"
            initial-value="0"
            @input="onChangeBook"
          />
        </div>
      </div>
      <div
        v-if="showErrorMessageFlg"
        class="text-danger"
      >
        {{ $t("messages.error.canNotPublishSelectedTextbook") }}
      </div>
      <div
        v-if="showCanNotGetTextbook"
        class="text-danger"
      >
        {{ $t("messages.error.canNotGetTextbook") }}
      </div>
      <div
        v-if="showCanNotGetStudent"
        class="text-danger"
      >
        {{ $t("messages.error.canNotGetStudent") }}
      </div>
      <div
        v-if="stateGroupAccounts"
        class="text-danger"
      >
        {{ $t("messages.error.canNotGetSelectTextbook") }}
      </div>
      <div class="row mb-3 question-setting">
        <div class="col-md-4 align-self-stretch tree-wrap">
          <TreeQuestion
            ref="treeQuestion"
            class="tree"
            :list="questionList"
            :grayout-hidden-mode="true"
            v-on="{
              'clicked-question-title': onClickedQuestionTitle,
              'change-preview-tabs': changePreviewAreaTabs,
              'on-dirty': onDirty,
            }"
          />
        </div>
        <PreviewContainer
          :image="image"
          :setting-list="settingList"
          :message-display-flg="messageDisplayFlg"
        />
      </div>
      <div class="row mb-1">
        <div class="col">
          <label
            class="text-end w-100"
            style="font-size: 85%"
          >
            {{ $t("labels.textbookQuestionMessage") }}
          </label>
        </div>
      </div>
      <div class="row mb-3 stream-data-list">
        <div class="col">
          <TableHomeworkStreamDataList
            :stream-data-list="currentHomeworkDetailItems"
            :display-count="5"
            :is-published="isPublished"
            :is-show-button-up-down="false"
            :student-items="studentItems"
            :book-items="bookItems"
            :limit="homeworkMaximumLimitItems[0].items[0].value"
            :stream-group="String(headerInfo.streamGroup)"
            :stream-group-member="selectedMemberItems.groupMemberItems"
            :account-list="accountList"
            v-on="{
              delete: deleteTableRow,
              'changed-stream-data-list': changedStreamDataList,
            }"
          />
        </div>
      </div>
      <div class="row gx-5 justify-content-center">
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.cancel')"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="onClickCancel"
          />
        </div>
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.register')"
            color="layout-theme-light"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="onClickRegister"
          />
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />
    <ModalConfirmOkOnly
      v-if="isOverLimit"
      :message="
        $t('messages.error.overLimitTextBookHomeWorkCount', {
          limit: homeworkMaximumLimitItems[0].items[0].value,
        })
      "
      @close-confirm-modal-ok-only="onOverLimit"
    />
    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />
    <ModalConfirmOkOnly
      v-if="isRegisterBookChange"
      :message="$t('messages.error.registerBookChange')"
      @close-confirm-modal-ok-only="onRegisterBookChange()"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - 教材の問題を追加
 */
import { mapGetters, mapMutations } from "vuex"
import { db, funcs } from "@/dbs/indexedDb"
import mixin from "@/mixins/mixin"
import questionRepository from "@/repositories/question"
import TitleHomework from "@/components/molecules/TitleHomework.vue"
import FormSelectBox from "@/components/atoms/FormSelectBox.vue"
import TableHomeworkStreamDataList from "@/components/organisms/TableHomeworkStreamDataList.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import PreviewContainer from "@/components/organisms/PreviewContainer.vue"
import TreeQuestion from "@/components/organisms/TreeQuestion.vue"
import questionObjectGeneratorRepository from "@/repositories/questionObjectGenerator"
import accountRepository from "@/repositories/account"
import { homeworkTypeCode } from "@/constant/homework"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import { apiResponseCode, sukenServiceId } from "../constant/network"
import { settingTabIdCode } from "../constant/question"
import homeworkRepository from "@/repositories/homework"

export default {
  name: "HomeworkAddTextbookQuestion",
  components: {
    TitleHomework,
    FormSelectBox,
    TableHomeworkStreamDataList,
    ButtonBorderCircleMedium,
    PreviewContainer,
    TreeQuestion,
    LoadingScreen,
    ModalConfirmOkOnly,
  },

  mixins: [mixin],
  props: {
    isPublished: { type: Boolean },
  },
  data: () => ({
    selectedBookId: "",
    settingList: null,
    defaultSettingList: [],
    filterList: [],
    studentItems: [],
    bookItems: [],
    bookSelect: [],
    image: null,
    colorLayoutTheme: "#ff7f27",
    questionPreviewImage: null,
    questionList: [],
    currentHomeworkDetailItems: [],
    isLoading: false,
    showErrorMessageFlg: false,
    showCanNotGetStudent: false,
    showCanNotGetTextbook: false,
    messageDisplayFlg: false,
    isOverLimit: false,
    isHandleCurriculum: true,
    isRegisterBookChange: false,
    handleCurriculums: [],
    selectedBookName: "",
    stateGroupAccounts: false,
    selectedMemberItems: {
      groupMemberItems: [],
      isStreamMySelf: false,
    },
    accountList:[],
  }),
  computed: {
    ...mapGetters("homework", ["headerInfo", "homeworkDetailItems"]),
    ...mapGetters("parameterSettings", ["homeworkMaximumLimitItems"]),
    /**
     * グループ・書籍ドロップダウンリストの項目選択状態を真偽で返す処理（選択時は真）
     */
    isSelectedDropDownList() {
      if (this.selectedBookId === "0" || this.selectedBookId === "") {
        return false
      }

      return true
    },
  },

  created: function () {
    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("resize", this.onHandleResize, false)
  },
  mounted: async function () {
    this.isLoading = true

    // グループ更新（ヘッダのグループ更新処理呼び出し）
    await this.$parent.refreshGroupList();

    this.buttonTab1Height = this.getButtonTab1Height()

    this.currentHomeworkDetailItems = JSON.parse(
      JSON.stringify(
        this.homeworkDetailItems.filter(
          (item) => item.questionType === homeworkTypeCode.textbook
        )
      )
    )

    this.isHandleCurriculum = true
    // 教材パッケージ一覧のレスポンスキャッシュをクリア
    await funcs.responseCache.clearMatch("/accounts/products/books");
    
    const groupProductIds = []; // 指定したグループ内の生徒が所持している商品IDリスト
    const accountList =  await accountRepository.getProductIdsOfSukenAccount(
      sukenServiceId.lms,
      this.headerInfo.streamGroup,
      this.loginUserInfo.accountId,
      this.loginUserInfo.sessionToken
    )
    this.accountList = accountList.data.accountList;
    accountList.data.accountList.forEach(account => {
      account.productIdList.forEach(productId => {
        if (!groupProductIds.includes(productId)) {
          groupProductIds.push(productId);
        }
      })
    })

    try {
      this.selectedMemberItems = await this.getSelectedStreamGroupMemberItems()
      const promise = await this.getSukenAccountWithBooks()
      if (!promise) {
        this.getBookList([])
        this.showCanNotGetTextbook = true

        this.isLoading = false
        return
      }
      this.studentItems = promise.accountItems
      let bookItems = promise.bookItems
      this.bookItems = accountRepository.judgeAddOptionBook(groupProductIds,bookItems);
      this.isHandleCurriculum = this.handleCurriculums.some((curriculum) => {
        return String(curriculum) === this.headerInfo.curriculum
      })
      if (!this.isHandleCurriculum) {
        this.getBookList([])

        this.isLoading = false
        return
      }

      // 紐づく教材が0件の場合
      if (this.bookItems.length === 0) {
        this.showCanNotGetTextbook = true
        this.getBookList([])

        this.isLoading = false
        return
      }
      // 宿題共通設定を取得
      await this.getHomeworkSchoolInfo()
      this.getBookList(this.bookItems)
    } catch (error) {
      this.isHandleCurriculum = this.handleCurriculums.some((curriculum) => {
        return String(curriculum) === this.headerInfo.curriculum
      })
      if (!this.isHandleCurriculum) {
        this.getBookList([])

        this.isLoading = false
        return
      }
      // 紐づく生徒が0件の場合
      this.showCanNotGetStudent = true
      this.getBookList([])
    } finally {
      this.isLoading = false
    }
  },
  beforeDestroy: function () {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("resize", this.onHandleResize, false)
  },

  methods: {
    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.clearSelectedStreamGroupMemberItems()
      await this.$router.push({ name: "Login" })
    },
    /**
     * 登録時に教材の情報が更新されていた際のリロード処理
     */
     async onRegisterBookChange() {
      // ブラウザリロード
      location.reload()
    },
    ...mapMutations("homework", ["setHomeworkDetailItems", "setCmnCategoryItems"]),

    onHandleResize: function () {
      this.buttonTab1Height = this.getButtonTab1Height()
    },
    /**
     * 配信先の選択済リストを初期化
     */
    async clearSelectedStreamGroupMemberItems() {
      await db.selectedStreamGroupMember.put({
        id: 0,
        groupMemberItems: [],
        isStreamMySelf: false,
      })
    },
    /**
     * 配信データが30件を超える場合に30件に調整する
     */
    onOverLimit() {
      let homeworkDetailItems = []
      this.currentHomeworkDetailItems.map((item, index) => {
        // 配信データが30件を超える場合true
        if (index + 1 > this.homeworkMaximumLimitItems[0].items[0].value) {
          // １：要素のチェックボックスのフラグをfalseにする
          this.$set(
            this.questionList[item.parentIndex].nodes[item.childIndex].nodes[
              item.elementIndex
            ],
            "checkFlg",
            false
          )
          // ２：子要素のチェックボックスのフラグのうち１つでもtrueであれば親要素をtrueにし、全てがfalseであれば親要素をfalseにする
          this.questionList[item.parentIndex].nodes[item.childIndex].checkFlg =
            this.questionList[item.parentIndex].nodes[
              item.childIndex
            ].nodes.some((x) => {
              return x.checkFlg === true
            })
          // ２：親要素のチェックボックスのフラグのうち１つでもtrueであれば親要素の親要素をtrueにし、全てがfalseであれば親要素の親要素をfalseにする
          this.questionList[item.parentIndex].checkFlg = this.questionList[
            item.parentIndex
          ].nodes.some((x) => {
            return x.checkFlg === true
          })
        } else {
          // 配信データが30件以内で配列を作り直す
          homeworkDetailItems.push(item)
        }
      })
      // 作り直した配列を適用する
      this.currentHomeworkDetailItems = homeworkDetailItems
      this.$refs.treeQuestion.onChangedChildFlg()
      this.isOverLimit = false
    },
    /**
     * ツリーに変化が生じた場合に発火
     */
    onDirty() {
      for (let parent of this.questionList) {
        for (let child of parent.nodes) {
          for (let node of child.nodes) {
            if (node.checkFlg) {
              // 最下層のチェックフラグがTrueかつ配信データ一覧の件数が規定件数以下の場合は登録処理を行う
              // チェックされた項目が配信データ一覧に登録済みかどうかを取得
              const duplicateCheckFlg = this.currentHomeworkDetailItems.some(
                (item) => this.duplicateCheck(item, node)
              )
              if (!duplicateCheckFlg) {
                // 配信データ一覧に未登録の場合登録を実施
                this.currentHomeworkDetailItems.push({
                  questionType: homeworkTypeCode.textbook,
                  img: node.img,
                  taskName: node.title,
                  bookId: node.bookId,
                  mainQuestionInfo: {
                    chapterId: node.chapterId,
                    nodeId: node.nodeId,
                    quesParentId: node.id,
                    fileName: this.selectedBookName,
                  },
                  subQuestionInfo: {
                    teachingMaterials: node.bookId,
                    filePath: "",
                  },
                  sendMemo: "",
                  questionCount: node.questionCount,
                  sortedDisplaySuffixNumber: 0,
                  parentIndex: node.parentIndex,
                  childIndex: node.childIndex,
                  elementIndex: node.elementIndex,
                })
              }
            } else {
              // 最下層のチェックフラグがFalseの場合は配信データ一覧からデータを削除する
              // チェックされていない項目が配信データ一覧に登録済みかどうかを取得（チェックが外された要素であるかを確認するため）
              const duplicateItemIndex =
                this.currentHomeworkDetailItems.findIndex((item) =>
                  this.duplicateCheck(item, node)
                )
              // チェックが外された要素: 0以上, もとよりチェックされていなかった要素: -1
              if (duplicateItemIndex >= 0) {
                // チェックが外された項目を配信データ一覧から削除する
                this.currentHomeworkDetailItems.splice(duplicateItemIndex, 1)
              }
            }
            // homeworkEdaNo等を振りなおす
            this.currentHomeworkDetailItems.forEach((item, index) => {
              const num = index + 1
              this.$set(item, "homeworkEdaNo", num)
              this.$set(item, "sortedDisplayNo", num)
            })
          }
        }
      }
      this.isOverLimit =
        this.currentHomeworkDetailItems.length >
        this.homeworkMaximumLimitItems[0].items[0].value
    },
    async getSukenAccountWithBooks() {
      this.handleCurriculums = Array.from(
        new Set(this.loginUserInfo.handleCurriculums).values()
      )
      let curriculums = this.handleCurriculums.map((curriculum) => {
        let item = this.nameCurriculums.shortName.find(
          (item) => curriculum == item.code
        )
        return item
      })
      const searchParamCurriculum = curriculums.find((curriculum) => {
        return curriculum.code === this.headerInfo.curriculum
      })
      if (!searchParamCurriculum) {
        return null
      }
      return await accountRepository.getSukenAccountWithBooks(
        String(this.headerInfo.streamGroup),
        this.loginUserInfo.accountId,
        searchParamCurriculum.name,
        this.loginUserInfo.sessionToken,
        this.paramApiSettingItems.find(v => v.itemName === 'accountsProductsBooks').items
      )
    },
    /**
     * 宿題共通設定を取得する
     */
    async getHomeworkSchoolInfo() {
      this.isLoading = true

      try {
        const data = await homeworkRepository.getHomeworkSchoolInfo(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.accountId,
          "COMMON_CATEGORY",
        )
        this.setCmnCategoryItems(data);
      } catch (error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      } finally {
        this.isLoading = false
      }
    },
    getButtonTab1Height: function () {
      return 27.38
    },
    /**
     * 登録押下
     */
    async onClickRegister() {
      this.isLoading = true
      await this.getChangeStateGroups()
      if (this.stateGroupAccounts) {
        this.questionList = []

        this.isLoading = false
        this.isRegisterBookChange = true
        return
      }
      this.setHomeworkDetailItems({
        questionType: homeworkTypeCode.textbook,
        homeworkDetailItems: this.currentHomeworkDetailItems,
      })

      this.isLoading = false
      this.$router.push({ name: "HomeworkRegister" })
    },
    /**
     * キャンセル押下
     */
    onClickCancel: function () {
      this.$router.push({ name: "HomeworkRegister" })
    },
    /**
     * 問題名押下時処理
     */
    async onClickedQuestionTitle(item) {
      const imgSrc = item.img
      // 問題の画像を取得する
      this.image = await questionRepository.getQuestionImage(imgSrc)
      // settingListにpreviewArea用の画像パスを渡す
      // ※３タブの場合のみ
      const suffixList = this.questionList[0].suffix
      if (suffixList) {
        suffixList.forEach(async (suffix, index) => {
          // 問題の画像を取得する
          const convertedImgSrc = imgSrc.replace(/\.jpg/, `_${suffix}.jpg`)
          this.settingList[index].content =
            await questionRepository.getQuestionImage(convertedImgSrc)
        })
      }
      return
    },
    /**
     * 問題リストにチェックフラグ、表示制御フラグ、インデックスを追加
     */
    generateQuestionList(list) {
      if (!list) {
        return []
      }

      list.forEach((parent, parentIndex) => {
        // 1階層目にチェックフラグを追加
        this.$set(parent, "checkFlg", false)
        this.$set(parent, "isOpen", false)
        parent.nodes.forEach((child, childIndex) => {
          this.$set(child, "checkFlg", false)
          this.$set(parent, "isOpen", false)
          child.nodes.forEach((element, elementIndex) => {
            this.$set(element, "checkFlg", false)
            this.$set(parent, "isOpen", false)
            this.$set(element, "bookId", this.selectedBookId)
            this.$set(element, "parentIndex", parentIndex)
            this.$set(element, "childIndex", childIndex)
            this.$set(element, "elementIndex", elementIndex)
            this.$set(element, "chapterId", parent.id)
            this.$set(element, "nodeId", child.id)
          })
        })
      })

      return list
    },
    /**
     * 重複チェック
     */
    duplicateCheck(homeworkDetailItem, questionItem) {
      // 選択済み宿題一覧の要素と問題一覧の要素が一致しているかを確認する
      if (
        homeworkDetailItem.subQuestionInfo.teachingMaterials !==
        questionItem.bookId
      ) {
        return false
      }
      if (
        homeworkDetailItem.mainQuestionInfo.chapterId !== questionItem.chapterId
      ) {
        return false
      }
      if (homeworkDetailItem.mainQuestionInfo.nodeId !== questionItem.nodeId) {
        return false
      }
      if (
        homeworkDetailItem.mainQuestionInfo.quesParentId !== questionItem.id
      ) {
        return false
      }
      return true
    },
    /**
     * テーブルの行削除処理
     */
    deleteTableRow(deleteRowItem) {
      if (
        deleteRowItem.subQuestionInfo.teachingMaterials ===
          this.selectedBookId &&
        "parentIndex" in deleteRowItem
      ) {
        // 引数に紐づく要素のチェックフラグをFalseにする
        this.$set(
          this.questionList[deleteRowItem.parentIndex].nodes[
            deleteRowItem.childIndex
          ].nodes[deleteRowItem.elementIndex],
          "checkFlg",
          false
        )
        // 子要素のチェックボックスのフラグのうち１つでもtrueであれば親要素をtrueにし、全てがfalseであれば親要素をfalseにする
        this.questionList[deleteRowItem.parentIndex].nodes[
          deleteRowItem.childIndex
        ].checkFlg = this.questionList[deleteRowItem.parentIndex].nodes[
          deleteRowItem.childIndex
        ].nodes.some((x) => {
          return x.checkFlg === true
        })
        // 親要素のチェックボックスのフラグのうち１つでもtrueであれば親要素の親要素をtrueにし、全てがfalseであれば親要素の親要素をfalseにする
        this.questionList[deleteRowItem.parentIndex].checkFlg =
          this.questionList[deleteRowItem.parentIndex].nodes.some((x) => {
            return x.checkFlg === true
          })

        this.currentHomeworkDetailItems.splice(deleteRowItem.index, 1)
      } else {
        // 現在選択されている書籍のものではない問題 or APIから取得してきたもので各種インデックスの情報を保持していない問題
        // そのまま削除
        const index = this.currentHomeworkDetailItems.findIndex(
          (item) =>
            item.subQuestionInfo.teachingMaterials ===
              deleteRowItem.subQuestionInfo.teachingMaterials &&
            item.mainQuestionInfo.chapterId ===
              deleteRowItem.mainQuestionInfo.chapterId &&
            item.mainQuestionInfo.nodeId ===
              deleteRowItem.mainQuestionInfo.nodeId &&
            item.mainQuestionInfo.quesParentId ===
              deleteRowItem.mainQuestionInfo.quesParentId
        )
        this.currentHomeworkDetailItems.splice(index, 1)
      }

      // 枝番の修正
      this.currentHomeworkDetailItems.forEach((item, index) => {
        const num = index + 1
        this.$set(item, "homeworkEdaNo", num)
        this.$set(item, "sortedDisplayNo", num)
      })
    },
    getBookList(bookItems) {
      this.bookSelect = accountRepository.getBookList(bookItems)
    },
    /**
     * 書籍リスト選択変更時処理
     */
    async onChangeBook(selectedBookId) {
      this.isLoading = true
      // セッション期間が有効かチェックする
      await this.checkSession()

      if (this.bookSelect.length === 0) {
        return
      }

      await this.getChangeStateGroups()

      if (this.stateGroupAccounts) {
        this.questionList = []

        this.isLoading = false
        return
      }
      this.questionList = []
      this.settingList = null
      this.image = null
      this.showErrorMessageFlg = false

      this.selectedBookId = selectedBookId
      if (this.selectedBookId === "0") {
        this.questionList = []
        this.settingList = null

        this.isLoading = false
        return
      }
      const selectedBookItem = this.bookSelect.find(
        (book) => book.value === selectedBookId
      )
      this.selectedBookName = selectedBookItem.label

      // 「すべて選択」のチェックボックスを問答無用で外す
      // ※le_ques_config.jsonが存在しない教材を選択していた場合はtreeQuestionを参照できないので、チェックが必要
      if (this.$refs.treeQuestion) {
        this.$refs.treeQuestion.uncheckAllCheckBox()
      }
      if (this.isSelectedDropDownList) {
        if (this.showCanNotGetTextbook) {
          this.isLoading = false
          return
        }

        const bookAndAccount = accountRepository.getBookAndStudentAccount(
          this.studentItems,
          this.bookItems,
          selectedBookId
        )
        const selectedBook = bookAndAccount.book
        const studentAccount = bookAndAccount.student

        try {
          // 教材パッケージの閲覧権限を取得
          await this.getCookieSukenAccountWithBooks(
            String(this.headerInfo.streamGroup),
            studentAccount.accountId,
            selectedBook.productIds[0],
            this.selectedBookId,
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken
          )
        } catch (error) {
          if (error.status === apiResponseCode.unauthorized) {
            this.showCanNotGetTextbook = true

            this.isLoading = false
            return
          }
        }
        try {
          // 問題一覧を取得
          const textbookConfigPromise = await this.getTextbookConfig(
            selectedBook.shortCurriculumName,
            this.selectedBookId
          )
          const textbookConfigResult = textbookConfigPromise.data

          // コンテンツリストを取得
          const contentsListPromise = await this.getContentsList(
            selectedBook.shortCurriculumName,
            this.selectedBookId
          )

          const contentsListResult = !contentsListPromise
            ? contentsListPromise
            : contentsListPromise.data

          // 問題リストを取得
          let tempList =
            questionObjectGeneratorRepository.generateStreamObjectTree(
              textbookConfigResult
            )

          this.questionList = this.generateQuestionList(tempList)

          this.connectDatas(this.questionList, this.currentHomeworkDetailItems)
          // 設定項目タブを取得
          const filterList =
            questionObjectGeneratorRepository.getDisplayFilterTab(
              textbookConfigResult,
              contentsListResult
            )
          this.filterList = filterList.filter((filter) => filter.label)
          this.defaultSettingList = new Array(this.filterList.length - 1)
          this.filterList.forEach((filter, index) => {
            // プレビューエリアのタブ構築
            if (
              filter.label !== null &&
              filter.id !== settingTabIdCode.contentId
            ) {
              this.defaultSettingList.splice(index, 1, filter)
            }
          })
          // displayFlgがすべて0の時の処理
          let allHidden = true
          this.questionList.forEach((parent) => {
            parent.nodes.forEach((child) => {
              child.nodes.forEach((node) => {
                if (node.displayFlag !== 0) {
                  allHidden = false
                }
              })
            })
          })
          if (allHidden) {
            this.questionList = []
            this.showErrorMessageFlg = true
          }
        } catch (error) {
          this.isLoading = false
          if (!error || error.status === apiResponseCode.notFound) {
            this.showErrorMessageFlg = true
            return
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
          return
        } finally {
          this.isLoading = false
        }
      }
    },
    /**
     * 配信データ一覧とチェックボックスを紐づける
     */
    connectDatas(questionItems, homeworkItems) {
      if (homeworkItems) {
        homeworkItems.map((item) => {
          if (item.subQuestionInfo.teachingMaterials === this.selectedBookId) {
            questionItems.map((parent, parentIndex) => {
              if (item.mainQuestionInfo.chapterId === parent.id) {
                parent.nodes.map((child, childIndex) => {
                  if (item.mainQuestionInfo.nodeId === child.id) {
                    child.nodes.map((element, elementIndex) => {
                      if (item.mainQuestionInfo.quesParentId === element.id) {
                        this.$set(parent, "checkFlg", true)
                        this.$set(child, "checkFlg", true)
                        this.$set(element, "checkFlg", true)
                        this.$set(item, "parentIndex", parentIndex)
                        this.$set(item, "childIndex", childIndex)
                        this.$set(item, "elementIndex", elementIndex)
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
    },
    /**
     * プレビューエリアのタブに3タブを渡す
     */
    async changePreviewAreaTabs() {
      this.settingList = JSON.parse(JSON.stringify(this.defaultSettingList))
    },
    /**
     * グループ ID に紐づくアカウントが所有している教材パッケージの閲覧権限を取得する
     */
    async getCookieSukenAccountWithBooks(
      groupId,
      studentAccountId,
      productId,
      bookId,
      teacherAccountId,
      sessionToken
    ) {
      return await accountRepository.getCookieSukenAccountWithBooks(
        groupId,
        studentAccountId,
        productId,
        bookId,
        teacherAccountId,
        sessionToken
      )
    },
    /**
     * 引数の教科、教材に紐づく問題一覧を取得する処理
     */
    async getTextbookConfig(curriculum, bookId) {
      return await questionRepository.getTextbookConfigJson(curriculum, bookId)
    },
    /**
     * 引数の教科、教材に紐づくコンテンツリストを取得する処理
     */
    async getContentsList(curriculum, bookId) {
      try {
        return await questionRepository.getContentsListJson(curriculum, bookId)
      } catch (error) {
        if (error.status === apiResponseCode.notFound) {
          return []
        } else {
          return error
        }
      }
    },
    /**
     * 子での値変更時に親の値を変更する
     */
    changedStreamDataList(streamDataList) {
      this.currentHomeworkDetailItems = streamDataList
    },
    /**
     * 選択したグループが変更されているかを取得する
     */
    async getChangeStateGroups() {
      this.stateGroupAccounts = await this.getChangeStateGroupAccounts()
    },
    /**
     * グループに紐づく配信先が変更されているかを取得する
     */
    async getChangeStateGroupAccounts() {
      try {
        if (
          !this.loginUserInfo.handleCurriculums.some(
            (item) => item === Number(this.headerInfo.curriculum)
          )
        ) {
          return false
        }
        const getSukenAccountWithBooksPromise =
          await this.getSukenAccountWithBooks(this.selectedGroupId)
        if (!getSukenAccountWithBooksPromise) {
          this.getBookList([])
          return true
        }
        const promiseBooks = getSukenAccountWithBooksPromise.bookItems

        // 紐づく教材が0件の場合
        if (!promiseBooks || promiseBooks.length === 0) {
          this.showCanNotGetTextbook = true
          this.getBookList([])
          return false
        }

        const checkResult = this.bookItems.every((book) =>
          promiseBooks.find((pBook) => pBook.bookId === book.bookId)
        )
        if (!checkResult) {
          this.bookItems = promiseBooks
          this.getBookList(this.bookItems)
        }
        return !checkResult
      } catch (error) {
        // 紐づく生徒が0件の場合
        this.showCanNotGetStudent = true
        this.getBookList([])

        this.isLoading = false
        return false
      }
    },
    async getSelectedStreamGroupMemberItems() {
      return await db.selectedStreamGroupMember.get(0)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .title-homework {
  ul.list-inline {
    margin-bottom: 0;
  }
}

.container-fluid {
  .question-setting {
    // デザインサイトで設定されていた高さは 508px
    height: 508px;

    .card-header {
      height: 7%;
      padding: 0.4rem;
      border-bottom: none;
      background: var(--bs-table-header);
      color: var(--bs-layout-theme);
      font-weight: bold;
    }

    ul.tab {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;

      li {
        width: 6em;
        height: 100%;
        text-align: center;
        padding: 0.2em 0;
        line-height: 1.2;
        background: #f1f1f0;
        border-top: solid 2px #e5e5e5;
        border-radius: 3px 3px 0 0;
      }

      li:nth-of-type(1) {
        border-left: solid 1px #b3b3b3;
      }

      li.active {
        cursor: pointer;
        border-right: solid 1px #b3b3b3;
        background: #fff;
      }
    }

    ul {
      list-style: none;
    }
    .preview-window {
      height: 200px;
    }
    .tree-wrap {
      position: relative;
    }
    .tree {
      position: absolute;
      width: 90%;
      height: 100%;
    }
  }

  .stream-data-list {
    min-height: 300px;
  }
}
</style>
