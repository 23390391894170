<template>
  <div class="modal-handed-image-detail">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div
            v-if="selectedNoteItem.noteItems"
            class="modal-container-outer"
          >
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div
              ref="modalContainer"
              class="modal-container"
            >
              <div class="header-area">
                <div class="header-left-area" />
                <div class="header-center-area">
                  <h6>{{ studentName }}のノート</h6>
                </div>
                <div
                  class="header-right-area"
                >
                  <div
                    v-if="currentNoteTypeIsWriteInfo"
                    class="header-lesson-no"
                  >
                    No.{{ currentNoteLessonNo }}
                  </div>
                  <div
                    v-if="showPageNo"
                    class="header-page"
                  >
                    {{ currentNoteMaxPage }}枚中{{ currentNotePageNo }}枚目
                  </div>
                </div>
              </div>
              <div class="image-container">
                <div class="detail-image">
                  <HomeworkNoteImage
                    ref="noteImage"
                    :content_scale="1"
                    :img.sync="currentNoteItem"
                    :teacher-padding="true"
                    :draggable="true"
                    :editable="editable"
                    :scale="1"
                    :config-page-teacher.sync="configPageTeacher"
                    :cond-teacher.sync="condTeacher"
                    :scales="scales"
                    :initial-scale="initialScale"
                    :write-info-object-to-string="writeInfoObjectToString"
                    v-on="{
                      'drag-start': onDragStart,
                      showConfirmForOverWriteInfoSize: ()=>$emit('showConfirmForOverWriteInfoSize'),
                      drawStart: ()=> isDrawing = true,
                      drawEnd: ()=> isDrawing = false,
                    }"
                  />
                </div>
              </div>
              <div
                class="prev-area"
              >
                <div
                  v-if="selectedNoteIndex > 0"
                  class="prev"
                  :style="{
                    'pointer-events': isDrawing?'none':'all',
                  }"
                  @click="onClickTransferArea(isPrevData)"
                >
                  <img
                    src="@/assets/images/sNavi/icon_prev.png"
                  >
                </div>
              </div>
              <div
                class="next-area"
              >
                <div
                  v-if="selectedNoteIndex < selectedNoteItem.noteItems.length - 1"
                  class="next"
                  :style="{
                    'pointer-events': isDrawing?'none':'all',
                  }"
                  @click="onClickTransferArea(!isPrevData)"
                >
                  <img
                    src="@/assets/images/sNavi/icon_next.png"
                  >
                </div>
              </div>
              <div class="row justify-content-center">
                <!--- ツールバー -->
                <HomeworkWriteInfoToolbar
                  ref="toolbar"
                  :config-page-teacher.sync="configPageTeacher"
                  :cond-teacher.sync="condTeacher"
                  :scales="scales"
                  :initial-scale="initialScale"
                  :editable="editable"
                  :editing-permission="editingPermission"
                  v-on="{
                    'erase-all': onEraseAll,
                    'undo-history': onUndoHistory,
                    'redo-history': onRedoHistory,
                    'click-writing-tool': ($event) => {
                      this.showNoEditPermission = $event;
                    },
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 書き込み全削除確認 -->
    <ModalConfirm
      v-if="showEraseAllConfirm"
      :message="$t('messages.confirm.eraseAllWriteInfo')"
      ok-button-id="buttons.erase"
      v-on="{
        'ok-confirm-modal': onClickOkForEraseAllConfirm,
        'close-confirm-modal': onClickCloseForEraseAllConfirm,
      }"
    />
    <ModalConfirmOkOnly
      v-if="showNoEditPermission"
      :message="$t('messages.error.noEditingPermission')"
      @close-confirm-modal-ok-only="showNoEditPermission = false"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題進捗 提出データ拡大ポップアップ画面 modal コンポーネント
 */
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import HomeworkNoteImage from "@/components/atoms/HomeworkNoteImage.vue"
import HomeworkWriteInfoToolbar from "@/components/atoms/HomeworkWriteInfoToolbar.vue"
import ModalConfirm from "./ModalConfirm.vue"
import ModalConfirmOkOnly from "./ModalConfirmOkOnly.vue"
import { noteType } from "@/constant/homework"

export default {
  name: "ModalHandedImageDetail",
  components: {
    ButtonClose,
    HomeworkNoteImage,
    HomeworkWriteInfoToolbar,
    ModalConfirm,
    ModalConfirmOkOnly,
  },

  props: {
    studentData: {
      type: Object,
      default: function () {
        return {}
      },
    },
    selectedNoteIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
    showStudentName: {
      type: Boolean,
    },
    selectedNoteItem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    homeworkDetails: {
      type: Object,
      default: function () {
        return {}
      },
    },
    configPageTeacher: {
      type: Object,
      default: function(){
        return {};
      }
    },
    condTeacher: {
      type: Object,
      default: function(){
        return {};
      }
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    writeInfoObjectToString: {
      type: Function,
      required: false,
      default: null,
    },
    editingPermission: {
      type: Boolean,
      default: function () {
        return true;
      }
    },

  },
  data: () => ({
    noteImageComponent: null,
    toolbarComponent: null,
    selectedIndex: 0,
    currentNoteItem: null,
    isPrevData: true,
    scales: [
      0.7,
      0.8,
      0.9,
      1.0,
      1.1,
      1.2,
      1.3,
      1.4,
      1.5,
      1.6,
      1.7,
      1.8,
      1.9,
      2.0,
      2.1,
      2.2,
      2.3,
      2.4,
      2.5,
      2.6,
      2.7,
      2.8,
      2.9,
      3.0,
      3.1,
      3.2,
      3.3,
      3.5,
      3.6,
      3.7
    ],
    initialScale: 1.6,
    showEraseAllConfirm: false,
    isDrawing: false,
    showNoEditPermission:false,
  }),

  computed: {
    /**
     * 表示する生徒名取得
     * @returns 「生徒名表示」チェックボックス
     * (on: 生徒氏名、off: "生徒" + アルファベット)
     */
    studentName() {
      return this.showStudentName
        ? this.studentData.accountName
        : this.studentData.anonymousAccountName
    },
    currentNoteType: {
      get(){
        return this.currentNoteItem ? this.currentNoteItem.noteType : '';
      }
    },
    currentNoteTypeIsWriteInfo: {
      get(){
        return this.currentNoteType === noteType.writeInfo;
      }
    },
    currentNoteLessonNo:{
      get(){
        return this.currentNoteItem ? this.currentNoteItem.lessonNo : '';
      }
    },
    currentNoteTaskName:{
      get(){
        return this.currentNoteItem ? this.currentNoteItem.taskName : '';
      }
    },
    showPageNo:{
      get(){
        return this.currentNoteTypeIsWriteInfo && Number.isInteger(this.currentNoteItem.maxPage);
      }
    },
    currentNotePageNo:{
      get(){
        return this.currentNoteItem ? this.currentNoteItem.pageNo : '';
      }
    },
    currentNoteMaxPage:{
      get(){
        return this.currentNoteItem ? this.currentNoteItem.maxPage : '';
      }
    },
  },
  watch: {
    selectedNoteIndex(newValue, oldValue){
      this.selectedNoteItem.noteItems[oldValue] = this.currentNoteItem;
      this.currentNoteItem = this.selectedNoteItem.noteItems[newValue];
    },
  },
  mounted: async function() {
    this.currentNoteItem = this.selectedNoteItem.noteItems[this.selectedNoteIndex];
    this.noteImageComponent = this.$refs.noteImage;
    this.toolbarComponent = this.$refs.toolbar;

    // リサイズ時にモーダルサイズの調整を行う（iPad対応）
    window.addEventListener('resize', () => {
      this.adjustModal();
    });
    this.adjustModal();
  },
  methods: {
    /**
     * モーダルサイズ調整
     */
    adjustModal() {
      var e = this.$refs.modalContainer;
      if (e) {
        var v = window.innerHeight;
        e.style.height = `${ (v - 20) }px`;
      }
    },
    /**
     * 「キャンセル」押下時の処理
     */
    onClickCancel() {
      this.$emit("close")
    },
    /**
     * スライドボタン押下時の処理
     * @param {bool} isPrev 先頭方向かどうか
     */
    onClickTransferArea(isPrev) {
      this.selectedIndex = this.selectedNoteIndex
      if (isPrev) {
        this.selectedIndex--
      } else {
        this.selectedIndex++
      }
      this.$emit("selected-node-transfer", this.selectedIndex)
    },
    onDragStart(){
      this.toolbarComponent.closeSubPanel();
    },
    onEraseAll(){
      // ツールバーで全削除押下
      // 書き込み削除確認ダイアログ表示
      this.showEraseAllConfirm = true;
    },
    onClickOkForEraseAllConfirm(){
      // 書き込み削除確認OK
      this.showEraseAllConfirm = false;
      this.noteImageComponent.eraseAll();
    },
    onClickCloseForEraseAllConfirm(){
      // 書き込み削除確認キャンセル
      this.showEraseAllConfirm = false;
    },
    /** アンドゥ */
    onUndoHistory(){
      this.noteImageComponent.undoHistory();
    },
    /** リドゥ */
    onRedoHistory(){
      this.noteImageComponent.redoHistory();
    },
}
}
</script>

<style lang="scss" scoped>
ul {
  li {
    list-style: none;
    padding: 0.4em;
  }
  margin: 0 auto;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .modal-container-outer {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      .modal-default-button {
        position: absolute;
        left: calc(100vw - 50px);
      }
      .modal-container {
        position: relative;
        width: calc(100vw - 100px);
        height: calc(100vh - 20px);
        margin: auto;
        // padding: 20px 30px;
        // padding-top: 0;
        // padding-bottom: 1.5rem;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;
        overflow: hidden;

        .header-area {
          padding-top: 0;
          padding-bottom: 0px;
          padding-left: 10px;
          padding-right: 10px;
          margin: 0;
          display: table;
          width: 100%;
          height: 30px;
          border-bottom: 2px #ccc solid;

          .header-left-area {
            display: table-cell;
            text-align: left;
            width: 200px;
            height: 100%;
          }

          .header-center-area {
            display: table-cell;
            text-align: center;
            width: calc(100% - 400px);
            height: 100%;
            position: relative;
            padding: 0;

            h6 {
              margin-top: 0 !important;
              margin-bottom: 5px !important;
              bottom: 0;
              width: 100%;
              text-align: center;
              position: absolute;
            }
          }

          .header-right-area {
            display: table-cell;
            text-align: right;
            width: 200px;
            height: 100%;
            position: relative;

            .header-lesson-no {
              margin: 0;
              padding: 0;
              width: auto;
              height: auto;
              font-weight: bold;
              position: absolute;
              left: 0;
              bottom: 0;
            }

            .header-page {
              margin-top: 0 !important;
              margin-bottom: 0px !important;
              right: 0;
              bottom: 0;
              width: auto;
              position: absolute;
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }

        .image-container {
          height: calc(100% - 100px);
          width: 100%;

          .detail-image {
            width: 100%;
            height: 100%;
            position: relative;

            ::v-deep .image {
              margin: 0 auto;
            }
          }
        }
      }
      .next-area {
        position: absolute;
        height: 100%;
        width: auto;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        pointer-events: none;

        .next {
          width: auto;
          height: auto;
          right: 10px;
          pointer-events: auto;
          cursor: pointer;

          img{
            width: 37px;

            /* ドラッグ無効化 */
            user-select:none;
            -webkit-user-select:none;
            -ms-user-select: none;
            -moz-user-select:none;
            -khtml-user-select:none;
            -webkit-user-drag:none;
            -khtml-user-drag:none;

            /* 長押しのポップアップを無効化 */
            -webkit-touch-callout:none;
            -webkit-user-select:none;
          }
        }
      }
      .prev-area {
        position: absolute;
        height: 100%;
        width: auto;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
      }
      .prev {
        width: auto;
        height: auto;
        left: 10px;
        pointer-events: auto;
        cursor: pointer;

        img{
          width: 37px;

          /* ドラッグ無効化 */
          user-select:none;
          -webkit-user-select:none;
          -ms-user-select: none;
          -moz-user-select:none;
          -khtml-user-select:none;
          -webkit-user-drag:none;
          -khtml-user-drag:none;

          /* 長押しのポップアップを無効化 */
          -webkit-touch-callout:none;
          -webkit-user-select:none;
        }
      }
    }
  }
}
</style>
