var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-input-category" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container-outer" }, [
              _c(
                "div",
                {
                  staticClass: "modal-container p-4",
                  staticStyle: { overflow: "auto", "max-height": "80vh" }
                },
                [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col text-start" },
                      [
                        _c("FormTextField", {
                          attrs: {
                            id: "categoryName",
                            "label-text":
                              "カテゴリー名を入力してください。（15文字以内）",
                            value: _vm.categoryName,
                            "initial-value": _vm.initialCategoryName,
                            "max-length": 15,
                            "is-require": false
                          },
                          on: {
                            input: function($event) {
                              _vm.categoryName = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.inputGroupType === "share"
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c("div", { staticClass: "col  text-end" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("labels.schoolWideActionMessage")) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "row mt-1 error-message",
                      class: { "error-message-visible": _vm.showError }
                    },
                    [
                      _c("div", { staticClass: "col text-start" }, [
                        _vm._v(" " + _vm._s(_vm.errorMessage) + " ")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row h-25 gx-5 justify-content-center mt-4"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": "キャンセル",
                              padding: "0",
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme,
                              "font-size": "1.0rem",
                              width: "8rem"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickCancel.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": "OK",
                              padding: "0",
                              color: "layout-theme-light",
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme,
                              disabled: !_vm.categoryName || _vm.showError,
                              "font-size": "1.0rem",
                              width: "8rem"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickOk.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }