import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { menu } from './modules/common/Menu'
import { configJson } from './modules/common/config/configJson'
import { nameConversions } from './modules/common/config/nameConversions'
import { parameterSettings } from './modules/common/config/parameterSettings'
import { gradeStamp } from './modules/GradeStamp'
import { homework } from './modules/Homework'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // ログインユーザ情報
    loginUserInfo: {
      accountId: '',
      userName: '',
      sessionToken: '',
      lmsApiToken: '',
      schoolId: 0,
      schoolName: '',
      handleCurriculums: [],
      loginDateTime: '',
      groupIds: [],
      shareGroupIds: [],
      isAdmin: false,
    },
    // セッション状態管理
    sessionDisconnect: false,
    // システム情報
    systemInfo: {
      agreedTermsVersion: '',
      viewedNoticeVersion: '',
      viewedUpdateInfoVersion: '',
    },
  },

  getters: {
    loginUserInfo: (state) => {
      return state.loginUserInfo
    },
    sessionDisconnect: (state) => {
      return state.sessionDisconnect
    },
    systemInfo: (state) => {
      return state.systemInfo
    },
  },

  mutations: {
    /**
     * ログインユーザ情報を設定
     */
    setLoginUserInfo(state, payload) {
      state.loginUserInfo = payload.loginUserInfo
    },
    /**
     * ログインユーザの持つグループIDを設定
     */
    setGroupIdsOfLoginUser(state, payload) {
      state.loginUserInfo.groupIds = payload
    },
    /**
     * ログインユーザの持つ共有グループIDを設定
     */
    setShareGroupIdsOfLoginUser(state, payload) {
      state.loginUserInfo.shareGroupIds = payload
    },
    /**
     * ログインユーザの担当教科を設定
     */
    setHandleCurriculumsOfLoginUser(state, payload) {
      state.loginUserInfo.handleCurriculums = payload
    },
    /**
     * セッションの有効無効フラグを設定
     */
    setSessionDisconnect(state, payload) {
      state.sessionDisconnect = payload
    },
    /**
     * システム情報を設定
     */
    setSystemInfo(state, payload) {
      state.systemInfo = payload.systemInfo
    },
    /**
     * システム情報の同意済利用規約バージョンを設定
     */
    setAgreedTermsVersion(state, payload) {
      state.systemInfo.agreedTermsVersion = payload
    },
    /**
     * システム情報の閲覧済お知らせバージョンを設定
     */
    setViewedNoticeVersion(state, payload) {
      state.systemInfo.viewedNoticeVersion = payload
    },
    /**
     * システム情報の閲覧済更新情報バージョンを設定
     */
    setvViewedUpdateInfoVersion(state, payload) {
      state.systemInfo.viewedUpdateInfoVersion = payload
    },
    /**
     * ログインユーザ情報を初期化
     */
    clearLoginUserInfo(state) {
      const clearArray = (target) => {
        if (Array.isArray(target)) {
          target.splice(0);
        }
      }

      state.loginUserInfo.accountId = ''
      state.loginUserInfo.userName = ''
      state.loginUserInfo.sessionToken = ''
      state.loginUserInfo.lmsApiToken = ''
      state.loginUserInfo.schoolId = 0
      state.loginUserInfo.schoolName = ''
      clearArray(state.loginUserInfo.handleCurriculums)
      state.loginUserInfo.loginDateTime = ''
      clearArray(state.loginUserInfo.groupIds)
      clearArray(state.loginUserInfo.shareGroupIds)
    },
  },

  actions: {
    async clearSessionInfo({ commit }) {
      await commit('clearLoginUserInfo')
    },
  },

  modules: {
    menu,
    configJson,
    nameConversions,
    parameterSettings,
    gradeStamp,
    homework,
  },

  plugins: [
    createPersistedState({
      key: 'managementSite',
      storage: window.localStorage,
    })
  ],

  strict: process.env.NODE_ENV !== 'production'
})
