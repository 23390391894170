var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-bulk-return" }, [
    _c(
      "button",
      {
        class: [
          "btn",
          "btn-" + _vm.color,
          { "no-editing-permission": !_vm.editingPermission }
        ],
        style: _vm.buttonStyle,
        attrs: { type: "button", disabled: _vm.disabled }
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-row" },
          [
            _c("IconBulkReturn", { attrs: { width: "20px", height: "20px" } }),
            _vm._v("   " + _vm._s(_vm.labelName) + " ")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }