var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homework-progress" },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row mt-3 mb-2 justify-content-around" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("TitleHomework", {
                attrs: { "help-link": _vm.$t("url.helps.homeworkProgress") }
              })
            ],
            1
          ),
          _c("div", { staticClass: "col text-center homework-name py-2" }, [
            _vm._v(" " + _vm._s(_vm.homeworkName) + " ")
          ]),
          _c("div", { staticClass: "col-auto" }, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("labels.startDateReplaceWords", {
                        date: "：" + _vm.params.startDate
                      })
                    ) +
                    " "
                )
              ]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("labels.deadlineDateReplaceWords", {
                        date: "：" + _vm.params.deadlineDate
                      })
                    ) +
                    " "
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col mt-2 mb-2" }, [
            _c(
              "div",
              { staticClass: "row justify-content-around align-items-center" },
              [
                _c(
                  "div",
                  { staticClass: "col-auto" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "Homework" } } },
                      [
                        _c("ButtonReturn", {
                          attrs: {
                            "label-name": _vm.$t("buttons.returnToList"),
                            "label-color": "#ff7f27",
                            "border-color": "#ff7f27"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col-auto" }, [
                  _c("div", { staticClass: "bulk-return" }, [
                    _c(
                      "div",
                      {
                        staticClass: "bulk-return-button",
                        class: { hide: _vm.isBulkMode }
                      },
                      [
                        _c("ButtonBulkReturn", {
                          style: {
                            color: _vm.editingPermission
                              ? "layout-theme-light"
                              : ""
                          },
                          attrs: {
                            "label-name": _vm.labelBulkMode,
                            "label-color": "#ff7f27",
                            "border-color": "#ff7f27",
                            "font-size": "0.9rem",
                            "editing-permission": _vm.editingPermission
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClickBulkMode.apply(null, arguments)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm.isBulkMode
                      ? _c("div", { staticClass: "bulk-return-menu" }, [
                          _c(
                            "div",
                            { staticClass: "bulk-return-menu-frame" },
                            [
                              _c("label", [
                                _vm._v(_vm._s(_vm.labelSubmissionStatus))
                              ]),
                              _c("FormSelectBox", {
                                ref: "submissionStatusSelectBox",
                                attrs: {
                                  id: "submissionStatus",
                                  value: _vm.selectedSubmissionStatus,
                                  options: _vm.generateSubmissionStatusItems,
                                  "initial-value": _vm.initialSubmissionStatus,
                                  disabled: false,
                                  "is-require": false
                                },
                                on: { input: _vm.onSelectSubmissionStatus }
                              }),
                              _c("ButtonBorderCircleMedium", {
                                attrs: {
                                  "label-name": _vm.$t("buttons.cancel"),
                                  "label-color": "#ff7f27",
                                  "border-color": "#ff7f27",
                                  "font-size": "0.9rem",
                                  width: "100px",
                                  padding: "0"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickBulkMode.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              }),
                              _c("ButtonBorderCircleMedium", {
                                attrs: {
                                  "label-name": _vm.labelBulkReturn,
                                  color: "layout-theme-light",
                                  "label-color": "#ff7f27",
                                  "border-color": "#ff7f27",
                                  "font-size": "0.9rem",
                                  width: "100px",
                                  padding: "0",
                                  disabled: _vm.bulkReturnDisabled
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickBulkReturn.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "col text-end" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center"
                    },
                    [
                      _c("div", { staticClass: "sub-functions-area" }, [
                        _c(
                          "div",
                          {
                            ref: "sub-functions",
                            staticClass: "sub-functions"
                          },
                          [
                            _c("div", {
                              staticClass: "button-leader",
                              domProps: { textContent: _vm._s("･･･") },
                              on: { click: _vm.toggleSubFunction }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "sub-menu-list",
                                attrs: { hidden: !_vm.showSubMenu }
                              },
                              [
                                _c("div", {
                                  staticClass: "sub-menu-item",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("buttons.fileExport")
                                    )
                                  },
                                  on: { click: _vm.onClickExport }
                                }),
                                _c("div", {
                                  staticClass: "sub-menu-item",
                                  class: {
                                    "no-editing-permission": !_vm.editingPermission
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("buttons.homeworkEvalSet")
                                    )
                                  },
                                  on: { click: _vm.onClickHomeworkEvalSet }
                                })
                              ]
                            )
                          ]
                        )
                      ]),
                      _c("CheckBox", {
                        ref: "showStudentNameCheckBox",
                        attrs: {
                          label: "生徒名を表示",
                          "initial-checked": true
                        },
                        on: { input: _vm.onChangedShowStudentNameCheckBox }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ]),
        _c(
          "div",
          { ref: "containerScroller", staticClass: "row container-scroller" },
          [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "w-100 ps-3 pt-0" }, [
                _vm.showWhole
                  ? _c("div", { ref: "wholeArea", staticClass: "row mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "col pe-0" },
                        [
                          _c(
                            "TableHomeworkProgressWhole",
                            _vm._g(
                              {
                                attrs: {
                                  "header-items": !_vm.isLoading
                                    ? _vm.homeworkHeaderItems
                                    : [],
                                  "child-question-rate-items": !_vm.isLoading
                                    ? _vm.childQuestionRateItems
                                    : [],
                                  "book-items": _vm.bookItems,
                                  "student-items": _vm.studentItems,
                                  "homework-details": _vm.homeworkDetails
                                }
                              },
                              {
                                opend:
                                  _vm.tableHomeworkProgressWholeOpenedHandler,
                                closed:
                                  _vm.tableHomeworkProgressWholeClosedHandler
                              }
                            )
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col pe-0" },
                    [
                      _c(
                        "TableHomeworkProgressStudents",
                        _vm._g(
                          {
                            ref: "tableHomeworkProgressStudents",
                            attrs: {
                              "header-items": !_vm.isLoading
                                ? _vm.homeworkHeaderItems
                                : [],
                              items: !_vm.isLoading
                                ? _vm.homeworkProgressItems
                                : [],
                              "view-items": !_vm.isLoading
                                ? _vm.viewProgressItems
                                : [],
                              "grade-stamps": _vm.stamps,
                              "teacher-comment": _vm.teacherComment,
                              "haishin-start-date": _vm.haishinStartDate,
                              "show-student-name": _vm.showStudentName,
                              "progress-params": _vm.params,
                              "homework-details": _vm.homeworkDetails,
                              "ques-image-list": _vm.quesImageList,
                              "config-page-teacher": _vm.configPageTeacher,
                              "cond-teacher": _vm.condTeacher,
                              "show-bulk-check": _vm.showBulkCheck,
                              "show-whole": _vm.showWhole,
                              "school-info": _vm.schoolInfo,
                              "editing-permission": _vm.editingPermission
                            },
                            on: {
                              "update:quesImageList": function($event) {
                                _vm.quesImageList = $event
                              },
                              "update:ques-image-list": function($event) {
                                _vm.quesImageList = $event
                              },
                              "update:configPageTeacher": function($event) {
                                _vm.configPageTeacher = $event
                              },
                              "update:config-page-teacher": function($event) {
                                _vm.configPageTeacher = $event
                              },
                              "update:condTeacher": function($event) {
                                _vm.condTeacher = $event
                              },
                              "update:cond-teacher": function($event) {
                                _vm.condTeacher = $event
                              }
                            }
                          },
                          {
                            "on-click-return-of": _vm.onClickReturnOf,
                            "on-click-send-back": _vm.onClickSendBack,
                            "on-click-send-save": _vm.onClickSendSave,
                            "on-checked-student-data": _vm.onCheckedStudentData,
                            "on-change-show-student-name":
                              _vm.onChangedShowStudentNameCheckBox,
                            "on-reload": _vm.reloadStudentsInfo,
                            "on-close-bulk-return": _vm.closeModalBulkReturn,
                            "on-show-whole":
                              _vm.tableHomeworkProgressWholeOpenedHandler
                          }
                        )
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ]
        )
      ]),
      _vm.showNoEditPermission
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.noEditingPermission") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showNoEditPermission = false
              }
            }
          })
        : _vm._e(),
      _vm.showHomeworkEvalSetModal
        ? _c(
            "ModalSettingEval",
            _vm._g(
              {
                attrs: {
                  "initial-data": _vm.homeworkEvalSet,
                  "is-common": false
                }
              },
              {
                set: _vm.onClickEvalSet,
                cancel: _vm.closeModalHomeworkEvalSet
              }
            )
          )
        : _vm._e(),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.isLoading2 ? _c("LoadingScreen") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }