var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-confirm" }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "modal-container p-4",
            staticStyle: { overflow: "auto", "max-height": "80vh" }
          },
          [
            _c("div", { staticClass: "row h-75 align-items-center" }, [
              _c("div", { staticClass: "col message-wrapper" }, [
                _c("p", { staticClass: "message-area" }, [
                  _vm._v(" " + _vm._s(_vm.message) + " ")
                ]),
                _vm.inputGroupType === "share"
                  ? _c("p", { staticClass: "message-area" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("labels.schoolWideActionMessage")) +
                          " "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "row h-25 gx-5 justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ButtonBorderCircleMedium", {
                    attrs: {
                      "label-name": _vm.cancelLabel,
                      "label-color": _vm.colorLayoutTheme,
                      "border-color": _vm.colorLayoutTheme,
                      "font-size": "1.2rem",
                      width: "10rem"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickCancel.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ButtonBorderCircleMedium", {
                    attrs: {
                      "label-name": _vm.okLabel,
                      color: "layout-theme-light",
                      "label-color": _vm.colorLayoutTheme,
                      "border-color": _vm.colorLayoutTheme,
                      "font-size": "1.2rem",
                      width: "10rem"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickOk.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }