var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-control" },
    [
      _c(
        "div",
        { staticClass: "mt-3 mx-4 mb-2" },
        [
          _c("TitleCommentControl", {
            attrs: { "help-link": _vm.$t("url.helps.comment") }
          })
        ],
        1
      ),
      _c("div", { staticClass: "description" }, [
        _c("span", {
          staticClass: "description-title",
          domProps: {
            textContent: _vm._s(
              _vm.$t("labels.commentControl.description.title")
            )
          }
        }),
        _c("span", {
          staticClass: "description-body",
          domProps: {
            textContent: _vm._s(
              _vm.$t("labels.commentControl.description.body")
            )
          }
        })
      ]),
      _c(
        "div",
        { staticClass: "input-area" },
        [
          _c("CheckBox", {
            attrs: { label: _vm.$t("labels.commentControl.checkbox") },
            model: {
              value: _vm.commentFlagBool,
              callback: function($$v) {
                _vm.commentFlagBool = $$v
              },
              expression: "commentFlagBool"
            }
          }),
          _c("ButtonBorderCircleMedium", {
            attrs: {
              "label-name": _vm.$t("buttons.register"),
              color: "layout-theme-light",
              "label-color": _vm.colorLayoutTheme,
              "border-color": _vm.colorLayoutTheme,
              width: "180px",
              padding: "0"
            },
            nativeOn: {
              click: function($event) {
                return _vm.onClickSave.apply(null, arguments)
              }
            }
          })
        ],
        1
      ),
      _vm.showSaveComplete
        ? _c("ModalConfirmOkOnly", {
            attrs: {
              message: _vm.$t("labels.commentControl.saveComplete"),
              "message-align": "left",
              "button-label": _vm.$t("buttons.close")
            },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showSaveComplete = false
              }
            }
          })
        : _vm._e(),
      _vm.sessionDisconnect
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.overLimitSessionToken") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onSessionDisconnect()
              }
            }
          })
        : _vm._e(),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }