<template>
  <div class="modal-confirm">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container p-4"
          style="overflow: auto; max-height: 80vh;"
        >
          <div class="row h-75 align-items-center">
            <div class="col message-wrapper">
              <p class="message-area">
                {{ message }}
              </p>
              <p class="message-area"  v-if="inputGroupType === 'share'">
                {{$t("labels.schoolWideActionMessage")}}
              </p>
            </div>
          </div>
          <div class="row h-25 gx-5 justify-content-center">
            <div class="col-auto">
              <ButtonBorderCircleMedium
                :label-name="cancelLabel"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                font-size="1.2rem"
                width="10rem"
                @click.native="onClickCancel"
              />
            </div>
            <div class="col-auto">
              <ButtonBorderCircleMedium
                :label-name="okLabel"
                color="layout-theme-light"
                :label-color="colorLayoutTheme"
                :border-color="colorLayoutTheme"
                font-size="1.2rem"
                width="10rem"
                @click.native="onClickOk"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 確認ダイアログ
 */
import { mapGetters } from "vuex"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"

export default {
  name: "ModalConfirm",
  components: {
    ButtonBorderCircleMedium,
  },
  props: {
    message: {
      type: String,
      default: function () {
        return ""
      },
    },
    okButtonId: {
      type: String,
      required: false,
      default: function(){
        return "buttons.ok";
      }
    },
    cancelButtonId: {
      type: String,
      required: false,
      default: function(){
        return "buttons.cancel";
      }
    },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
  }),

  computed: {
    ...mapGetters("homework", [
      "inputGroupType"
    ]),
    okLabel:{
      get(){
        return this.$t(this.okButtonId);
      },
    },
    cancelLabel:{
      get(){
        return this.$t(this.cancelButtonId);
      },
    },
  },

  methods: {
    /**
     * OKが押された
     */
    onClickOk: function () {
      this.$emit("ok-confirm-modal")
      this.$emit("close-confirm-modal")
    },
    /**
     * キャンセルが押された
     */
    onClickCancel: function () {
      this.$emit('cancel-confirm-modal')
      this.$emit('close-confirm-modal')
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      width: 520px;
      height: 250px;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border: 2px solid rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      text-align: center;

      .message-wrapper {
        height: 100px;
        overflow-y: auto;

        .message-area {
          font-size: 1.22rem;
          white-space: pre-wrap;
        }
      }

      ::v-deep .btn {
        padding: 0.6rem 0.5rem;
      }
    }
  }
}
</style>
