/**
 * メンテナンス関連
 */
export const maintenanceConfig = {
  startTime: process.env.VUE_APP_MAINTENANCE_START_TIME,
  endTime: process.env.VUE_APP_MAINTENANCE_END_TIME,
}

/**
 * セッション関連
 */
export const sessionConfig = {
  sessionTimeSpan: Number(process.env.VUE_APP_SESSION_TIME_SPAN),
  responseCacheSpan: Number(process.env.VUE_APP_RESPONSE_CACHE_SPAN) * 1000,
}

/**
 * 利用規約のバージョン
 */
export const termsVersion = "25031601"