var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-edit-category" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c(
              "div",
              { staticClass: "modal-container-outer" },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "modal-container p-4",
                    staticStyle: { "max-height": "80vh" }
                  },
                  [
                    _c("div", { staticClass: "row g-0 category-header" }, [
                      _c("div", { staticClass: "col-auto" }, [
                        _c("p", { staticClass: "text-start fs-5" }, [
                          _vm._v(" カテゴリーの編集 ")
                        ])
                      ]),
                      _vm.inputGroupType == "share"
                        ? _c("div", { staticClass: "col-auto" }, [
                            _c("p", { staticClass: "caution text-end" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("labels.sharedGroupEditMessage")
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "row g-0" }, [
                      _c("div", { staticClass: "col mt-2 mb-2" }, [
                        _c("div", { staticClass: "category-add" }, [
                          _c(
                            "div",
                            {
                              staticClass: "btn-category-add",
                              on: {
                                click: function($event) {
                                  return _vm.onClickEdit(true)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/btn_add.png")
                                }
                              }),
                              _c("div", [_vm._v("新規作成")])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row category-area" }, [
                      _c(
                        "div",
                        { staticClass: "col mt-auto h-100" },
                        [
                          _vm._l(_vm.categories, function(categoryItem, idx) {
                            return [
                              _c(
                                "div",
                                {
                                  key: "category-" + idx,
                                  staticClass: "category-row row g-0"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "category-col col-auto mt-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "category-item" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "category-name" },
                                            [
                                              _vm._v(
                                                " " + _vm._s(categoryItem) + " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "category-btn-area"
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "btn-edit",
                                                attrs: {
                                                  src: require("@/assets/images/btn_edit.png")
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickEdit(
                                                      false,
                                                      categoryItem
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("img", {
                                                staticClass: "btn-trashbox",
                                                attrs: {
                                                  src: require("@/assets/images/btn_trashbox.png")
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickErase(
                                                      categoryItem
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm.showInputCategory
        ? _c(
            "ModalInputCategory",
            _vm._g(
              {
                attrs: {
                  "initial-category-name": _vm.selectedCategory,
                  "action-row": _vm.actionRow
                }
              },
              {
                "on-click-cancel": function() {
                  _vm.showInputCategory = false
                },
                "on-click-ok": _vm.onInputCategory
              }
            )
          )
        : _vm._e(),
      _vm.showOKOnlyMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.okOnlyMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showOKOnlyMessage = false
              }
            }
          })
        : _vm._e(),
      _vm.showConfirmDeleteCategory
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message:
                    "【" +
                    _vm.selectedCategory +
                    "】を削除してもよろしいですか？"
                }
              },
              {
                "ok-confirm-modal": _vm.onOkShowConfirmDeleteCategory,
                "close-confirm-modal": _vm.onCloseConfirmModalCategory
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }