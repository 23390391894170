<template>
  <div class="button-border-circle-medium">
    <button
      type="button"
      :class="[{'no-editing-permission':!editingPermission},'btn', 'btn-' + color]"
      :style="buttonStyle"
      :disabled="disabled"
    >
      {{ labelName }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonBorderCircleMedium",
  props: {
    labelName: {
      type: String,
      default: function () {
        return ''
      },
    },
    color: {
      type: String,
      default: function () {
        return ''
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    fontSize: {
      type: String,
      default: function () {
        return ''
      },
    },
    width: {
      type: String,
      default: function () {
        return ''
      },
    },
    padding: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderSize: { type: String, default: "1px" },
    // 無効化フラグ
    disabled: { type: Boolean, default: false },
    editingPermission: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
  },
  computed: {
    buttonStyle: function () {
      const style = {
        color: this.labelColor,
        border: `solid ${this.borderSize} ${this.borderColor}`,
        "font-size": `${this.fontSize}`,
        width: `${this.width}`,
      }
      if (this.padding) {
        style["padding-left"] = `${this.padding}`
        style["padding-right"] = `${this.padding}`
      }
      return style
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 2rem;
  font-weight: bold;
  padding: 0.5em 3em 0.5em;
}
.no-editing-permission{
  opacity: 0.3;
  cursor: auto!important;
}
</style>