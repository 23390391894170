/**
 * 宿題管理関連 Repository
 */
import { apiResponseCode } from "../constant/network"
import homeworkApi from "../api/homework"
import accountRepository from "./account"
import convertValue from "./convert-value"
import {
  handedStatus,
  correctResult,
  streamStatus,
  homeworkTypeCode,
  excelStyle,
} from "../constant/homework"
import { db } from "@/dbs/indexedDb"
import ExcelJS from 'exceljs'
import Promise from 'bluebird'
import sessionRepository from "@/repositories/session"

export default {
  /**
   * Create 系
   */

  /**
   * 匿名の生徒名を生成する
   * @param {Number} count 生徒名の人数
   * @returns 生成された匿名生徒名の配列
   */
  async generateAnonymousStudentNames(count) {
    let char1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    let char2 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    let char3 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
    char2.unshift("")
    char3.unshift("")
    let names = []

    for (let i = 0; i < char3.length; i++) {
      if (i === 1) {
        char2.shift()
      }
      for (let j = 0; j < char2.length; j++) {
        for (let k = 0; k < char1.length; k++) {
          if (names.length === count) {
            break
          }
          names.push(`生徒${char3[i] + char2[j] + char1[k]}`)
        }
      }
    }

    return names
  },

  /**
   * 公開終了日を取得
   */
  getPeriodDate(endPeriod) {
    const nowDate = new Date()
    const periodTime = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth() + endPeriod,
      nowDate.getDate()
    )
    return periodTime
  },

  /**
   * 生徒情報をリストに反映する処理
   */
   setStudentsInfo(headerItems, homeworkProgressItems, groupMemberItems, childQuestionRateItems, homeworkDetails) {
    let studentCount = 0;
    let homeworkCount = 0;
    let foundGroupMemberItems = [];
    headerItems.forEach((item) => {
      homeworkCount += item.length;
    });
    const totalResponseCounts = new Array(homeworkCount);
    const totalCorrectCounts = new Array(homeworkCount);
    for (let i = 0; i < homeworkCount; i++) {
      totalResponseCounts[i] = 0;
      totalCorrectCounts[i] = 0;
    }
    homeworkProgressItems.forEach((item) => {
      const foundGroupMemberItem = groupMemberItems.find(
        (f) => f.accountId === item.accountId
      );

      // 在校しているかしていないかを判別（在校している: true）
      item.isInGroup = foundGroupMemberItem !== undefined;
      if (foundGroupMemberItem) {
        foundGroupMemberItems.push(foundGroupMemberItem);
        item.accountName = foundGroupMemberItem.accountName;
        item.results.forEach((result, index) => {
          // 教科書所持チェック実施 所持: false 未所持: true
          const disableFlg =
            result.homeworkKind === homeworkTypeCode.textbook &&
            !foundGroupMemberItem.bookItems.some(
              (bookItem) => bookItem.id === result.bookId
            );
          result.disableFlg = disableFlg;
          if (!disableFlg) {
            if (result.result === correctResult.correct) {
              // 正解
              totalResponseCounts[index]++;
              totalCorrectCounts[index]++;
            } else if (
              !disableFlg &&
              result.result === correctResult.incorrect
            ) {
              // 不正解
              totalResponseCounts[index]++;
            }
          }
        });

        studentCount++;
      } else {
        // 在校していない場合は名前を表示しないようにする
        item.accountName = "―";
        // 回答状況もグレーアウト
        item.results.forEach((result) =>
          result.disableFlg = true
        );
      }

      // 回答数
      const responses = item.results.filter((f) => {
        if (f.disableFlg) {
          return false;
        } else {
          return (
            f.result === correctResult.correct ||
            f.result === correctResult.incorrect
          );
        }
      });

      // 正答数
      const corrects = item.results.filter((f) => {
        if (f.disableFlg) {
          return false;
        } else {
          return f.result === correctResult.correct;
        }
      });

      const totalCount = item.results.filter((f) => !f.disableFlg);

      item.responseRate = totalCount.length == 0 ? 0 : Math.round(
        (responses.length / totalCount.length) * 100
      );
      item.correctAnswerRate = totalCount.length == 0 ? 0 : Math.round(
        (corrects.length / totalCount.length) * 100
      );
      item.responseCorrectRate = responses.length == 0 ? 0 : Math.round(
        (corrects.length / responses.length) * 100
      );
    });
    if (studentCount > 0) {
      let i = 0;
      childQuestionRateItems.forEach((questionItem, index) => {
        const kind = homeworkDetails.homeworkInfo[index].kind;
        const bookId = homeworkDetails.homeworkInfo[index].bookId;
        const validStudentCount =
          kind !== homeworkTypeCode.textbook
            ? studentCount
            : foundGroupMemberItems.filter((studentItem) => {
                return studentItem.bookItems.some(
                  (bookItem) => bookItem.id === bookId
                );
              }).length;
        questionItem.forEach((item) => {
          item.responseRate = validStudentCount == 0 ? 0 : Math.round(
            (totalResponseCounts[i] / validStudentCount) * 100
          );
          item.correctRate = validStudentCount == 0 ? 0 : Math.round(
            (totalCorrectCounts[i] / validStudentCount) * 100
          );
          item.responseCorrectRate = totalResponseCounts[i] == 0 ? 0 : Math.round(
            (totalCorrectCounts[i] / totalResponseCounts[i]) * 100
          );
          i++;
        });
      });
    }
  },

  /**
   * 表示用進捗詳細情報リストを取得
   */
  getViewProgressItems(homeworkProgressItems, nameHomeworkStatusItems, headerItems, homeworkKey, labelsExpired) {
    return homeworkProgressItems.map((item) => {
      const foundStatus = nameHomeworkStatusItems.find(
        (x) => Number(x.code) === item.status
      )
      // 期限超過の場合は、(期限超過)の文字を付与する
      // 差し戻しの場合は（期限超過）は付与しない
      const statusDisplayName = item.expired && item.status !== Number(handedStatus.sendBack)
        ? `${foundStatus.name}（${labelsExpired}）`
        : foundStatus.name

      // 大問毎の配列を構築する
      const resultArray = []
      let index = 0
      headerItems.forEach((header) => {
        const array = []
        header.forEach(() => {
          array.push(item.results[index++])
        })
        resultArray.push(array)
      })

      return {
        accountId: item.accountId,
        accountName: item.accountName,
        anonymousAccountName: item.anonymousAccountName,
        homeworkKey: homeworkKey,
        handedDate: item.handedDate,
        handedTime: item.handedTime,
        status: item.status,
        statusDisplayName: statusDisplayName,
        statusColor: item.statusColor,
        eval: item.eval,
        evalManualSetFlg: item.evalManualSetFlg,
        stamp: item.stamp,
        responseRate: item.responseRate,
        correctAnswerRate: item.correctAnswerRate,
        responseCorrectRate: item.responseCorrectRate,
        results: resultArray,
        isInGroup: item.isInGroup,
        noteItems: item.noteItems,
        studentComment: item.studentComment,
        teacherComment: item.teacherComment,
        returnDate: item.returnDate,
        lastSubmitAddDate: item.lastSubmitAddDate,
        comments: item.comments,
        bulkCheck: false,
        initBulkCheck: false,
      }
    })
  },

  /**
   * Excelファイルエクスポート
   */
  async outputProgressInfo(params, homeworkHeaderItems, childQuestionRateItems, viewProgressItems) {
    // 初期化
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('宿題進捗', {views:[{state: 'frozen', xSplit: 5, ySplit:9, showGridLines:false}]});
    
    // 基本情報
    ws.addRow(['グループ', '宿題名', '開始日', '締切日', '教科', '科目']);
    ws.addRow([params.groupName, params.homeworkName, params.startDate, params.deadlineDate, params.curriculumName, params.subjectName]);
    ws.addRow([]);

    // 全体情報-ヘッダー
    const wholeHeaderRowValues = ['', '', '', '', ''].concat(homeworkHeaderItems.flat());
    ws.addRow(wholeHeaderRowValues);
    // 全体情報-明細
    ws.addRow(['回答率', '', '', '', ''].concat(childQuestionRateItems.flatMap((questionItem) => questionItem.map((item) => item.responseRate / 100))));
    ws.addRow(['正答率（／回答）', '', '', '', ''].concat(childQuestionRateItems.flatMap((questionItem) => questionItem.map((item) => item.responseCorrectRate / 100))));
    ws.addRow(['正答率（／全体）', '', '', '', ''].concat(childQuestionRateItems.flatMap((questionItem) => questionItem.map((item) => item.correctRate / 100))));
    ws.addRow([]);
    
    // 生徒情報-ヘッダー
    const studentHeaderRowValues = ['生徒名', '提出状況', '回答率', '正答率\n（／回答）', '正答率\n（／全体）'].concat(homeworkHeaderItems.flat());
    ws.addRow(studentHeaderRowValues);
    // 生徒情報-明細
    viewProgressItems.forEach((progressItem) => {
      let studentDataRowValues;
      if (progressItem.isInGroup) {
        studentDataRowValues = [
          progressItem.accountName,
          progressItem.statusDisplayName,
          !progressItem.responseRate ? 0 : progressItem.responseRate / 100,
          !progressItem.responseCorrectRate ? 0 : progressItem.responseCorrectRate / 100,
          !progressItem.correctAnswerRate ? 0 : progressItem.correctAnswerRate / 100
        ];
      } else {
        studentDataRowValues = [progressItem.accountName, '', '', '', '']
      }
      ws.addRow(studentDataRowValues.concat(progressItem.results.flatMap((result) =>
          result.map((item) => {
            if (item != null && item.result != null && !item.disableFlg) {
              if (item.result === correctResult.notAnswer) {
                return '-';
              } else if (item.result === correctResult.correct) {
                return '○';
              } else if (item.result === correctResult.incorrect) {
                return '×';
              } else {
                return '';
              }
            } else {
              return '';
            }
          })
        ))
      );
    });

    // スタイル設定
    this.setExcelStyle(ws);

    // ファイル生成
    const uint8Array = await wb.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const a = document.createElement('a');
    a.href = (window.URL || window.webkitURL).createObjectURL(blob);
    a.download = '宿題_' + params.groupName + '_' + params.homeworkName + '_' + convertValue.formatDateForYYYYMMDDHHMMSS(new Date()) + '.xlsx';
    a.click();
    a.remove();
  },

  /*
   * Excelスタイル設定
   */
  setExcelStyle(ws) {
    // 値を持つすべての行 (空の行を除く) を走査
    ws.eachRow((row, rowNumber) => {
      // 行内のすべてのセル (空のセルを含む) を走査
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        // 共通-フォント
        cell.font = excelStyle.font;
        // 共通-枠線
        cell.border = excelStyle.border;

        // 共通-ヘッダー
        if (rowNumber == 1 || rowNumber == 4 || rowNumber == 9) {
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          cell.fill = excelStyle.headerFill;
          cell.font = excelStyle.headerFont;
        }

        // 基本情報-開始日,締切日
        if (rowNumber == 2 && (colNumber >= 3 && colNumber <= 4)) {
          cell.numFmt = 'yyyy/m/d';
          cell.alignment = { horizontal: 'left' };
        }

        // 全体情報
        if (rowNumber >= 4 && rowNumber <= 7) {
          
          // 全体情報-ヘッダー-列幅(1回のみ設定)
          if (rowNumber == 4) {
            switch (colNumber) {
              case 1:
                ws.getColumn(colNumber).width = 15;
                break
              case 2:
                ws.getColumn(colNumber).width = 18;
                break
              case 3:
              case 4:
              case 5:
                ws.getColumn(colNumber).width = 12;
                break
              default:
                ws.getColumn(colNumber).width = 8;
            }
          // 全体情報-明細-回答率,正答率
          } else if (colNumber >= 6) {
            cell.numFmt = '0%';
            cell.alignment = { horizontal: 'center' };
            cell.fill = excelStyle.detailFill;
          }

          // 全体情報-枠線
          if (colNumber == 1) {
            cell.border = excelStyle.wholeFirstBorder;
          } else if (colNumber >= 2 && colNumber <= 5) {
            cell.border = excelStyle.wholeEmptyBorder;
          }
        }
        
        // 生徒情報-明細
        if (rowNumber >= 10) {
          // 生徒情報-明細-回答率,正答率
          if (colNumber >= 3 && colNumber <= 5) {
            cell.numFmt = '0%';
            cell.alignment = { horizontal: 'center' };
          // 全体情報-明細-正誤
          } else if (colNumber >= 6) {
            cell.alignment = { horizontal: 'center' };

            if (!cell.value) {
              cell.fill = excelStyle.studentDetailDisableFill;
            } else {
              cell.fill = excelStyle.detailFill;
              if (cell.value == '○') {
                cell.font = excelStyle.studentDetailCorrectFont;
              } else if (cell.value == '×') {
                cell.font = excelStyle.studentDetailIncorrectFont;
              }
            }
          }
        }
      });
    });
  },

  /**
   * Read 系
   */

  /**
   * 宿題一覧を取得する
   * @param {String} accountId ID
   * @param {Number} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @returns 宿題一覧
   */
  async getHomeworkList(accountId, schoolId, apiToken) {
    // API で宿題一覧を取得
    const promise = await homeworkApi.getHomeworkList(
      accountId,
      schoolId,
      apiToken
    )

    // API ステータスが200番以外でも then に処理がくるのでステータスを確認
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    let homeworkItems = promise.data.data.list
    return homeworkItems.map((x) => {
      let status = x.haishin_status
      if (
        status === streamStatus.streaming ||
        status === streamStatus.endOfStream
      ) {
        let today = new Date()
        let endDateStr = convertValue.toDateStringSeparator(x.haishin_end_date)
        let endDate = new Date(endDateStr)
        endDate.setDate(endDate.getDate() + 1)
        if (today.getTime() > endDate.getTime()) {
          status = streamStatus.endOfStream
        } else {
          status = streamStatus.streaming
        }
      }
      return {
        // 宿題キー
        homeworkKey: x.homework_key,
        // 学校 ID
        schoolId: x.school_id,
        // 配信ステータス
        status: status,
        // 配信ステータス名（宿題一覧コンポーネントでセットする）
        statusDisplayName: "",
        // 配信ステータス色
        statusColor: this.setHomeworkExpired(
          convertValue.toDateStringSeparator(x.haishin_end_date),
          status
        )
          ? "#000"
          : this.getStreamStatusColor(status),
        // グループ ID
        groupId: Number(x.haishin_grp),
        // カテゴリー
        category: x.category,
        // 宿題名
        homeworkName: x.homework_nm,
        // 教科 ID
        curriculum: x.curriculum,
        // 科目 ID
        subject: x.subject,
        // 開始日（配信開始日）
        startDate: x.haishin_start_date,
        // 締切日（配信終了日）
        deadlineDate: x.haishin_end_date,
        // 提出済数
        handedStudentCount: x.submitted_fin,
        // 提出済数 / 提出総数
        HandInCount: `${x.submitted_fin}/${x.submitted_all}`,
        // 公開終了日
        releaseEndDate: x.public_end_date,
        // 提出期限チェック
        expired: this.setHomeworkExpired(
          convertValue.toDateStringSeparator(x.haishin_end_date),
          status
        ),
        // グループ種別
        groupType : x.haishin_grp_type == "1" ? "share" : "individual",
        // 宿題編集許可設定
        editor:x.editable == "1" ? "onlyme" : "anyone",
        // 宿題編集権限
        editableHomework : x.editablehomework
      }
    })
  },

  /**
   * 宿題詳細情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   * @param {String} sessionToken セッショントークン
   * @param {Array} curriculums 教科略称一覧（検索用）
   * @param {Array} handleCurriculums 所持教科一覧
   * @param {Object} paramApiSettingItems API設定
   * @returns 宿題詳細（ヘッダー情報、詳細情報、配信先グループ情報、教材一覧）
   */
  async getHomeworkDetail(
    accountId,
    schoolId,
    homeworkKey,
    apiToken,
    sessionToken,
    curriculums,
    handleCurriculums,
    paramApiSettingItems,
  ) {
    const promise = await homeworkApi.getHomeworkDetail(
      accountId,
      schoolId,
      homeworkKey,
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    const responseData = promise.data.data

    // ヘッダー情報
    let headerInfo = {
      // 教科
      curriculum: responseData.curriculum,
      // 科目
      subject: responseData.subject,
      // 宿題名
      homeworkName: responseData.homework_nm,
      // カテゴリー
      category: responseData.category,
      // 配信開始日
      startDate: responseData.haishin_start_date,
      // 配信終了日
      deadlineDate: responseData.haishin_end_date,
      // 配信ステータス
      streamStatus: responseData.haishin_status,
      // 先生コメント
      teacherComment: responseData.teacher_comment,
      // 宿題作成担当者名
      createTeachaer: responseData.create_teachaer,
      // 自分にも配信するフラグ（常に0が入る）
      isStreamMySelf: responseData.haishin_self_flg,
      // 配信先グループ ID
      streamGroup: responseData.haishin_grp,
      // 更新日時
      // updateDate: responseData.update_date,
    }

    // 詳細情報
    let homeworkDetail = responseData.homework_detail_list.map((x) => {
      return {
        // 宿題枝番
        homeworkEdaNo: x.homework_eda_no,
        // 宿題種別
        questionType: x.homework_syubetu_kbn,
        // タイトル
        taskName: x.title,
        // メイン情報
        mainQuestionInfo: {
          chapterId: x.main_info[0].chapter_id,
          nodeId: x.main_info[0].node_id,
          quesParentId: x.main_info[0].ques_parent_id,
          fileName: x.main_info[0].file_name,
        },
        // サブ情報
        subQuestionInfo: {
          teachingMaterials: x.sub_info[0].teaching_materials,
          filePath: x.sub_info[0].file_path,
        },
        // 送信メモ
        sendMemo: x.send_memo,
        // 問題数
        questionCount: x.ques_num,
        // 表示順
        sortedDisplayNo: x.disp_no,
        // 表示順枝番
        sortedDisplaySuffixNumber: x.disp_eda_no,
        // ファイル連番
        fileNmSeq: x.file_nm_seq,
      }
    })

    // 教科未設定の状態で保存されている可能性がある
    let groupMemberItems = []
    let bookItems = []
    let groupMemberFilterItems = []
    if (handleCurriculums && headerInfo.streamGroup) {
      const streamGroupInfoPromise = await this.getStreamGroupMemberInfo(
        headerInfo.streamGroup,
        accountId,
        handleCurriculums,
        sessionToken,
        curriculums,
        paramApiSettingItems
      )

      if (streamGroupInfoPromise) {
        // 配信先グループメンバ情報を取得
        bookItems = streamGroupInfoPromise.bookItems
        // 配信先グループ情報
        groupMemberItems = responseData.haishin_list.map((x) => {
          const found = streamGroupInfoPromise.accountItems.find(
            (f) => f.accountId === x.haishin_account_id
          )
          const accountName = found ? found.accountName : ""
          const bookItems = found ? found.bookItems : []

          return {
            accountId: x.haishin_account_id,
            accountName: accountName,
            bookItems: bookItems,
          }
        })
        // 名前が取得できた生徒に絞り込む
        groupMemberFilterItems = groupMemberItems.filter((x) => x.accountName)
      }
    }

    return {
      headerInfo: headerInfo,
      homeworkDetail: homeworkDetail,
      homeworkQuesSetList: responseData.homework_ques_set_list,
      groupMemberItems: groupMemberFilterItems,
      bookItems: bookItems,
      systemDateTime: responseData.system_date,
    }
  },

  /**
   * プレビュー用の配信画像情報を取得
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} keyNo キー番号
   * @param {String} apiToken LMS API トークン
   * @returns 配信画像情報（ファイル名、ファイルパス）
   */
  async getHomeworkStreamFileListForPreview(
    accountId,
    schoolId,
    mode,
    key,
    keyNo,
    apiToken
  ) {
    const promise = await homeworkApi.getHomeworkStreamFileInfo(
      accountId,
      schoolId,
      mode,
      key,
      keyNo,
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    const data = promise.data.data
    if (data.upload_file_list.length == 0) {
      return Promise.reject([])
    }

    return promise.data.data.upload_file_list.map((item) => {
      return {
        fileName: item.file_name,
        filePath: item.file_path,
      }
    })
  },
  async getHomeworkStreamFileListForStdbPreview(
    accountId,
    schoolId,
    mode,
    key,
    keyNo,
    fileNmSeq,
    apiToken
  ) {
    const promise = await homeworkApi.getHomeworkStreamFileInfo(
      accountId,
      schoolId,
      mode,
      key,
      keyNo,
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }
    const data = promise.data.data
    if (data.upload_file_list.length == 0) {
      return Promise.reject([])
    }
    fileNmSeq = fileNmSeq || data.preview_info_list[0].file_nm_seq
    const fileNameTarget = ("000" + fileNmSeq).slice(-3)
    const result = data.upload_file_list.filter(
      (item) => item.file_name.indexOf(fileNameTarget) != -1
    )
    return result.map((item) => {
      return {
        fileName: item.file_name,
        filePath: item.file_path,
      }
    })
  },

  /**
   * 宿題進捗情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} deadlineDate 期限日
   * @param {String} apiToken LMS API トークン
   * @param {Number} bulkCount MSHW0403_ノート画像情報取得APIのバルク呼び出し件数
   * @returns 宿題進捗情報（ヘッダー情報、生徒別情報、子問題別レート情報）
   */
  async getHomeworkProgressList(
    accountId,
    schoolId,
    homeworkKey,
    deadlineDate,
    apiToken,
    apiSettings
  ) {

    const apiSettingBulkCount = apiSettings.find(v => v.code == "0300");
    const apiSettingConcurrency = apiSettings.find(v => v.code == "0301");
    const apiSettingTimeout = apiSettings.find(v => v.code == "0302");
    var bulkCount = apiSettingBulkCount ? apiSettingBulkCount.value : 3;
    var concurrency = apiSettingConcurrency ? apiSettingConcurrency.value : 5;
    var timeout = apiSettingTimeout ? apiSettingTimeout.value : 120000;

    let result = await homeworkApi.getHomeworkProgress(
      accountId,
      schoolId,
      homeworkKey,
      apiToken
    )
    if (result.status !== apiResponseCode.ok) {
      return Promise.reject(result)
    }

    let executionArn = result.data.executionArn;
    const promiseSF = await this.checkStepFunctionsStatus(homeworkApi.getStatusForHomeworkProgress, executionArn)
    if (promiseSF.status !== apiResponseCode.ok) {
      return Promise.reject(promiseSF)
    }

    // JSONレスポンスファイル取得
    let promise = undefined
    try {
      const responseUrl = promiseSF.data.response_url
      const promiseJson = await homeworkApi.getStepFunctionsResponseJson(responseUrl)
      if (promiseJson.status !== apiResponseCode.ok) {
        return Promise.reject(promiseJson)
      }
      promise = {
        status: promiseJson.status,
        data: promiseJson.data,
      }
    } catch (e) {
      promise = {
        status: 500
      }
    }

    // JSON取得に失敗した場合
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(result)
    }
    // Lambda実行中のエラー
    if (promise.data.code !== apiResponseCode.ok) {
      return Promise.reject({
        status: promise.data.code
      })
    }

    // ヘッダーの子問題見出しと子問題集計用配列を生成
    let headerItems = []
    let childQuestionCountItems = []
    let homeworkDetailIds = []
    promise.data.data.homework_detail_header_list.forEach((x, index) => {
      let childIndex = 0
      for (let i = 0; i < x.ques_num; i++) {
        childIndex++
        // ヘッダーを生成
        headerItems.push(`${index + 1}\n${childIndex}問目`)

        // 子問題集計用配列を生成
        childQuestionCountItems.push({
          // 親問題番号
          sortedDisplayNo: index + 1,
          // 子問題番号
          childQuestionId: childIndex,
          // 回答数
          responseCount: 0,
          // 正答数
          correctCount: 0,
        })
      }

      // 宿題の教材、章、親問題Idを返す
      homeworkDetailIds.push({
        bookId: x.sub_info[0].teaching_materials,
        chapterId: x.main_info[0].chapter_id,
        quesParentId: x.main_info[0].ques_parent_id,
      })
    })

    // 子問題総数（オブジェクト型で合計する場合は、initialValue の指定が必要）
    let initialValue = 0
    const totalCount = promise.data.data.homework_detail_header_list.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.ques_num
      },
      initialValue
    )

    // 匿名生徒名配列の生成
    const anonymousNames = await this.generateAnonymousStudentNames(
      promise.data.data.student_homework_list.length
    )

    // システム日時
    const systemDate = promise.data.data.system_date;

    // 生徒アカウントIDリストが無駄に重複していたので重複しないように修正(リクエストのペイロードサイズを減らす)APIの結果は変わらない
    const accountList = promise.data.data.student_homework_detail_list.map(item => item.account_id).filter((id, idx, ary)=>ary.indexOf(id)===idx);

    // アカウントリストをbulkCountずつに分割し、分割単位でリクエスト
    const bulkAccountList = this.sliceArray(accountList, bulkCount)
    let searchStudentNoteInfoPromise = []

    searchStudentNoteInfoPromise = await Promise.map(bulkAccountList, (accounts) => {
      return this.searchStudentNoteInfo(
        accountId,
        schoolId,
        homeworkKey,
        accounts,
        apiToken,
        timeout
      )
    }, {concurrency: concurrency})

    searchStudentNoteInfoPromise.forEach(p => {
      if (!p || p.status !== apiResponseCode.ok) {
        throw new Object({status: 500});
      }
    });

    const studentNoteInfoResult = searchStudentNoteInfoPromise.flatMap(p => p.data.data.student_list)


    let progressItems = await Promise.all(
      promise.data.data.student_homework_list.map(async (x, index) => {
        // 生徒の宿題情報（結果）
        const foundResults = promise.data.data.student_homework_detail_list
          .filter((f) => f.account_id === x.account_id)
          .map((m) => {
            const teachingMaterialInfo =
              this.getTeachingMaterialInfoFromHomeworkInfo(promise, m)
            return {
              // 生徒のアカウント ID
              accountId: m.account_id,
              // 宿題枝番
              homeworkEdaNo: m.homework_eda_no,
              // 子問題 ID
              childQuestionId: m.ques_child_id,
              // 結果区分
              result: m.result_kbn,
              // 実施日
              dayOfLearning: m.lean_day,
              // 実施時刻
              timeOfLearning: m.lean_time,
              // タイトル（親問題タイトル）
              title: m.title,
              // 問題数
              questionCount: m.ques_num,
              // 表示順
              sortedDisplayNo: m.disp_no,
              // 表示順枝番
              sortedDisplaySuffixNumber: m.disp_eda_no,
              // 課題区分
              homeworkKind: teachingMaterialInfo.homeworkKind,
              // 書籍Id
              bookId: teachingMaterialInfo.bookId,
            }
          })

        // 回答数
        const responses = foundResults.filter(
          (f) =>
            f.result === correctResult.correct ||
            f.result === correctResult.incorrect
        )

        // 正答数
        const corrects = foundResults.filter(
          (f) => f.result === correctResult.correct
        )

        // 子問題集計配列に結果を蓄積
        // （子問題集計配列と生徒別の結果配列の個数と順番が同じ前提）
        foundResults.forEach((x, index) => {
          const found = childQuestionCountItems.find(
            (f) =>
              f.sortedDisplayNo === x.sortedDisplayNo &&
              f.childQuestionId === x.childQuestionId
          )

          if (found) {
            // 回答数
            const response =
              x.result === correctResult.correct ||
              x.result === correctResult.incorrect
                ? 1
                : 0
            // 正答数
            const correct = x.result === correctResult.correct ? 1 : 0

            childQuestionCountItems.splice(index, 1, {
              sortedDisplayNo: found.sortedDisplayNo,
              childQuestionId: found.childQuestionId,
              responseCount: found.responseCount + response,
              correctCount: found.correctCount + correct,
            })
          }
        })

        // const result = await this.searchStudentNoteInfo(
        //   accountId,
        //   schoolId,
        //   homeworkKey,
        //   foundResults[0].accountId,
        //   apiToken
        // )
        const result = studentNoteInfoResult.find((v) => v.account_id === foundResults[0].accountId)

        // const noteItems = result.data.data.student_homework_note_list.map(
        const noteItems = result.student_homework_note_list.map(
          (shnlm) => {
            const noteType = [0, 1].includes(shnlm.note_type) ? shnlm.note_type : 0;
            return {
              accountId: shnlm.account_id,
              homeworkNoteNo: shnlm.homework_note_no,
              noteDrawFileUmuFlg: shnlm.note_draw_file_umu_flg,
              noteFilePath: shnlm.note_file_path,
              submitDate: shnlm.submit_date,
              lastSubmitAddDate: shnlm.last_submit_add_date,
              noteType: noteType,
              homeworkEdaNo: shnlm.homework_eda_no,
              pageNo: shnlm.page_no,
              writeInfoStudent: shnlm.write_info_student,
              writeInfoTeacher: shnlm.write_info_teacher,
            }
          }
        )

        // x.statusがnullの場合は未着手扱いとする
        const status = x.status ? x.status : handedStatus.notProgress;
        // 回答率（回答数 / 子問題数 * 100）小数点以下は四捨五入
        const responseRate = Math.round((responses.length / totalCount) * 100);
        // 正答率（／全体）（正答数 / 子問題数 * 100）小数点以下は四捨五入
        const correctAnswerRate = Math.round((corrects.length / totalCount) * 100);
        // 正答率（／回答）（正答数 / 回答数 * 100）小数点以下は四捨五入
        const responseCorrectRate = responses.length == 0 ? 0 : Math.round((corrects.length / responses.length) * 100);
        let newItem = {
          // 生徒のアカウント ID
          accountId: x.account_id,
          // 生徒の名前
          accountName: x.account_id,
          // 匿名用の生徒名
          anonymousAccountName: anonymousNames[index],
          // 宿題ステータス
          status: status,
          // 宿題ステータス表示名
          // ステータス表示名はコンポーネントでセット
          statusDisplayName: "",
          // 期限をチェックして、期限超過の場合は「期限超過」の文字を生成する
          expired: this.setHomeworkExpired(
            deadlineDate,
            null,
            !x.submit_date
              ? null
              : convertValue.toDateStringSeparator(x.submit_date)
          ),
          // 宿題ステータス背景色
          statusColor: this.getProgressStatusColor(
            status,
            this.setHomeworkExpired(
              deadlineDate,
              null,
              !x.submit_date
                ? null
                : convertValue.toDateStringSeparator(x.submit_date)
            )
          ),
          // 採点スタンプ
          stamp: x.score_stamp,
          // 評価
          eval: x.eval,
          // 評価手動設定フラグ
          evalManualSetFlg: x.eval_manual_set_flg,
          // 回答率
          responseRate: responseRate,
          // 正答率（／全体）
          correctAnswerRate: correctAnswerRate,
          // 正答率（／回答）
          responseCorrectRate: responseCorrectRate,
          // 提出日
          handedDate: x.submit_date,
          // 提出時刻
          handedTime: x.submit_time,
          // 先生用コメント
          teacherComment: x.teacher_comment,
          // 生徒用コメント
          studentComment: x.student_comment,
          // 最終提出・追加提出日時
          lastSubmitAddDate: x.last_submit_add_date,
          // コメントリスト
          comments: x.comment_list ? x.comment_list : [],
          // 返却日時
          returnDate: x.return_date,
          // 生徒別宿題情報一覧（結果）
          results: foundResults,
          // 画像一覧
          noteItems: noteItems,
        }

        return newItem
      })
    )

    // 子問題の集計
    let childQuestionRateItems = childQuestionCountItems.map((x) => {
      return {
        sortedDisplayNo: x.sortedDisplayNo,
        childQuestionId: x.childQuestionId,
        // 後で計算するので一旦0を突っ込んでおく
        responseRate: 0,
        correctRate: 0,
        responseCorrectRate: 0,
      }
    })

    return Promise.resolve({
      headerItems: headerItems,
      progressItems: progressItems,
      childQuestionRateItems: childQuestionRateItems,
      homeworkDetailIds: homeworkDetailIds,
      teacherComment: promise.data.data.teacher_comment,
      systemDate: systemDate,
      homeworkEvalSet: promise.data.data.homework_eval_set,
    })
  },
  /**
   * 配列の分割
   */
  sliceArray(array, number) {
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  },
  /**
   * 生徒のノート画像を取得する
   * @param {String} accountId アカウントID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} studentAccountId 生徒アカウントID
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報の生徒のノート画像を取得する
   */
  async searchStudentNoteInfo(
    accountId,
    schoolId,
    homeworkKey,
    studentAccountId,
    apiToken,
    timeout = undefined
  ) {
    try {
      const promise = await homeworkApi.searchStudentNoteInfo(
        accountId,
        schoolId,
        homeworkKey,
        studentAccountId,
        apiToken,
        timeout
      )
      return Promise.resolve(promise)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * リストから書籍IDを取得する処理
   * @param {Promise} promise API層の返却値
   * @param {Object} questionInfo 問題情報
   * @returns 書籍ID
   */
  getTeachingMaterialInfoFromHomeworkInfo(promise, questionInfo) {
    // リストから書籍IDを取り出す
    const header = promise.data.data.homework_detail_header_list.find(
      (f) => f.homework_eda_no === questionInfo.homework_eda_no
    )
    if (!header) {
      return {
        homeworkKind: homeworkTypeCode.textbook,
        bookId: "",
      }
    }

    let teachingMaterial = header.sub_info[0].teaching_materials
    const bookId = teachingMaterial
    return {
      homeworkKind: header.homework_syubetu_kbn,
      bookId: bookId,
    }
  },

  /**
   * 配信ステータス名から背景色を返す
   * @param {Number} status 配信ステータス
   * @returns 背景色
   */
  getStreamStatusColor(status) {
    switch (status) {
      // 作成中
      case streamStatus.creating:
        return "#70ad47"
      // 配信予定
      case streamStatus.waitStream:
        return "#b686da"
      // 配信中
      case streamStatus.streaming:
        return "#0070c0"
      // 配信終了
      case streamStatus.endOfStream:
        return "#000"
    }
  },

  /**
   * 進捗ステータス名から背景色を返す
   * @param {Number} status 進捗ステータス
   * @returns 背景色
   */
  getProgressStatusColor(status, expired = false) {
    switch (status) {
      // 「未着手」、「着手中」、「提出待ち」は「未提出」
      case handedStatus.notProgress:
      case handedStatus.progressing:
      case handedStatus.waitHand:
        if (expired) {
          return "#f00"
        } else {
          return "#ff7f27"
        }
      // 「提出済」、「提出済（確認済）」は「提出済」
      case handedStatus.handed:
      case handedStatus.checked:
        return "#0070c0"
      // 「返却済」、「返却済（確認済）」は「返却済」
      case handedStatus.returned:
      case handedStatus.returnChecked:
        return "#70ad47"
      // 「差し戻し」、「差し戻し（確認済）」は「差し戻し」
      case handedStatus.sendBack:
      case handedStatus.sendBackCheck:
        return "#a6a6a6"
    }
  },

  /**
   * 期限・締切超過チェック
   * @param {Date} deadlineDate
   * @param {Number} status
   * @param {Number} handedNum
   * @returns Boolean
   */
  setHomeworkExpired(deadlineDate, status = null, handedNum = null) {
    if (status === null || status === streamStatus.endOfStream) {
      let checkDate
      if (!handedNum) {
        checkDate = new Date()
      } else {
        checkDate = convertValue.stringToDate(handedNum)
      }
      deadlineDate = convertValue.stringToDate(deadlineDate)
      deadlineDate.setDate(deadlineDate.getDate() + 1)
      return deadlineDate <= checkDate
    } else {
      return false
    }
  },

  /**
   * 採点スタンプ画像を取得する
   * @returns スタンプ画像
   */
  async getGradeStamps(gradeStampItems) {
    let stamps = []
    for (let i = 0; i < gradeStampItems.length; i++) {
      const code = i + 1
      const fileName = `le_stamp${("00" + code).slice(-2)}.svg`
      // S3 よりスタンプ画像をダウンロードして取得する
      await homeworkApi.getGradeStamps(fileName).then((response) => {
        const stampSvg = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
          response.data
        )}`
        stamps.push({ code: code, stamp: stampSvg })
      })
    }

    return stamps
  },

  /**
   * 教材設定を取得する
   * @returns 教材設定
   */
  async getBookConfig(fileName) {
    let config = {}
    await homeworkApi.getBookConfig(fileName).then((response) => {
      config = response.data
    })
    return config
  },

  /**
   * 宿題の種別ごとの登録済一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} homeworkType 宿題種別
   * @param {String} apiToken LMS API トークン
   * @returns 宿題の問題設定情報一覧
   */
  async getQuestionOfHomework(
    accountId,
    schoolId,
    homeworkKey,
    homeworkType,
    apiToken
  ) {
    const promise = await homeworkApi.getQuestionOfHomework(
      accountId,
      schoolId,
      homeworkKey,
      homeworkType,
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise
  },

  /**
   * 生徒名称の一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   * @param {String} sessionToken セッショントークン
   * @param {Array} curriculums 教科略称一覧（検索用）
   * @param {Object} paramApiSettingItems API設定
   * @returns 生徒名称一覧
   */
  async getStudentsInfo(
    accountId,
    schoolId,
    homeworkKey,
    apiToken,
    sessionToken,
    curriculums,
    paramApiSettingItems,
  ) {
    const promise = await homeworkApi.getHomeworkDetail(
      accountId,
      schoolId,
      homeworkKey,
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    const responseData = promise.data.data

    // 配信先グループ ID
    const streamGroup = Number(responseData.haishin_grp)

    // 配信先グループメンバ情報を取得
    const streamGroupInfoPromise = await this.getStreamGroupMemberInfo(
      streamGroup,
      accountId,
      [responseData.curriculum], // 配列が要求されている
      sessionToken,
      curriculums,
      paramApiSettingItems,
    )

    // 配信先グループ情報
    let groupMemberItems = responseData.haishin_list.map((x) => {
      const found = streamGroupInfoPromise.accountItems.find(
        (f) => f.accountId === x.haishin_account_id
      )
      const accountName = found ? found.accountName : ""
      const bookItems = found ? found.bookItems : []

      return {
        accountId: x.haishin_account_id,
        accountName: accountName,
        bookItems: bookItems,
      }
    })
    // 宿題詳細情報
    let homeworkDetails = {
      // APIのレスポンスのcurriculumを設定値からshortNameに変換する
      curriculum: curriculums.shortName.find(
        (x) => x.code == promise.data.data.curriculum
      ).name,
      groupId: promise.data.data.haishin_grp,
      createTeacher: promise.data.data.create_teacher,
      homeworkInfo: promise.data.data.homework_detail_list.map((x) => {
        return {
          homeworkKey: homeworkKey,
          homeworkEdaNo: x.homework_eda_no,
          kind: x.homework_syubetu_kbn,
          taskName: x.title,
          chapterId: x.main_info[0].chapter_id,
          quesParentId: x.main_info[0].ques_parent_id,
          fileNmSeq: x.file_nm_seq,
          bookId: x.sub_info[0].teaching_materials,
        }
      }),
    }
    return {
      // 名前が取得できた生徒に絞り込んで返却
      groupMemberItems: groupMemberItems.filter((x) => x.accountName),
      homeworkDetails: homeworkDetails,
    }
  },

  /**
   * 配信先グループメンバ情報を取得する
   * @param {Number} streamGroup
   * @param {String} accountId ID
   * @param {Array} curriculumIds 教科 ID
   * @param {String} sessionToken セッショントークン
   * @param {Array} curriculums 教科略称一覧（検索用）
   * @param {Object} paramApiSettingItems API設定
   * @returns 配信先グループメンバ情報（メンバ一覧、教材一覧）
   */
  async getStreamGroupMemberInfo(
    streamGroup,
    accountId,
    curriculumIds,
    sessionToken,
    curriculums,
    paramApiSettingItems,
  ) {
    // 教科は通常1つのみだが、API が配列のため変数名も複数形にしている
    const curriculumItems = []
    // 教科 ID から教科略称名を取得
    curriculumIds.forEach((element) => {
      curriculumItems.push(curriculums.shortName[element - 1].name)
    })
    try {
      const bookInfosPromise = await accountRepository.getSukenAccountWithBooks(
        streamGroup,
        accountId,
        curriculumItems,
        sessionToken,
        paramApiSettingItems.find(v => v.itemName === 'accountsProductsBooks').items
      )
      return bookInfosPromise
    } catch (error) {
      console.log(error)
      return null
    }
  },

  /**
   * 宿題の数が上限数に達しているか？
   * @param {Array} homeworks 宿題リスト
   * @param {Array} limitItems Storeに保存されている上限情報配列
   */
  isLimitHomeworkCount(homeworks, limitItems) {
    const currentCount = homeworks.length
    const limitCount = limitItems[0].items[0].value
    return currentCount >= limitCount
  },

  /**
   * システム日付を取得する
   * HACK:そもそも「システム日付だけを返す」と言うAPIが存在しない為、適当なAPIを叩いて対処
   * @param {String} accountId ID
   * @param {Number} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @returns YYYY/mm/dd形式の日付
   */
  async getSystemDate(accountId, schoolId, apiToken) {
    // システム日時を取得する
    const promise = await sessionRepository.getSystemDateTime(
      accountId,
      apiToken
    )

    // API ステータスが200番以外でも then に処理がくるのでステータスを確認
    if (promise.status !== apiResponseCode.ok) {
      promise.data.data.list = []
      return Promise.reject(promise)
    }

    return promise.data.data.system_date
  },

  /**
   * 開始日が有効かどうかを返す
   * @param {String} systemDateStr システム日付文字列
   * @param {String} startDateStr 開始日文字列
   * @returns 有効ならtrue
   */
  isValidStartDate(systemDateStr, startDateStr) {
    let systemDate = new Date(systemDateStr)
    systemDate = convertValue.datetimeToDate(systemDate)
    const startDate = new Date(startDateStr)

    // １か月後までを有効にする
    let limitDate = new Date(systemDate.getTime())
    limitDate = convertValue.setMonthSafely(limitDate, limitDate.getMonth() + 1)

    return systemDate <= startDate && startDate <= limitDate
  },

  /**
   * 締切日が有効かどうかを返す
   * @param {String} startDateStr 開始日文字列
   * @param {String} deadlineDateStr 締切日文字列
   * @param {Number} endPeriodMonth 配信終了日までの月数
   * @param {Number} deadlinePeriodMonth 締切日までの月数
   * @returns 有効ならtrue
   */
  isValidDeadlineDate(
    startDateStr,
    deadlineDateStr,
    endPeriodMonth,
    deadlinePeriodMonth
  ) {
    const startDate = new Date(startDateStr)
    const deadlineDate = new Date(deadlineDateStr)

    // 締切日が開始日より過去でないこと
    if (startDate > deadlineDate) {
      return false
    }

    let judgeDate = new Date(startDate.getTime())
    judgeDate = convertValue.setMonthSafely(
      judgeDate,
      judgeDate.getMonth() + endPeriodMonth - deadlinePeriodMonth
    )

    // 締切日が「リクエスト．配信開始日」+「公開終了日／パラメータ：0110」-「締切日／パラメータ：0210」を超える場合false
    return deadlineDate <= judgeDate
  },

  /**
   * 有効な開始日の範囲を返す
   * @returns 有効な日付範囲
   */
  getStartDateRange() {
    let systemDate = new Date()
    systemDate.setDate(systemDate.getDate())

    // １か月後までを有効にする
    let limitDate = new Date(systemDate.getTime())
    limitDate = convertValue.setMonthSafely(limitDate, limitDate.getMonth() + 1)

    return {
      min: convertValue.formatDate(systemDate),
      max: convertValue.formatDate(limitDate),
    }
  },

  /**
   * 有効な締切日の範囲を返す
   * @param {String} startDateStr 公開日文字列
   * @param {Number} endPeriodMonth 配信終了日までの月数
   * @param {Number} deadlinePeriodMonth 締切日までの月数
   * @returns 有効な日付範囲
   */
  getDeadlineDateRange(startDateStr, endPeriodMonth, deadlinePeriodMonth) {
    let startDate
    if (!startDateStr) {
      startDate = new Date()
    } else {
      startDate = new Date(startDateStr)
    }
    let judgeDate = new Date(startDate.getTime())

    // 締切日の最短は開始日の翌日とする
    startDate.setDate(startDate.getDate())
    judgeDate = convertValue.setMonthSafely(
      judgeDate,
      judgeDate.getMonth() + endPeriodMonth - deadlinePeriodMonth
    )

    return {
      min: convertValue.formatDate(startDate),
      max: convertValue.formatDate(judgeDate),
    }
  },

  /**
   * 宿題共通設定を取得する
   * @param {String} apiToken LMS API トークン
   * @param {Number} schoolId 学校 ID
   * @param {String} accountId ID
   * @returns 宿題共通設定
   */
   async getHomeworkCmnInfo(apiToken, schoolId, accountId) {
    // API で宿題共通設定を取得
    const promise = await homeworkApi.getHomeworkCmnInfo(
      apiToken,
      schoolId,
      accountId,
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    const responseData = promise.data.data

    if (responseData === null) {
      return null
    }

    return {
      evalSet: responseData.eval,
      categorySet: responseData.category,
    }
  },

  /**
   * 宿題共通設定を取得する
   * @param {String} apiToken LMS API トークン
   * @param {Number} schoolId 学校 ID
   * @param {String} accountId ID
   * @returns 宿題共通設定
   */
   async getHomeworkSchoolInfo(apiToken, schoolId, accountid, setKey) {
    // API で宿題共通設定を取得
    const promise = await homeworkApi.getHomeworkSchoolInfo(
      apiToken,
      schoolId,
      accountid,
      setKey,
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    const responseData = promise.data.data

    if (responseData === null) {
      return null
    }

    return {
      setList: responseData["set_list"]
    }
  },

  /**
   * Update 系
   */

  /**
   * 宿題リストが空か？
   * @param {Array}} homeworks 宿題リスト
   * @returns 宿題が空ならtrue
   */
  isHomeworkEmpty(homeworks) {
    const homeworkCount = homeworks.length
    return homeworkCount === 0
  },

  /**
   * 宿題が上限数に達しているか？
   * @param {Array} homeworks 宿題リスト
   * @param {Array} homeworkLimits Storeに保存されている宿題上限のリスト
   * @returns 上限を超えていたらTrueを返す
   */
  isHomeworkLimit(homeworks, homeworkLimits) {
    const indexKeyValue = []
    indexKeyValue.push({ keys: [homeworkTypeCode.textbook], value: 0 })
    indexKeyValue.push({
      keys: [homeworkTypeCode.stdb, homeworkTypeCode.stdbLayout],
      value: 1,
    })
    indexKeyValue.push({ keys: [homeworkTypeCode.pdf], value: 2 })

    let isLimitOver = false
    indexKeyValue.forEach((keyValue) => {
      if (isLimitOver) {
        return
      }

      if (
        homeworks.filter((item) =>
          keyValue.keys.some((key) => item.questionType === key)
        ).length > homeworkLimits[keyValue.value].items[0].value
      ) {
        isLimitOver = true
      }
    })

    return isLimitOver
  },

  /**
   * 宿題を保存
   * @param {Object} params パラメータオブジェクト
   * @param {String} apiToken APIトークン
   * @returns 処理結果
   */
  async saveHomework(accountId, params, apiToken) {
    const promise = await this.saveOrPublishHomework(
      accountId,
      params,
      0, // 保存なのでmodeは0
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }
    return promise
  },

  /**
   * 宿題を配信
   * @param {Object} params パラメータオブジェクト
   * @param {String} apiToken APIトークン
   * @returns 処理結果
   */
  async publishHomework(accountId, params, apiToken) {
    const promise = await this.saveOrPublishHomework(
      accountId,
      params,
      1, // 配信なのでmodeは1
      apiToken
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }
    return promise
  },

  /**
   * 宿題保存・配信 共通処理
   * @param {Object} params パラメータオブジェクト
   * @param {Number} mode モード（0: 保存、1: 配信）
   * @param {String} apiToken APIトークン
   * @returns 処理結果
   */
  async saveOrPublishHomework(accountId, params, mode, apiToken) {
    // 各種データをAPIの要求する形に変換
    // 教科
    params.curriculum = Number(params.curriculum)
    // 科目
    params.subject = Number(params.subject)
    // 開始日・締切日・初期表示日時
    // YYYY-mm-dd → YYYYmmddに修正
    params.startDate = params.startDate.replaceAll("-", "")
    params.deadlineDate = params.deadlineDate.replaceAll("-", "")
    // 自身への配信フラグ
    params.sendToSelfFlag = params.sendToSelfFlag ? 1 : 0

    await new Promise((resolve) => {
      // 宿題詳細リスト
      params.detailList = this.convertDetailListToApi(params.detailList)
      params.questionSetList = this.convertSetListToApi(
        JSON.parse(JSON.stringify(params))
      )
      resolve()
    })

    // グループID
    params.groupId = Number(params.groupId)
    // アカウントリスト
    params.publishAccountList = convertValue.stringArrayToCommaDelimitedString(
      params.publishAccountList
    )

    // 配信中 or 配信終了なら空配列を渡さなければならない
    if (
      params.status === streamStatus.streaming ||
      params.status === streamStatus.endOfStream
    ) {
      params.detailList = []
      params.questionSetList = []
    }

    params.fileHistoryList = await this.getFileHisList(params.fileHistoryList)
    const blob = new Blob([JSON.stringify(params, null, 2)], {
      type: "application/json",
    })
    // リクエストが6MBを超える場合、保存処理を複数回に分割する
    if (blob.size > 6000000) {
      let requests = this.convertToApiRequest(params)

      for (let index = 0; index < requests.length; index++) {
        const request = requests[index]
        if (request.homeworkKey === 0) {
          // 新規の場合のみ宿題キーを更新する必要があるため別途処理を行う
          const promise = await homeworkApi.saveOrPublishHomework(
            accountId,
            request,
            mode,
            apiToken
          )
          request.homeworkKey = promise.data.data.homework_key
          requests.forEach((item) => {
            item.homeworkKey = promise.data.data.homework_key
          })
        } else {
          if (index == requests.length - 1) {
            // 最後の場合の処理
            return await homeworkApi.saveOrPublishHomework(
              accountId,
              request,
              mode,
              apiToken
            )
          } else {
            // 最後以外の場合の処理
            await homeworkApi.saveOrPublishHomework(
              accountId,
              request,
              mode,
              apiToken
            )
          }
        }
      }
    } else {
      return await homeworkApi.saveOrPublishHomework(
        accountId,
        params,
        mode,
        apiToken
      )
    }
  },

  /**
   * リクエストのサイズが6MBを超える場合にリクエストを分割する
   * @param {Object} params
   */
  convertToApiRequest(params) {
    let size = 0
    let fileHistoryLists = []
    let requests = []
    // 枝番１の宿題詳細リストのみ抽出
    const detailListEdaNo1Request = []
    const detailListEdaNo1 = params.detailList.find(
      (item) => item.homework_eda_no == 1
    )
    detailListEdaNo1Request.push(detailListEdaNo1)

    // 枝番１の宿題設定リストのみ抽出
    const questionSetListEdaNo1Request = []
    const questionSetListEdaNo1 = params.questionSetList.find(
      (item) => item.homework_eda_no == 1
    )
    // 枝番１の宿題設定リストが存在する場合は値を割り当てる
    if (questionSetListEdaNo1) {
      questionSetListEdaNo1Request.push(questionSetListEdaNo1)
    }

    // ソートを実施する
    let sortedFileHisList = []
    const uploadFileListNo1 = params.fileHistoryList.find(
      (fhl) =>
        detailListEdaNo1.sub_info[0].file_path == fhl.sub_info[0].file_path
    )
    sortedFileHisList.push(uploadFileListNo1)
    params.fileHistoryList.forEach((fhl) => {
      const notExist = sortedFileHisList.every(
        (item) => item.sub_info[0].file_path !== fhl.sub_info[0].file_path
      )
      if (notExist) {
        sortedFileHisList.push(fhl)
      }
    })

    sortedFileHisList.map((fileHistory) => {
      // アップロードファイルリストの変数を用意
      let uploadFileList = []
      if (fileHistory.upload_file_list.length) {
        fileHistory.upload_file_list.forEach((item, index) => {
          uploadFileList.push(item)
          // アップロードファイルリストのサイズを一つ一つ見ていく
          const blob = new Blob([JSON.stringify(item, null, 2)], {
            type: "application/json",
          })
          size += blob.size
          // 超えている場合
          if (size > 2000000) {
            fileHistoryLists.push({
              expiration_date: fileHistory.expiration_date,
              file_status_flg: fileHistory.file_status_flg,
              haishin_file_no: fileHistory.haishin_file_no,
              homework_syubetu_kbn: fileHistory.homework_syubetu_kbn,
              main_info: fileHistory.main_info,
              send_memo: fileHistory.send_memo,
              sub_info: fileHistory.sub_info,
              title: fileHistory.title,
              upload_file_list: uploadFileList,
              over_flg: true,
            })
            uploadFileList = []
            size = 0
          } else if (index == fileHistory.upload_file_list.length - 1) {
            fileHistoryLists.push({
              expiration_date: fileHistory.expiration_date,
              file_status_flg: fileHistory.file_status_flg,
              haishin_file_no: fileHistory.haishin_file_no,
              homework_syubetu_kbn: fileHistory.homework_syubetu_kbn,
              main_info: fileHistory.main_info,
              send_memo: fileHistory.send_memo,
              sub_info: fileHistory.sub_info,
              title: fileHistory.title,
              upload_file_list: uploadFileList,
              over_flg: false,
            })
            uploadFileList = []
          }
        })
      } else {
        fileHistoryLists.push({
          expiration_date: fileHistory.expiration_date,
          file_status_flg: fileHistory.file_status_flg,
          haishin_file_no: fileHistory.haishin_file_no,
          homework_syubetu_kbn: fileHistory.homework_syubetu_kbn,
          main_info: fileHistory.main_info,
          send_memo: fileHistory.send_memo,
          sub_info: fileHistory.sub_info,
          title: fileHistory.title,
          upload_file_list: [],
          over_flg: true,
        })
      }
      for (
        let index = 0;
        index < fileHistory.upload_file_list.length;
        index++
      ) {
        const item = fileHistory.upload_file_list[index]
        uploadFileList.push(item)
      }
    })
    let fileHistoryListsRequest = []
    let fileHistoryListsParameter = []
    for (let index = 0; index < fileHistoryLists.length; index++) {
      const item = fileHistoryLists[index]
      fileHistoryListsParameter.push({
        expiration_date: item.expiration_date,
        file_status_flg: item.file_status_flg,
        haishin_file_no: item.haishin_file_no,
        homework_syubetu_kbn: item.homework_syubetu_kbn,
        main_info: item.main_info,
        send_memo: item.send_memo,
        sub_info: item.sub_info,
        title: item.title,
        upload_file_list: item.upload_file_list,
      })
      if (item.over_flg) {
        fileHistoryListsRequest.push(fileHistoryListsParameter)
        fileHistoryListsParameter = []
      } else if (index == fileHistoryLists.length - 1) {
        fileHistoryListsRequest.push(fileHistoryListsParameter)
        fileHistoryListsParameter = []
      }
    }
    let openDateTime = new Date(params.openDateTime)
    for (let index = 0; index < fileHistoryListsRequest.length; index++) {
      const fileHistoryList = fileHistoryListsRequest[index]
      openDateTime.setDate(openDateTime.getDate() + 1)
      if (index === fileHistoryListsRequest.length - 1) {
        // 最後の場合の処理を記載
        // 宿題詳細リストはすべて
        requests.push({
          schoolId: params.schoolId,
          homeworkKey: params.homeworkKey,
          status: params.status,
          curriculum: params.curriculum,
          subject: params.subject,
          homeworkName: params.homeworkName,
          startDate: params.startDate,
          deadlineDate: params.deadlineDate,
          comment: params.comment,
          sendToSelfFlag: params.sendToSelfFlag,
          openDateTime: convertValue.formatDatetime(openDateTime),
          detailList: params.detailList,
          questionSetList: params.questionSetList,
          fileHistoryList: fileHistoryList,
          groupId: params.groupId,
          publishAccountList: params.publishAccountList,
          category: params.category,
        })
      } else {
        // 最後以外の場合の処理を記載
        // 宿題詳細リストは枝番１のみ
        requests.push({
          schoolId: params.schoolId,
          homeworkKey: params.homeworkKey,
          status: params.status,
          curriculum: params.curriculum,
          subject: params.subject,
          homeworkName: params.homeworkName,
          startDate: params.startDate,
          deadlineDate: params.deadlineDate,
          comment: params.comment,
          sendToSelfFlag: params.sendToSelfFlag,
          openDateTime: convertValue.formatDatetime(openDateTime),
          detailList: detailListEdaNo1Request,
          questionSetList: questionSetListEdaNo1Request,
          fileHistoryList: fileHistoryList,
          groupId: params.groupId,
          publishAccountList: params.publishAccountList,
          category: params.category,
        })
      }
    }
    return requests
  },

  /**
   * 画像ファイルを追加
   * @param {Object} fileHisList
   * @returns
   */
  async getFileHisList(fileHisList) {
    let arr = JSON.parse(JSON.stringify(fileHisList))
    return new Promise((resolve) => {
      const promises = arr.map((item, index) => {
        return new Promise((resolveFetch) => {
          if (0 === item.file_status_flg) {
            const key = item.sub_info[0].file_path
            db.idbQuestionImage.get(key).then((result) => {
              result.uploadFileList.forEach((elem) => {
                arr[index].upload_file_list.push({
                  file_name: elem.file_name,
                  file_image: elem.file_image,
                })
              })
              resolveFetch()
            })
          } else {
            item.upload_file_list = new Array()
            resolveFetch()
          }
        })
      })

      Promise.all(promises).then(() => resolve(arr))
    })
  },

  /**
   * 宿題詳細リストをAPIの要求する形式に変換
   * @param {Array} list
   */
  convertDetailListToApi(list) {
    let sortedItems = []
    let items = list.map((item, index) => {
      return {
        homework_eda_no: index + 1,
        homework_syubetu_kbn: item.questionType,
        title:
          item.taskName.length > 99
            ? item.taskName.substring(0, 99).concat("…")
            : item.taskName,
        main_info: [
          {
            chapter_id: item.mainQuestionInfo.chapterId,
            node_id: item.mainQuestionInfo.nodeId,
            ques_parent_id: item.mainQuestionInfo.quesParentId,
            file_name: item.mainQuestionInfo.fileName,
          },
        ],
        sub_info: [
          {
            teaching_materials: item.subQuestionInfo.teachingMaterials,
            file_path: item.subQuestionInfo.filePath,
          },
        ],
        file_nm_seq: !item.fileNmSeq ? 0 : item.fileNmSeq,
        send_memo: item.sendMemo,
        ques_num: !item.questionCount ? 1 : item.questionCount,
        disp_no: index + 1,
        disp_eda_no: 0,
      }
    })

    let textbooksStream = items.filter(
      (item) => item.homework_syubetu_kbn == homeworkTypeCode.textbook
    )
    let pdfStream = items.filter(
      (item) => item.homework_syubetu_kbn == homeworkTypeCode.pdf
    )
    let stdbStream = items.filter(
      (item) =>
        item.homework_syubetu_kbn == homeworkTypeCode.stdbLayout ||
        item.homework_syubetu_kbn == homeworkTypeCode.stdb
    )

    sortedItems = sortedItems
      .concat(stdbStream)
      .concat(pdfStream)
      .concat(textbooksStream)

    sortedItems.forEach((item, index) => {
      item.homework_eda_no = index + 1
    })

    return sortedItems
  },
  /**
   * 宿題設定リストをAPIの要求する形式に変換
   * @param {Array} list
   */
  convertSetListToApi(params) {
    let dataList = JSON.parse(JSON.stringify(params))
    // 宿題設定リストと宿題問題リストを紐づける
    return dataList.questionSetList
      .map((setList) => {
        if (setList.question_type === homeworkTypeCode.stdb) {
          const record = dataList.detailList.find(
            (detail) =>
              detail.sub_info[0].file_path === setList.file_path &&
              detail.file_nm_seq === setList.fileNmSeq
          )
          setList.homework_eda_no = record.homework_eda_no
          setList.question_type = record.homework_syubetu_kbn

          return {
            homework_eda_no: setList.homework_eda_no,
            ans_disp_flg: Number(setList.ans_disp_flg),
            explain_disp_flg: Number(setList.explain_disp_flg),
          }
        } else {
          return null
        }
      })
      .filter((item) => item != null)
  },

  /**
   * 生徒別宿題データを更新する（返却する）
   * @param {*} studentAccountId
   * @param {*} studentAccountIdList
   * @param {*} schoolId
   * @param {*} homeworkKey
   * @param {*} mode
   * @param {*} accountId
   * @param {*} evalCode
   * @param {*} evalManualSetFlg
   * @param {*} gradeStamp
   * @param {*} teacherComment
   * @param {*} drawList
   * @param {*} writeInfoList
   * @param {*} apiToken
   * @param {*} systemDate
   */
  async updateHomeworkProgressByStudent(
    studentAccountId,
    studentAccountIdList,
    schoolId,
    homeworkKey,
    mode,
    accountId,
    evalCode,
    evalManualSetFlg,
    gradeStamp,
    teacherComment,
    drawList,
    writeInfoList,
    apiToken,
    systemDate,
  ) {
    const promise = await homeworkApi.updateHomeworkProgressByStudent(
      studentAccountId,
      studentAccountIdList,
      schoolId,
      homeworkKey,
      mode,
      accountId,
      evalCode,
      evalManualSetFlg,
      gradeStamp,
      teacherComment,
      drawList,
      writeInfoList,
      apiToken,
      systemDate,
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return Promise.resolve(promise)
  },

  /**
   * 宿題共通設定を更新する
   * @param {*} apiToken
   * @param {*} schoolId
   * @param {*} accountId
   * @param {*} evalSet
   * @param {*} categorySet
   */
   async updateHomeworkCmnSet(
    apiToken,
    schoolId,
    accountId,
    evalSet,
    categorySet,
  ) {
    const promise = await homeworkApi.updateHomeworkCmnSet(
      apiToken,
      schoolId,
      accountId,
      evalSet,
      categorySet,
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return Promise.resolve(promise)
  },

  /**
   * 宿題共通設定を更新する
   * @param {*} apiToken
   * @param {*} schoolId
   * @param {*} accountId
   * @param {*} evalSet
   * @param {*} categorySet
   */
   async updateHomeworkSchoolSet(
    apiToken,
    schoolId,
    accountid,
    setKey,
    setValue,
  ) {
    const promise = await homeworkApi.updateHomeworkSchoolSet(
      apiToken,
      schoolId,
      accountid,
      setKey,
      setValue,
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return Promise.resolve(promise)
  },

  /**
   * 宿題評価設定を更新する
   * @param {*} apiToken
   * @param {*} schoolId
   * @param {*} accountId
   * @param {*} homeworkKey
   * @param {*} evalSet
   */
   async updateHomeworkEvalSet(
    apiToken,
    schoolId,
    accountId,
    homeworkKey,
    evalSet,
  ) {
    const promise = await homeworkApi.updateHomeworkEvalSet(
      apiToken,
      schoolId,
      accountId,
      homeworkKey,
      evalSet,
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return Promise.resolve(promise)
  },

  /**
   * Delete 系
   */

  /**
   * 宿題を1件削除する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   * @returns 処理結果
   */
  async deleteHomework(accountId, schoolId, homeworkKey, apiToken) {
    // 宿題削除StepFunctions呼び出し
    const result = await homeworkApi.deleteHomework(
      accountId,
      schoolId,
      homeworkKey,
      apiToken
    )
    // API エラー
    if (result.status !== apiResponseCode.ok) {
      return Promise.reject(result)
    }
    let executionArn = result.data.executionArn;
    // StepFunctionsの完了を監視
    const promise = await this.checkStepFunctionsStatus(homeworkApi.getStatusDeleteHomework, executionArn);

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise
  },

  /**
   * 宿題記録のエクスポート
   * @param {*} param
   * @returns 処理結果
   */
  async exportHomework(param) {
    const promise = await homeworkApi.exportHomework(param)

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise
  },

  /**
   * 宿題記録のエクスポートの実行ステータス取得
   * @param {string} executionArn
   * @returns 処理結果
   */
  async getStatusForExportHomework(executionArn) {
    const promise = await homeworkApi.getStatusForExportHomework(executionArn)

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise
  },

  /**
   * StepFunctionsのステータス監視
   * @param {*} executionArn 
   */
  async checkStepFunctionsStatus(checkStateFunc, executionArn) {
    return await new Promise((resolve) => {
      const intervalid = setInterval(async () => {
        try {
          let statusResult = await checkStateFunc(executionArn)
          let status = statusResult.data.status;
          switch (status) {
            case "RUNNING":
              // 実行継続中
              break;
            case "SUCCEEDED": {
              // 実行終了（成功）
              const output = JSON.parse(statusResult.data.output)
              resolve({
                status: 200,
                data: output.data
              })
              clearInterval(intervalid)
              break;
            }
            case "FAILED":
            default:
              // 実行終了（失敗）
              console.error(statusResult)
              resolve({
                status: 500
              })
              clearInterval(intervalid)
              break;
          }
        } catch (e) {
          console.error(e)
          resolve({
            status: 500
          })
          clearInterval(intervalid)
        }
      }, 1000)
    });
  },

  /**
   * 学校共通設定情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @param {String[]} keyList 取得キーリスト
   * @returns 学校共通設定情報マップオブジェクト
   */
  async getSchoolInfo(accountId, schoolId, apiToken, keyList) {
    // API で宿題一覧を取得
    const promise = await homeworkApi.getSchoolInfo(
      accountId,
      schoolId,
      apiToken,
      keyList
    );

    // API ステータスが200番以外でも then に処理がくるのでステータスを確認
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    const valueMap = {};
    // 一度も登録してない場合はpromise.data.dataが設定されないので存在チェックを行う
    if (promise.data.data) {
      const setList = promise.data.data.set_list;
      if (setList) {
        setList.forEach(v => {
          valueMap[v.set_key] = v.set_value;
        });
      }
    }
    return valueMap;
  },

  /**
   * 学校共通設定情報を保存する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @param {String} keyName キー名
   * @param {String} value 値
   * @returns 学校共通設定情報マップオブジェクト
   */
  async saveSchoolInfo(accountId, schoolId, apiToken, keyName, value) {
    // API で宿題一覧を取得
    const promise = await homeworkApi.saveSchoolInfo(
      accountId,
      schoolId,
      apiToken,
      keyName,
      value
    );

    // API ステータスが200番以外でも then に処理がくるのでステータスを確認
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise;
  },
}
