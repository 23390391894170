var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-homework" },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.headers, function(header, index) {
              return _c(
                "th",
                { key: index },
                [
                  _c("TableHeaderLabel", {
                    attrs: {
                      "label-text": header.label,
                      "sort-key": header.sortKey,
                      "is-selected": header.sortKey === _vm.selectedHeaderKey
                    },
                    on: { "on-selected": _vm.onClickLabel }
                  })
                ],
                1
              )
            }),
            0
          )
        ]),
        _c(
          "tbody",
          _vm._l(_vm.convertedStatusNameItems, function(item, index) {
            return _c("tr", { key: index, staticClass: "text-center" }, [
              _c(
                "td",
                [
                  _c("BadgeStatus", {
                    attrs: {
                      "label-text": item.statusDisplayName,
                      color: item.statusColor
                    }
                  })
                ],
                1
              ),
              _c("td", [
                item.groupType == "share"
                  ? _c("img", {
                      staticClass: "ps-1",
                      attrs: {
                        src: require("@/assets/images/btn_edit.png"),
                        width: "25px"
                      }
                    })
                  : _c("img", {
                      staticClass: "ps-1",
                      attrs: {
                        src: require("@/assets/images/btn_add.png"),
                        width: "25px"
                      }
                    }),
                _vm._v(" " + _vm._s(item.groupName) + " ")
              ]),
              _c("td", [_vm._v(_vm._s(item.category))]),
              _c("td", { staticStyle: { "text-align": "left" } }, [
                _vm._v(" " + _vm._s(item.homeworkName) + " ")
              ]),
              _c(
                "td",
                [
                  item.status === _vm.streamStatus.streaming ||
                  item.status === _vm.streamStatus.endOfStream
                    ? [
                        _vm.isConfirmed(item)
                          ? _c(
                              "div",
                              [
                                _c("ButtonProgress", {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.onClickProgress(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("ButtonProgressUnconfirm", {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.onClickProgress(item)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "td",
                [
                  _c("ButtonEdit", {
                    attrs: { editingPermission: item.editingPermission },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickEdit(item)
                      }
                    }
                  })
                ],
                1
              ),
              _c("td", [_vm._v(_vm._s(item.curriculumName))]),
              _c("td", [_vm._v(" " + _vm._s(item.subjectName) + " ")]),
              _c("td", [_vm._v(_vm._s(item.startDate))]),
              _c("td", [_vm._v(_vm._s(item.deadlineDate))]),
              _c("td", [_vm._v(_vm._s(item.HandInCount))]),
              _c(
                "td",
                [
                  _c("ButtonCopy", {
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickCopy(item)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "td",
                [
                  item.status === _vm.streamStatus.streaming ||
                  item.status === _vm.streamStatus.endOfStream
                    ? [
                        _c("ButtonExport", {
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClickExport(item)
                            }
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "td",
                [
                  _c("ButtonDelete", {
                    attrs: { editingPermission: item.editingPermission },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickDelete(
                          item.homeworkKey,
                          item.schoolId,
                          item.homeworkName,
                          item.editingPermission
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c("td", [_vm._v(_vm._s(item.releaseEndDate))])
            ])
          }),
          0
        )
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.showConfirm
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.confirmMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showConfirm = false
              }
            }
          })
        : _vm._e(),
      _vm.showNoEditPermission
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.noEditingPermission") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showNoEditPermission = false
              }
            }
          })
        : _vm._e(),
      _vm.showComfirmDelete
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message: _vm.$t(
                    "messages.confirm.deleteHomeworkReplaceWords",
                    {
                      homeworkName: _vm.deleteHomeworkName
                    }
                  )
                }
              },
              {
                "ok-confirm-modal": _vm.okConfirmModal,
                "close-confirm-modal": _vm.closeConfirmModal
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }