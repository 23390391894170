<template>
  <div class="modal-select-question-type">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <div class="modal-container p-4">
              <h3>利用規約</h3>
              <div class="modal-text">
                <div class="mglr5">
                  <p class="mgb10">
                    この利用規約（以下「本規約」）は、「指導者用デジタル教科書（教材）」、「デジタル版指導用教科書」、「学習者用デジタル教科書」、「学習者用デジタル教科書・教材」、「学習者用デジタル教材」、及び「学習者用デジタル版」（以下併せて「本製品」）の利用に関してお客様と数研出版株式会社（以下「弊社」）との間に適用される契約内容となるものですので、お客様は本製品のご利用に際し、事前に本規約をお読みください。お客様が本製品をインストールまたは実際に利用することによって本規約に同意したものとみなし、インストールまたは初めて利用した日付をもって本規約は発効するものとします。
                  </p>
                  <br>
                  <ul class="cap_list">
                    <li class="mgb10">
                      1．定義
                      <ul class="cap_list">
                        <li class="mgb10">
                          1－1．本製品<br>
                          本製品には、本製品に関し、紙媒体その他の媒体により弊社からお客様に提供する関連資料・付属資料を含むものとします。
                        </li>
                        <li class="mgb10">
                          1－2．本ソフトウェア<br>
                          本製品に含まれるコンピュータプログラムその他のデジタルコンテンツ及び弊社が別途提供することのあるアップデートプログラムや追加のデジタルコンテンツをいい、特段の記載がない限り、弊社が第三者の許諾のもとに提供する第三者の著作物を含みます。
                        </li>
                        <li class="mgb10">
                          1－3．指導者用<br>
                          本製品のうち、「指導者用デジタル教科書（教材）」及び「デジタル版指導用教科書」 を指します。
                        </li>
                        <li class="mgb10">
                          1－4．学習者用<br>
                          本製品のうち、「学習者用デジタル教科書」、「学習者用デジタル教科書・教材」、「学習者用デジタル教材」、及び「学習者用デジタル版」を指します。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      2．著作権
                      <ul class="cap_list">
                        <li class="mgb10">
                          2－1．本製品及び本ソフトウェアの著作権は、弊社に帰属します。
                        </li>
                        <li class="mgb10">
                          2－2．ただし、本製品及び本ソフトウェアに収録されているプログラム、文章、写真、動画、イラスト、音声など第三者の著作物に関する著作権その他の知的財産権は、各著作権者に帰属します。
                        </li>
                        <li class="mgb10">
                          2－3．前記2－1、2－2の各著作物から派生するすべての権利は、前記2－1、2－2の各著作権者に帰属します。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      3．使用条件
                      <ul class="cap_list">
                        <li class="mgb10">
                          3－1．弊社は、本規約に定める条件の下で、お客様に対し、指導者用は本製品を使用する学校その他教育機関単位で、学習者用は本製品を使用するユーザー単位で、本製品及び本ソフトウェアの非独占的使用権を許諾します。なお、本製品及び本ソフトウェアについて、お客様には、本規約中で許諾される使用権以外は何らの権利も発生しません。
                        </li>
                        <li class="mgb10">
                          3－2．本製品及び本ソフトウェアは、以下の目的に限りご使用いただくことができます。<br>
                          ①お客様が学校その他の教育機関である場合：ご購入いただいたお客様の学校その他教育機関内において、授業を実施することを目的にご使用いただくことができます。この目的のために、本製品及び本ソフトウェアを学習者（学校については生徒、その他教育機関については学校における生徒と同等の地位を有する者に限るものとします。以下同じ）に使用させる場合、お客様の責任で学習者に本規約を遵守させるものとします。<br>
                          ②お客様が学習者個人である場合：ご購入いただいたお客様個人の自学自習のためにご使用いただくことができます。
                        </li>
                        <li class="mgb10">
                          3－3．本ソフトウェアは、指導者用については前記3－1により本製品及び本ソフトウェアの使用を許諾された学校その他教育機関が保有する各デバイス（学校については教師、その他教育機関については学校における教師と同等の地位を有する者が保有するデバイスを含みます。以下同じ）でご使用いただくことができ、また、学習者用については前記3－1により本製品及び本ソフトウェアの使用を許諾された学習者またはお客様が学校その他の教育機関である場合にはその関係者（学校については教師、その他教育機関については学校における教師と同等の地位を有する者に限るものとします。以下同じ）が保有する各デバイスでご使用いただくことができます。
                        </li>
                        <li class="mgb10">
                          3－4．本製品及び本ソフトウェアには利用期限が設けられています。利用期限は本製品パッケージ、弊社ホームページ等または別途弊社からお客様の学校その他教育機関に対して発行するライセンス証明書に記載しています。お客様は、利用期限の終了をもって本製品及び本ソフトウェアの使用を終了するものとします。
                        </li>
                        <li class="mgb10">
                          3－5．本製品及び本ソフトウェアに収録されたデータ及びその他の著作物は、著作権法に基づき、著作権者の権利を侵害しない範囲で利用することができます。
                        </li>
                        <li class="mgb10">
                          3－6．本製品、本ソフトウェアの利用に際し発生する通信料、利用に必要な設備等は、お客様のご負担となります。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      4．禁止事項
                      <ul class="cap_list">
                        <li class="mgb10">
                          4－1．本ソフトウェアの改変、リバースエンジニアリング、逆コンパイル及び逆アセンブルをすることはできません。
                        </li>
                        <li class="mgb10">
                          4－2．本製品及び本ソフトウェアから一部を分離しての使用や、本製品及び本ソフトウェアの全部または一部の複製、第三者への再使用許諾、貸与、譲渡及び送信(第三者に送信可能な状態でネットワーク上に蓄積することも含みます)をすることはできません。ただし、お客様が学校その他の教育機関である場合、その関係者が本製品及び本ソフトウェアを使用することは、「第三者への再使用許諾、貸与、譲渡」には該当しないものとします。
                        </li>
                        <li class="mgb10">
                          4－3．本製品及び本ソフトウェアに収録されたデータを使って作成し出力されたものを、冊子化（印刷して製本する等、紙媒体に限らず電子データ化も含む）の上、第三者に頒布することは、弊社の許諾がない限り、行うことはできません。
                        </li>
                        <li class="mgb10">
                          4－4．本製品及び本ソフトウェアに収録されたプログラム、文章、写真、動画、イラスト、音声など第三者の著作物については、転載や改変はできません。
                        </li>
                        <li class="mgb10">
                          4－5．本製品及び本ソフトウェアは授業を実施する上で必要な範囲での利用及び自学自習のための利用を原則としており、ホームページ・SNSその他インターネット上への記載、研究会での利用など、本製品及び本ソフトウェアの使用許諾を受けた者以外が本製品及び本ソフトウェアの内容を閲覧できるようにすること、並びに商用目的で本製品及び本ソフトウェアを利用することは、弊社及び前記2－2で示した各著作権者の許諾がない限り、行うことはできません。
                        </li>
                        <li class="mgb10">
                          4－6．本製品及び本ソフトウェア、ならびに本製品及び本ソフトウェアに収録されたデータを、弊社の権利・利益を不当に害することとなるような目的・態様において利用することはできません。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      5．本製品及び本ソフトウェアの変更・停止・終了
                      <ul class="cap_list">
                        <li class="mgb10">
                          5－1．弊社は、社会情勢、技術の変化、市場の変化またはその他の事情により、弊社ホームページ等によるお客様への事前の告知をもって、本製品及び本ソフトウェアについて、提供内容の変更、お客様による使用を停止・終了させることができるものとします。
                        </li>
                        <li class="mgb10">
                          5－2．弊社は、天災、システム障害またはその他のやむを得ない事情により、お客様に事前の連絡をすることなく、一時的に本製品及び本ソフトウェアによる提供内容の一部または全部を中断することがあります。
                        </li>
                        <li class="mgb10">
                          5－3．弊社は、本製品及び本ソフトウェアの提供の停止または中断により、お客様または第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      6．保証について
                      <ul class="cap_list">
                        <li class="mgb10">
                          6－1．弊社は、お客様に対し、本製品及び本ソフトウェアの性能及び機能がお客様の使用目的に合致していること、本製品及び本ソフトウェアに欠陥がないこと、その他本製品及び本ソフトウェアに関する一切の保証はいたしません。
                        </li>
                        <li class="mgb10">
                          6－2．本製品及び本ソフトウェアのインストール、利用開始に伴う作業は、お客様の責任及び負担で行うものとします。
                        </li>
                        <li class="mgb10">
                          6－3．弊社は、パンフレットや弊社ホームページ等で明示する動作保証対象外の機器・ソフトウェア、特殊な通信環境等に起因する動作不良等については、一切のサポートを行いません。
                        </li>
                        <li class="mgb10">
                          6－4．本製品及び本ソフトウェアに収録されたデータに対応する書籍に訂正等がなされた場合、これに対応する更新等は弊社の判断により行うものとし、これを保証するものではありません。更新に伴う作業はお客様の責任及び負担で行うものとします。
                        </li>
                        <li class="mgb10">
                          6－5．本製品及び本ソフトウェアに重大な瑕疵があった場合（ただし、動作保証対象外の特定のハードウェア・ソフトウェア及びそれらの組み合わせによる動作不具合を含まない）、欠陥の程度に応じて弊社の判断に基づき、ご購入後90日間に限り、製品の交換、あるいは修補、解決方法のご案内をいたします。
                        </li>
                        <li class="mgb10">
                          6－6．弊社は、弊社に故意または重大な過失がある場合を除き、本製品及び本ソフトウェアを使用し、あるいは使用ができない状況から起こるどのような損害についても、一切責任を負わないものとします。
                        </li>
                        <li class="mgb10">
                          6－7．弊社がお客様に対して損害賠償責任を負う場合でも、弊社に故意または重大な過失がある場合を除き、損害賠償額は、お客様がお支払いになられた金額を上限とします。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      7．学習データの取り扱いについて
                      <ul class="cap_list">
                        <li class="mgb10">
                          7－1．お客様は、教育に使用することを目的として、学習の記録、文章、画像その他のデータ（以下「学習データ」）を本製品と連動した外部サーバーに保存することができます。
                        </li>
                        <li class="mgb10">
                          7－2．お客様は、自らが保存する学習データについて、第三者の著作権その他の権利を侵害していないことを保証するものとします。また、保存された学習データに関して、お客様と第三者との間で紛争が生じた場合、お客様の責任において紛争を解決するものとし、弊社は紛争解決について何ら義務及び責任を負わないものとします。
                        </li>
                        <li class="mgb10">
                          7－3．お客様は、保存された学習データを、弊社並びにお客様及びその関係者が閲覧することがあることに同意するものとします。
                        </li>
                        <li class="mgb10">
                          7－4．弊社は、お客様が保存した学習データを、本製品及び本ソフトウェアの改良、メンテナンス、宣伝等に必要な範囲内で、永久かつ無償で自由に利用（複製、翻案、公衆送信、第三者への再許諾などを含む）できるものとします。
                        </li>
                        <li class="mgb10">
                          7－5．弊社は、学習データを保存する義務を負うものではなく、お客様は必要な情報は自己の責任で保全しておくものとします。弊社は、保存されている学習データが法令もしくは本規約に違反し、または違反するおそれがあると認めた場合、当該学習データを削除または非表示にすること、その他必要な措置を講じることができるものとします。また、学習データ削除によってお客様に生じた損害について、弊社は一切の責任を負わないものとします。
                        </li>
                      </ul>
                    </li>
                    <br>
                    <li class="mgb10">
                      8．お客様が本規約に違反された場合について<br>
                      お客様が本規約の条項の一つにでも違反した場合、お客様は本製品及び本ソフトウェアを使用する一切の権利を喪失するものとします。併せて、弊社はお客様に対して損害の賠償を請求することができるものとします。
                    </li>
                    <br>
                    <li class="mgb10">
                      9．本規約の変更について<br>
                      弊社は、お客様との個別の合意なしに、民法第548条の4の規定により本規約を変更することができるものとします。本規約を変更する場合、弊社は、弊社ホームページにて本規約を変更する旨及び変更後の本規約の内容並びに変更の効力発生時期を告知するものとします。
                    </li>
                    <br>
                  </ul>
                  <ul class="cap_list">
                    <li class="mgb10">
                      その他<br>
                      本規約は日本法に準拠するものとし、お客様と弊社との間で本規約について生じた紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所といたします。
                    </li>
                    <li class="mgb10">
                      ●「指導者用デジタル教科書（教材）」及び「デジタル版指導用教科書」に関する特約<br>
                      本製品及び本ソフトウェアを、授業を受ける者（生徒等）が主に使用するコンピュータその他のデバイスにインストールして使用することは、弊社の許諾がない限り、行うことはできません。
                    </li>
                    <li class="mgb10">
                      ●「学習者用デジタル教科書」、「学習者用デジタル教科書・教材」及び「学習者用デジタル教材」に関する特約<br>
                      本製品及び本ソフトウェアを、教室での授業においてプロジェクター等により拡大表示して使用することはできません。ただし、授業を受ける者（生徒等）全員が、本製品及び本ソフトウェアの利用許諾を得て使用可能な状態にある場合は、この限りではありません。
                    </li>
                    <li class="mgb10">
                      ●「学習者用デジタル版」に関する特約<br>
                      本製品及び本ソフトウェアを、教室での授業においてプロジェクター等により拡大表示して使用することは、弊社の許諾がない限り、行うことはできません。ただし、授業を受ける者（生徒等）全員が、本製品及び本ソフトウェアの利用許諾を得て使用可能な状態にある場合、または、本製品と同等の内容で構成された書籍等を使用している場合は、この限りではありません。
                    </li>
                    <li class="mgb10">
                      ● 本ソフトウェアにおける利用者情報の扱いについて<br>
                      本ソフトウェアのブラウザ版（以下「ブラウザ版」）の利便性向上のため、お客様がブラウザ版にアクセスされたという情報を外部に送信することがあります。詳細については、弊社の個人情報保護方針をご覧ください。<br>
                      数研出版 個人情報保護方針（
                      <a
                        href="https://www.chart.co.jp/top/engagement/privacy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://www.chart.co.jp/top/engagement/privacy.html
                      </a>
                      ）
                    </li>
                    <br>
                  </ul>
                  <p class="mgb10 text_right">
                    以上<br>
                    <!-- eslint-disable-next-line -->
                    最終更新日　2025年2月5日
                  </p>
                </div>
              </div>
              <div
                v-if="isAgreeRequired"
                class="row h-25 gx-5 justify-content-center"
              >
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.disagree')"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickDisagree"
                  />
                </div>
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.agree')"
                    color="layout-theme-light"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickAgree"
                  />
                </div>
              </div>
              <div
                v-if="!isAgreeRequired"
                class="row h-25 gx-5 justify-content-center"
              >
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.close')"
                    color="layout-theme-light"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickCloseTerms"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 利用規約モーダル
 */
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"

export default {
  name: "ModalTerms",

  components: {
    ButtonBorderCircleMedium,
  },

  props: {
    isAgreeRequired: { type: Boolean },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    buttonWidth: "180px"
  }),

  methods: {
    async onClickAgree() {
      return new Promise(resolve => 
        resolve(this.$emit("agree-terms"))
      )
    },
    async onClickDisagree() {
      return new Promise(resolve => 
        resolve(this.$emit("disagree-terms"))
      )
    },
    async onClickCloseTerms() {
      return new Promise(resolve => 
        resolve(this.$emit("close-terms"))
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-select-question-type {
  position: absolute;

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
      font-family: var(--bs-body-font-family);
      font-size: var(--bs-body-font-size);
      font-weight: var(--bs-body-font-weight);
      line-height: var(--bs-body-line-height);
      color: var(--bs-body-color);
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

      .modal-container-outer {
        margin-left: auto;
        margin-right: auto;

        .modal-default-button {
          width: 51px;
          margin-left: auto;
        }

        .modal-container {
          background-color: #fff;
          border: 2px solid rgba(0, 0, 0, 0.7);
          border-radius: 10px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
          transition: all 0.3s ease;
          text-align: center;

          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.5rem;
          }

          .modal-text {
            font-size: 85%;
            max-width: 800px;
            min-width: 410px;
            width: 60vw;
            text-align: left;
            overflow-wrap: break-word;
            max-height: 55vh;
            overflow-y: auto;
            margin-bottom: 1rem;

            .mglr5 {
              margin: 0px 5px;
            }
            .mgb10 {
              margin-bottom: 10px;
            }
            .cap_list {
              list-style: none;
              margin: 0;
              padding: 0;
            }
            .cap_list li {
              list-style: none;
              text-indent: -1em;
              padding-left: 1em;
            }
            .text_right {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
</style>
