var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "homework-register" }, [
    _c(
      "div",
      { staticClass: "mt-3 mb-4 mx-4" },
      [
        _c("TitleHomework", {
          ref: "titleArea",
          staticClass: "mr-6",
          attrs: {
            "help-link": _vm.$t("url.helps.homeworkRegister"),
            "show-required-message": true
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _vm.showed
                ? _c(
                    "InputParametersHomeworkRegister",
                    _vm._g(
                      {
                        ref: "inputParameters",
                        attrs: {
                          "select-params": {
                            headerInfo: _vm.headerInfo,
                            streamGroupMember: _vm.selectedStreamGroupMember
                          },
                          "stream-group-member": _vm.accountItems,
                          "is-invalid-date-range": _vm.isInvalidDateRange,
                          "is-published": _vm.isPublished,
                          "show-can-not-get-student": _vm.showCanNotGetStudent,
                          "show-can-not-get-textbook": _vm.showCanNotGetTextbook
                        }
                      },
                      {
                        "select-group": _vm.onSelectGroup,
                        "rollback-stream-member": _vm.rollbackStreamMember,
                        "on-update-selected-group-members":
                          _vm.onUpdateSelectedGroupMembers,
                        "on-click-invalid-date-range":
                          _vm.onClickInvalidDateRange,
                        "edit-category": _vm.onClickEditCategory
                      }
                    )
                  )
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("ButtonBorderCircleMedium", {
                attrs: {
                  "label-name": _vm.$t("buttons.addStreamData"),
                  color: "layout-theme-light",
                  "label-color": _vm.colorLayoutTheme,
                  "border-color": _vm.colorLayoutTheme,
                  disabled: _vm.isPublished
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.tryOpenAddStreamDataModal.apply(null, arguments)
                  }
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row mb-2" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "TableHomeworkStreamDataList",
                _vm._g(
                  {
                    attrs: {
                      "stream-data-list": _vm.homeworkDetailItems,
                      "is-published": _vm.isPublished,
                      "display-count": 10,
                      "stream-group-member":
                        _vm.selectedStreamGroupMember.groupMemberItems,
                      "student-items": _vm.studentItems,
                      "book-items": _vm.bookItems,
                      "stream-group": _vm.selectedGroupId.toString(),
                      "copy-homework-key": _vm.previewHomeworkKey,
                      "is-require": true,
                      "account-list": _vm.accountList
                    }
                  },
                  {
                    "on-sort": _vm.sortHomeworkDetailItemList,
                    "changed-stream-data-list": _vm.changedStreamDataList,
                    delete: _vm.deleteStreamData
                  }
                )
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row mb-3" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _vm.showed
                ? _c("FormTextArea", {
                    attrs: {
                      "label-text": _vm.$t("labels.comment"),
                      "aria-label": "comment",
                      row: "3",
                      value: _vm.inputTeacherComment,
                      "initial-value": _vm.initialTeacherComment,
                      placeholder: _vm.$t("placeholder.teacherComment")
                    },
                    on: {
                      input: function($event) {
                        _vm.inputTeacherComment = $event
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ButtonBorderCircleMedium", {
                    attrs: {
                      disabled: !_vm.isCreating,
                      "label-name": _vm.$t("buttons.save"),
                      color: "layout-theme-light",
                      "label-color": _vm.colorLayoutTheme,
                      "border-color": _vm.colorLayoutTheme
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickSave.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "col-8" }, [
            _c("div", { staticClass: "row gx-5" }, [
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ButtonBorderCircleMedium", {
                    attrs: {
                      "label-name": _vm.$t("buttons.cancel"),
                      "label-color": _vm.colorLayoutTheme,
                      "border-color": _vm.colorLayoutTheme
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickCancel.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("ButtonBorderCircleMedium", {
                    attrs: {
                      "label-name": _vm.$t("buttons.stream"),
                      color: "layout-theme-light",
                      "label-color": _vm.colorLayoutTheme,
                      "border-color": _vm.colorLayoutTheme
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onClickStream.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        !_vm.showed || _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
        _vm.showSelectQuestionType
          ? _c(
              "ModalSelectQuestionType",
              _vm._g(
                { attrs: { "is-published": _vm.isPublished } },
                {
                  "pre-transition": _vm.preTransitionSelectQuestion,
                  close: _vm.onCloseSelectQuestionType
                }
              )
            )
          : _vm._e(),
        _vm.showEditCategory
          ? _c(
              "ModalEditCategory",
              _vm._g(
                {},
                {
                  close: _vm.onCloseEditCategory,
                  "on-input-category": _vm.onInputCategory,
                  "on-delete-category": _vm.onDeleteCategory
                }
              )
            )
          : _vm._e(),
        _vm.isShowingModalOkOnly
          ? _c("ModalConfirmOkOnly", {
              attrs: { message: _vm.modalOkOnlyMessage },
              on: {
                "close-confirm-modal-ok-only": _vm.onClickOkConfirmModalOkOnly
              }
            })
          : _vm._e(),
        _vm.isShowingModalConfirm
          ? _c(
              "ModalConfirm",
              _vm._g(
                { attrs: { message: _vm.confirmMessage } },
                {
                  "ok-confirm-modal": _vm.onOkConfirmModal,
                  "close-confirm-modal": _vm.onCloseConfirmModal
                }
              )
            )
          : _vm._e(),
        _vm.sessionDisconnect
          ? _c("ModalConfirmOkOnly", {
              attrs: {
                message: _vm.$t("messages.error.overLimitSessionToken")
              },
              on: {
                "close-confirm-modal-ok-only": function($event) {
                  return _vm.onSessionDisconnect()
                }
              }
            })
          : _vm._e(),
        _vm.showChangedGroups
          ? _c("ModalConfirm", {
              attrs: { message: _vm.$t("messages.error.changedGroupConfirm") },
              on: {
                "ok-confirm-modal": function($event) {
                  return _vm.onOkCloseShowChangedGroups()
                },
                "close-confirm-modal": function($event) {
                  return _vm.onCloseShowChangedGroups()
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }